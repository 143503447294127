export const mobileNumberValidation = (mobileNumber) => {
  const mobileNumberRegex =/^(?:\+91)?(?:[6789]\d{9})$/;  
  if (!mobileNumberRegex.test(mobileNumber)) {
    return true;
  }
  else {
    return false;
  }
}

export const nameValidation = (name) => {
  const nameRegex = /^[a-zA-Z\s]+$/;
  if (!nameRegex.test(name)) {
    return true
  }
  else {
    return false
  }
}

export const emailValidation = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if(!emailRegex.test(email)){
    return true;
  }
  else{
    return false;
  }
}

export const AadhaarValidation = (Aadhaar) => {
  const AadhaarRegex = /^\d{12}$/;
  if (!AadhaarRegex.test(Aadhaar)) {
    return true;
  }
  else {
    return false;
  }
}