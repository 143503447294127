import React, { useState,} from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Statistic,
  Table,
  Card,
  Divider,
  Avatar,
  Input,
  Modal,
  Tag,
} from "antd";
import Title from "../../CommonComponents/Title";
import Header from "../../Header/Header";
import "../Dashboard/dashboard.css";
import TitleActionsContainer from "../../CommonComponents/TitleActionsContainer";
import { verifyVehicleMockData } from "../Dashboard/const";
import {
  CarOutlined,
  CheckCircleFilled,
  RightCircleFilled,
  SearchOutlined,
  TeamOutlined,
  VerifiedOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";
import Search from "antd/lib/input/Search";

const VerifiedVehicles = () => {
  const [isModalOpenView, setIsModalOpenView] = useState(false);

  const vehicleData = [
    "VehicleInfo",
    "modal",
    "variant",
    "regNumber",
    "status",
    "action",
  ];
  const vehicleColumns = [];
  const openModal = () => {
    setIsModalOpenView(true);
  };
  const handleOk = (type) => {
    setIsModalOpenView(false);
  };

  const handleCancel = (type) => {
    setIsModalOpenView(false);
  };

  vehicleData.map((headTitle) => {
    switch (headTitle) {
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  <Avatar
                    size="large"
                    src="https://imgs.search.brave.com/52jI6pldqCOOmm1EDA5bCEeFhBXUkyCSBwK36Itbuyw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5pc3RvY2twaG90/by5jb20vaWQvMTMw/NzA4NjU2Ny9waG90/by9nZW5lcmljLW1v/ZGVybi1zdXYtY2Fy/LWluLWNvbmNyZXRl/LWdhcmFnZS5qcGc_/cz02MTJ4NjEyJnc9/MCZrPTIwJmM9ZWg2/RUE0ZzQ2MnpmVmc1/YTNpUHdNc2JObFRH/WnFZaFpGVWhjTG9h/TERTcz0"
                  />
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.vehicleInfo}
                </span>
              </div>
            );
          },
        });
        break;
      case "modal":
        vehicleColumns.push({
          title: "Modal",
          dataIndex: "Modal",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.Modal}</span>;
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "Variant",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.Variant}</span>;
          },
        });
        break;
      case "regNumber":
        vehicleColumns.push({
          title: "Reg Number",
          dataIndex: "regNumber",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.regNumber}</span>;
          },
        });
        break;
      case "status":
        vehicleColumns.push({
          title: "Status",
          dataIndex: "status",
          render: (_i, item) => {
            return (
              
                  <Tag color="green" style={{borderRadius:"5px"}}>
                    Verified <CheckCircleFilled />
                    </Tag>
                  
            );
          },
        });
        break;
      case "action":
        vehicleColumns.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {
                  <>
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => openModal("view")}
                    >
                      View
                    </span>
                  </>
                }
              </span>
            );
          },
        });
        break;
      default:
        return null;
    }
  });
  return (
    <>
      <Row style={{marginBottom:"8%"}}> 
        <Header />
      </Row>
      <div className="vehicle componentsLoadContainer">
        <div
          style={{
            justifyContent: "normal",
            padding: "1%",
          }}
        >
          <Table
            className="custom-table"
            columns={vehicleColumns}
            dataSource={verifyVehicleMockData}
          />
        </div>

        <Modal
          centered
          title="Vehicle Info"
          open={isModalOpenView}
          onOk={() => handleOk("view")}
          onCancel={() => handleCancel("view")}
        ></Modal>
      </div>
    </>
  );
};
export default VerifiedVehicles;
