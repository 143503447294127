import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../Header/Header";
import { Avatar, Col, Divider, Form, Input, Row, Space } from "antd";
import Title from "../../CommonComponents/Title";
import { UserOutlined } from "@ant-design/icons";
import "./profile.css";

const Profile = () => {
  const userData = useSelector((state) => state.appSlice?.userData);
  const storedData = JSON?.parse(localStorage?.getItem("userData"));
  const userRole = localStorage?.getItem("user_role");
  const [form] = Form.useForm();

  useEffect(() => {
    if (userRole === "Dealer") {
      form.setFieldsValue({
        name: storedData?.dealer_name,
        mobile_number: storedData?.mobile_number,
        email: storedData?.email,
        address: storedData?.address,
      })
    }
    else if (userRole === "Super Admin") {
      form.setFieldsValue({
        name: storedData?.personalDetails?.name,
        mobile_number: storedData?.personalDetails?.mobile_number,
        email: storedData?.personalDetails?.email,
        // address: storedData?.address?.address1,
      })
    }
    else if (userRole === "User") {
      form.setFieldsValue({
        name: storedData?.dealer_name,
        mobile_number: storedData?.mobile_number,
        email: storedData?.email,
        // address: storedData?.address,
      })
    }
    else if (userRole === "IO") {
      form.setFieldsValue({
        name: storedData?.personalDetails?.name,
        email: storedData?.personalDetails?.email,
      })
    }

  }, [userRole]);
  const onFinish = () => { };

  return (
    <div>
      <div>
        <Row style={{ marginBottom: "6%" }}>
          <Header />
        </Row>
      </div>
      <div
        className="profile componentsLoadContainer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row style={{ marginTop: "-5%", padding: "1%" }}>
          <Col style={{ fontSize: "17px", float: "left" }}>
            <Title title="Profile" />
          </Col>
          <Divider style={{ marginTop: "-0.1%" }} />
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "space-between",
              justifySelf: "center",
            }}
          >
            <Avatar size={250} icon={<UserOutlined />} className="profileAvatar" />
            <Form
              form={form}
              onFinish={onFinish}
            >
              <Space style={{ display: "flex", flexDirection: "column" }}>
                <Col>
                  <Form.Item name="name" label="Name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="email" label="Email">
                    <Input />
                  </Form.Item>
                </Col>
                {!userRole === "IO" ?
                  <Col>
                    <Form.Item name="mobile_number" label="Mobile Number">
                      <Input />
                    </Form.Item>
                  </Col>
                  : null}
                {/* {!userRole === "IO" ?
                  <Col>
                    <Form.Item name="address" label="Address">
                      <Input />
                    </Form.Item>
                  </Col>
                  : null} */}
              </Space>
            </Form>
          </div>
        </Row>
      </div>
    </div>
  );
};
export default Profile;
