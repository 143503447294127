import Header from "../../Header/Header";
import react, { useEffect, useState } from "react";
import {
  Row,
  Table,
  Form,
  Select,
  Divider,
  Button,
  Col,
  Input,
  Spin,
  Tooltip,
  Space,
  Modal,
  message,
  Radio,
} from "antd";
import Title from "../../CommonComponents/Title";
import {
  DownCircleFilled,
  EditFilled,
  FileTextFilled,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import "../DealerSearch/dealerSearch.css";
// import "./dealerSearch.css";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/apiServices";
import { useSelector } from "react-redux";
import CountWidget from "../../CommonComponents/UiComponent/countWidget";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch";

const { Search } = Input;
const { Option } = Select;

const Users = () => {
  const [count, setCount] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [getPersonList, setPersonList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [searchData, setSearchData] = useState({});
  const [pageSize, setPageSize] = useState([]);
  const [selectedEditItem, setSelectedEditItem] = useState([]);
  const [getStateList, setStateList] = useState([]);
  const [getDistList, setDistList] = useState([]);
  const [getCityList, setCityList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const indianMobileRegex =
    /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/;
  const [id, setId] = useState();
  const pageLimit = [5, 10, 50, 100];
  const userData = useSelector((state) => state.appSlice?.userData);
  const storedData = JSON?.parse(localStorage?.getItem("userData"));
  const [form] = Form.useForm();
  const dealerDetails = ["s.no", "name", "mobile_number", "email", "action"];
  const dealersColumn = [];
  const navigate = useNavigate();
  // let users =
  //   userData?.users !== undefined ? userData?.users : storedData?.users;
  let jwtToken = localStorage.getItem("jwtToken");

  useEffect(() => {
    setCount();
    setPersonList();
    setCurrent();
    setPageSize();
    fetchData(1, 10);
  }, []);

  const fetchData = async (pages, size, querry) => {
    setCurrent(pages);
    setPageSize(size);
    let pageData = { page: pages, size: size };
    setIsLoading(true);
    const response = await apiServices
      .getUserList(jwtToken, pageData, querry)
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setTotalRecords(response?.data?.total_count);
          setPersonList(response?.data?.user_data);
          setTimeout(() => {
            setIsLoading(false);
          }, 140);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 140);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });
  };
  const onChangePage = (pagination, filters, sorter) => {
    fetchData(pagination?.current, pagination?.pageSize);
    setCurrent(pagination?.current);
    setPageSize(pagination?.pageSize);
  };

  useEffect(() => {
    getCity();
    getDist();
    getState();
    setId();
  }, []);
  const getState = async () => {
    try {
      const response = await apiServices.getStateList();
      if (response?.data?.status_code === 1) {
        setStateList(response?.data?.states_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const getDist = async () => {
    try {
      const response = await apiServices.getDistrictList();
      if (response?.data?.status_code === 1) {
        setDistList(response?.data?.district_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const getCity = async () => {
    try {
      const response = await apiServices.getCityList();
      if (response?.data?.status_code === 1) {
        setCityList(response?.data?.city_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log("error");
    }
  };
  const onSearch = async (data, source) => {
    setSearchData(data);
  };

  const handleSearch = (data) => {
    let queryString = "";
    if (Object.keys(data).length > 0) {
      queryString = Object.keys(data)
        .map((key) => `${key}=${data[key]}`)
        .join("&");
    }
    console.log(queryString, "dataQueryString");
    fetchData(1, 10, queryString);
  };

  const handleReset = async () => {
    fetchData(1, 10);
  };
  const handleUserView = (data) => {
    localStorage.setItem("ViewUserData", JSON.stringify(data));
    navigate("/viewUser");
  };
  const handleUserEdit = (data) => {
    setOpenEdit(true);
    setId(data?._id);
    form.setFieldsValue({
      name: data?.personalDetails?.name,
      mobile_number: data?.personalDetails?.mobile_number,
      address: data?.address?.address1,
      address2: data?.address?.address2,
      aadhar_number: data?.personalDetails?.aadhar_number,
      state_id: data?.address?.state_id,
      city_id: data?.address?.city_id,
      district_id: data?.address?.district_id,
      pinCode: data?.address?.pinCode,
      pan_card_number: data?.personalDetails?.pan_card_number,
    });
    setSelectedEditItem(data);
  };
  const onFinish = async (data) => {
    console.log(data, "finish");
    let error = {};
    if (data?.name === "" || data?.name === undefined) {
      error.name = "Name is required";
    }
    if (indianMobileRegex.test(data?.mobile_number) === false) {
      error.mobile_number = "Enter valid mobile number";
    }
    if (data?.address === "" || data?.address === undefined) {
      error.address = "Address is required";
    }
    if (data?.aadhar_number === "" || data?.aadhar_number === undefined) {
      error.aadhar_number = "Aadhaar is required";
    }
    if (data?.state_id === "" || data?.state_id === undefined) {
      error.state_id = "Select state from list";
    }
    if (data?.city_id === "" || data?.city_id === undefined) {
      error.city_id = "Select state from list";
    }
    if (data?.district_id === "" || data?.district_id === undefined) {
      error.district_id = "Select state from list";
    }
    if (data?.pinCode === "" || data?.pinCode === undefined) {
      error.pinCode = "Pincode is required";
    }
    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage({});
      let editUser = {
        id: id,
        personalDetails: {
          name: data?.name,
          mobile_number: data?.mobile_number,
          aadhar_number: data?.aadhar_number,
          pan_card_number: data?.pan_card_number,
          email: data?.email,
        },
        address: {
          address1: data?.address,
          address2: data?.address2,
          state_id: data?.state_id,
          district_id: data?.district_id,
          city_id: data?.city_id,
          pinCode: data?.pinCode,
        },
      };
      try {
        const response = await apiServices.update_person(editUser);
        if (response?.data?.status_code === 1) {
          message.success("User Details updated successfully");
          setOpenEdit(false);
        } else {
          message.error("User failed to update");
        }
      } catch {
        message.error("User failed to update");
        console.log("Edit User Error");
      }
    }
    fetchData(1, 10);
  };

  dealerDetails.map((headers) => {
    switch (headers) {
      case "s.no":
        dealersColumn.push({
          title: "S.No",
          dataIndex: "s.no",
          render: (_i, item, index) => {
            if (current && current > 1) {
              return <span>{(current - 1) * pageSize + index + 1}</span>;
            } else {
              return <span>{index + 1}</span>;
            }
          },
        });
        break;
      case "name":
        dealersColumn.push({
          title: "Users Name",
          dataIndex: "name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.personalDetails?.name}</a>
              </span>
            );
          },
        });
        break;
      case "mobile_number":
        dealersColumn.push({
          title: "Mobile Number",
          dataIndex: "mobile_number",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {item?.personalDetails?.mobile_number}
              </span>
            );
          },
        });
        break;
      case "email":
        dealersColumn.push({
          title: "Email",
          dataIndex: "email",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.personalDetails?.email}</a>
              </span>
            );
          },
        });
        break;
      case "district":
        dealersColumn.push({
          title: "District",
          dataIndex: "district",
          render: (_i, item) => {
            return <span className="tableRowText">{item.district}</span>;
          },
        });
        break;
      case "area":
        dealersColumn.push({
          title: "Area",
          dataIndex: "area",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item.area}</a>
              </span>
            );
          },
        });
        break;
      case "action":
        dealersColumn.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <>
                <span>
                  <Button
                    onClick={() => handleUserView(item)}
                    style={{ marginRight: "8px", marginRight: "2px" }}
                  >
                    view
                  </Button>
                  <span style={{ fontSize: "13px", marginRight: "6px" }}>
                    <Button>QR</Button>
                  </span>
                  <a>
                    <Tooltip
                      title="Edit"
                      overlayInnerStyle={{ borderRadius: "5px" }}
                    >
                      <EditFilled
                        onClick={() => handleUserEdit(item)}
                        style={{ fontSize: "16px" }}
                      />
                    </Tooltip>
                  </a>
                </span>
              </>
            );
          },
        });
        break;
      default:
        return null;
    }
  });
  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="toggleContainer">
        <div
          className="users componentsLoadContainer"
          style={{ width: openSearch ? "80%" : "100%" }}
        >
          <Row
            className="tableContant"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Col style={{ display: "flex" }}>
              <Title title="Users" />
            </Col>
            <Col style={{ float: "right" }}>
              <Space>
                {!openSearch && (
                  <button
                    onClick={(e) => {
                      setOpenSearch(true);
                    }}
                    style={{
                      fontSize: "16px",
                      background: "#FBA03A",
                      border: "none",
                      borderRadius: "49%",
                      color: "#fff",
                    }}
                  >
                    <SearchOutlined className="searchIcon" />
                  </button>
                )}
                <div>
                  <PlusCircleFilled
                    onClick={() => {
                      navigate("/createUsers");
                    }}
                    style={{
                      fontSize: "28px",
                      color: "#fba03b",
                    }}
                  />
                </div>
              </Space>
            </Col>
            <Divider style={{ marginTop: "1.2%" }} />
          </Row>
          <div
            style={{
              justifyContent: "normal",
              padding: "1%",
              marginTop: "-3%",
            }}
          >
            <Table
              className="custom-table"
              columns={dealersColumn}
              dataSource={getPersonList}
              loading={isLoading}
              onChange={onChangePage}
              pagination={{
                current: current,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: pageLimit,
              }}
            />
          </div>
          <Modal
            title="Edit Details"
            centered
            open={openEdit}
            footer={false}
            onCancel={() => setOpenEdit(false)}
            width={600}
          >
            <Form onFinish={onFinish} layout="horizental" form={form}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="User Name" name="name">
                      <Input
                        placeholder="Enter User Name"
                        status={!!errorMessage?.name === true ? "error" : null}
                      />
                    </Form.Item>
                    {!!errorMessage?.name === true ? (
                      <p className="error">{errorMessage?.name}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="Aadhaar Number" name="aadhar_number">
                      <Input
                        placeholder="Enter Aadhaar Number"
                        status={
                          !!errorMessage?.aadhar_number === true
                            ? "error"
                            : null
                        }
                      />
                    </Form.Item>
                    {!!errorMessage?.aadhar_number === true ? (
                      <p className="error">{errorMessage?.aadhar_number}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item name="state_id" label="Select State">
                      <Select
                        showSearch
                        placeholder="Select State"
                        optionFilterProp="children"
                        status={
                          !!errorMessage?.state_id === true ? "error" : null
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        <Option disabled>Select State</Option>
                        {getStateList.map((data) => {
                          return (
                            <Option value={data?._id}>
                              {data?.state_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {!!errorMessage?.state_id === true ? (
                      <p className="error">{errorMessage?.state_id}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="Address" name="address">
                      <Input
                        placeholder="Enter Address"
                        status={
                          !!errorMessage?.address === true ? "error" : null
                        }
                      />
                    </Form.Item>
                    {!!errorMessage?.address === true ? (
                      <p className="error">{errorMessage?.address}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item name="district_id" label="Select District">
                      <Select
                        showSearch
                        placeholder="Select District"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        status={
                          !!errorMessage?.district_id === true ? "error" : null
                        }
                      >
                        <Option disabled>Select District</Option>
                        {getDistList &&
                          getDistList.map((data) => {
                            return (
                              <Option value={data?._id}>
                                {data?.district_name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    {!!errorMessage?.district_id === true ? (
                      <p className="error">{errorMessage?.district_id}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item name="city_id" label="Select City">
                      <Select
                        showSearch
                        placeholder="Select City"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        status={
                          !!errorMessage?.city_id === true ? "error" : null
                        }
                      >
                        <Option disabled>Select City</Option>
                        {getCityList &&
                          getCityList.map((data) => {
                            return (
                              <Option value={data?._id}>
                                {data?.city_name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    {!!errorMessage?.city_id === true ? (
                      <p className="error">{errorMessage?.city_id}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="Mobile Number" name="mobile_number">
                      <Input
                        placeholder="Enter Mobile Number"
                        status={
                          !!errorMessage?.mobile_number === true
                            ? "error"
                            : null
                        }
                      />
                    </Form.Item>
                    {!!errorMessage?.mobile_number === true ? (
                      <p className="error">{errorMessage?.mobile_number}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="Address2" name="address2">
                      <Input placeholder="Enter Optional Address" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="PAN Number" name="pan_card_number">
                      <Input placeholder="Enter Valid PAN Number" />
                    </Form.Item>
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <Form.Item label="Pincode" name="pinCode">
                      <Input
                        placeholder="Enter Valid Pincode"
                        status={
                          !!errorMessage?.pinCode === true ? "error" : null
                        }
                      />
                    </Form.Item>
                    {!!errorMessage?.pinCode === true ? (
                      <p className="error">{errorMessage?.pinCode}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Divider />
                <Col>
                  <Form.Item>
                    <div
                      style={{
                        marginLeft: "-180px",
                        marginTop: "11px",
                        position: "absolute",
                      }}
                    >
                      <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
                    </div>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <div
                      style={{
                        marginLeft: "-99px",
                        marginTop: "11px",
                        position: "absolute",
                      }}
                    >
                      <Button
                        className="custBtn1"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit <FileTextFilled />
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </div>
            </Form>
          </Modal>
        </div>
        {openSearch && (
          <AdvanceSearch
            searchType="User"
            searchData={(e) => {
              handleSearch(e);
            }}
            setOpenSearch={() => {
              setOpenSearch(false);
            }}
            handleReset={handleReset}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
