import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Button,
  Space,
  Spin,
  message,
} from "antd";
import Header from "../../Header/Header";
import apiServices from "../../services/apiServices";
import {
  DownCircleFilled,
  EditFilled,
  FileTextFilled,
} from "@ant-design/icons";
import GetDate from "../../CommonComponents/GetDate";
import Title from "../../CommonComponents/Title";
import UploadDocument from "../../CommonComponents/UploadDocument";


const { Search } = Input;

const BuyerDetails = () => {
  const [buyerData, setBuyerData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [imgUrlData, setImgUrlData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const buyerColumn = [];
  const buyerDetails = ["buyer_name", "mobile_number", "address", "action"];

  const fetchData = async () => {
    setIsLoading(true)
    const response = await apiServices.getBuyerList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setBuyerData(response?.data?.buyer_data)
          setTimeout(() => {
            setIsLoading(false)
          }, 200)
        } else {
          setIsLoading(true)
        }
      })
      .catch((error) => {
        setIsLoading(true)
        console.log("error")
      })
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  const handleBuyerViewData = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };
  const handleBuyerEditData = (data) => {
    setSelectedItem(data);
    form.setFieldsValue({
      aadhar_number: data?.aadhar_number,
      address: data?.address,
      buyer_name: data?.buyer_name,
      mobile_number: data?.mobile_number,
      pan_card_number: data?.pan_card_number,
    });
    setOpenEdit(true);
  };
  const getModalTitle = (data) => {
    return (
      <div>
        <Col>
          <b> {data?.buyer_name}</b>
        </Col>
      </div>
    );
  };

  buyerDetails.map((headers) => {
    switch (headers) {
      case "buyer_name":
        buyerColumn.push({
          title: "Buyer Name",
          dataIndex: "buyer_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.buyer_name}</a>
              </span>
            );
          },
        });
        break;
      case "mobile_number":
        buyerColumn.push({
          title: "Mobile Number",
          dataIndex: "mobile_number",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.mobile_number}</a>
              </span>
            );
          },
        });
        break;
      case "address":
        buyerColumn.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.address}</a>
              </span>
            );
          },
        });
        break;
      case "action":
        buyerColumn.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <div>
                <a>
                  <span style={{ fontSize: "16px" }}>
                    <DownCircleFilled
                      onClick={() => handleBuyerViewData(item)}
                    />
                  </span>
                </a>
                {"  "}
                <a>
                  <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                    <EditFilled onClick={() => handleBuyerEditData(item)} />
                  </span>
                </a>
              </div>
            );
          },
        });
        break;
      default:
        return null;
    }
  });

  const onFinish = async (data) => {
    let errors = {};

    if (!/^[a-zA-Z\s]+$/.test(data?.buyer_name)) {
      errors.buyer_name = "Enter Valid name"
    }
    if (!/^(?:\+91)?(?:[6789]\d{9})$/.test(data?.mobile_number)) {
      errors.mobile_number = "Enter Valid Mobile Number"
    }
    if (data?.address === undefined || data?.address === "") {
      errors.address = "Enter Contacts"
    }
    if (!/^\d{12}$/.test(data?.aadhar_number)) {
      errors.aadhar_number = "Enter Valid Aadhaar"
    }
    setErrorMessage(errors)
    data.aadhar_document = imgUrlData?.aadhar_document
    if (Object.keys(errors).length === 0) {
      setErrorMessage("")
      let editBuyer = {
        id: selectedItem?._id,
        buyer_name: data?.buyer_name,
        mobile_number: data?.mobile_number,
        address: data?.address,
        aadhar_number: data?.aadhar_number,
        aadhar_document: data?.aadhar_document,
      }
      const response = await apiServices.updateBuyer(editBuyer)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            setBuyerData(response?.data?.buyer_data)
            setTimeout(() => {
              setIsLoading(false)
            }, 200)
          } else {
            setIsLoading(true)
          }
        })
        .catch((error) => {
          setIsLoading(true)
          console.log("error")
        })
    }
  };

  const handleImgUrl = (e, type) => {
    setImgUrlData((prevState) => ({
      ...prevState, [type]: e,
    }));
  }

  const onChange = (data, type) => {
    setErrorMessage({ ...errorMessage, [type]: false });
  }
  return (
    <>
      <Row style={{marginBottom:"8%"}}> 
        <Header />
      </Row>
      <div
        className="buyer componentsLoadContainer"
        style={{ padding: "10px",position:"relative" }}
      >
        <Row
          className="accLinks"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col>
            <Title title="Buyers" />
          </Col>
          <Col style={{ float: "right" }}>
            <Search
              placeholder="Enter Mobile Number"
              //   onSearch={onSearch}
              // onChange={(e) => setSearchData(e.target.value)}
              // value={searchData}
              enterButton
              size="medium"
              style={{
                width: 200,
              }}
            />
            <Button
              className="custBtn1"
              // onClick={handleReset}
            >
              Reset
            </Button>
          </Col>
          <Divider />
        </Row>
        <div
          style={{
            justifyContent: "normal",
            padding: "1%",
          }}
        >
          {!isLoading ?
          <Table
            className="custom-table"
            columns={buyerColumn}
            dataSource={buyerData}
          />
          : <Spin style={{ position:"absolute",top:"50%",left:"50%"  }} />
}
        </div>
        <Modal
          title={getModalTitle(selectedItem)}
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={500}
        >
          <div style={{ marginLeft: "50px", fontSize: "17px" }}>
            {buyerData.map((data) => {
              return data?._id === selectedItem?._id ? (
                <div className="buyerDetails">
                  <Card className="card">
                    <b>Buyer Details</b>
                    <Divider />
                    <Col>
                      <span>Dealer Name</span>{" "}
                      <b>{data?.buyer_name ? data?.buyer_name : null}</b>
                    </Col>
                    <Col>
                      <span>Mobile Number</span>{" "}
                      <b>{data?.mobile_number ? data?.mobile_number : null}</b>
                    </Col>
                    <Col>
                      <span>Address</span>{" "}
                      <b>{data?.address ? data?.address : null}</b>
                    </Col>
                    <Col>
                      <span>Aadhaar Number</span>{" "}
                      <b>{data?.aadhar_number ? data?.aadhar_number : null}</b>
                    </Col>
                    <Col>
                      <span>PAN Number</span>{" "}
                      <b>
                        {data?.pan_card_number ? data?.pan_card_number : null}
                      </b>
                    </Col>
                  </Card>
                  <Card className="card">
                    <b>Other Details</b>
                    <Divider />
                    <Col>
                      <span>Created Time</span>{" "}
                      <b>
                        {data?.created_time
                          ? GetDate(data?.created_time)
                          : null}
                      </b>
                    </Col>
                    <Col>
                      <span>Updated Time</span>{" "}
                      <b>
                        {data?.updated_time
                          ? GetDate(data?.updated_time)
                          : null}
                      </b>
                    </Col>
                  </Card>
                </div>
              ) : null;
            })}
          </div>
        </Modal>
        <Modal
          title="Edit Details"
          centered
          onCancel={() => setOpenEdit(false)}
          open={openEdit}
          width={500}
          footer={false}
        >
          <Form onFinish={onFinish} layout="horizental" form={form}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  marginLeft: "160px",
                }}
              >
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Buyer Name"
                    name="buyer_name"
                  >
                    <Input placeholder="Enter Dealer Name"
                      status={!!errorMessage?.buyer_name === true ? "error" : null}
                      onChange={(e) => { onChange(e, "buyer_name") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.buyer_name === true ? (<p className="error">{errorMessage?.buyer_name}</p>) : ("")}
                </Col>
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Mobile Number"
                    name="mobile_number"
                  >
                    <Input placeholder="Enter Mobile Number"
                      status={!!errorMessage?.mobile_number === true ? "error" : null}
                      onChange={(e) => { onChange(e, "mobile_number") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.mobile_number === true ? (<p className="error">{errorMessage?.mobile_number}</p>) : ("")}
                </Col>
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Address"
                    name="address"
                  >
                    <Input placeholder="Enter address"
                      status={!!errorMessage?.address === true ? "error" : null}
                      onChange={(e) => { onChange(e, "address") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.address === true ? (<p className="error">{errorMessage?.address}</p>) : ("")}
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  marginLeft: "60px",
                }}
              >
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Aadhaar Number"
                    name="aadhar_number"
                  >
                    <Input placeholder="Enter Aadhaar Number"
                      status={!!errorMessage?.aadhar_number === true ? "error" : null}
                      onChange={(e) => { onChange(e, "aadhar_number") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.aadhar_number === true ? (<p className="error">{errorMessage?.aadhar_number}</p>) : ("")}
                  <Row>
                <UploadDocument name="Upload Aadhaar" imgUrl={(e) => handleImgUrl(e, "aadhar_document")} />  
                </Row>
                </Col>
              </Row>
              <Divider />
              <Col>
                <Form.Item>
                  <div
                    style={{
                      marginLeft: "-180px",
                      marginTop: "11px",
                      position: "absolute",
                    }}
                  >
                    <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
                  </div>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <div
                    style={{
                      marginLeft: "-99px",
                      marginTop: "11px",
                      position: "absolute",
                    }}
                  >
                    <Button
                      className="custBtn1"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit <FileTextFilled />
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};
export default BuyerDetails;
