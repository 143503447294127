import React from "react";
import { Image, Button, Row, Col, Form, Input, notification, Divider } from "antd";
import { useNavigate, Link } from "react-router-dom";

// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// CSS Import's

import "./login.css";
import { useUserAuth } from "../../services/UserAuthContext";

const shownotification = (type, message) => {
  notification[type]({
    message: message,
  });
};

const ForgotPassword = () => {
  const { generatePasswordResetLink } = useUserAuth();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    debugger;
    generatePasswordResetLink(values.username)
      .then((response) => {
        shownotification("success", "Send link successsfully");
        navigate("/");
      })
      .catch((error) => {
        shownotification("error", "Email not found");
      });
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="rootContainer">
      <Row className="login">
        <Col sm={8} className="textCenter">
          <Image src="./images/SafeGadi.png" preview={false} style={{width:"100%", marginLeft:"-15%"}}/>
        </Col>
        <Divider type="vertical"/>
        <Col className="loginForm">
          <Form
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                {
                  type: "email",
                  message: "Enter a valid email address!",
                },
                {
                  required: true,
                  message: "Email is required!",
                },
              ]}
            >
              <Input type="text" className="inputBox"/>
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Send reset link
            </Button>
          </Form>
          <span>Return to </span>
          <Link className="text-md font-medium" to="/">
            login?
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
