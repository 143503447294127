import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import { Divider, Row, Table, Tooltip } from "antd";
import apiServices from "../../services/apiServices";
import { CloseCircleOutlined } from "@ant-design/icons";
import Title from "antd/lib/skeleton/Title";
import { useNavigate } from "react-router-dom";

const UsersView = () => {
    const selectedItem = JSON.parse(localStorage.getItem("ViewUserData"))
    const [districtData, setDistrictData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const user = ["name", "mobileNumber", "email", "address", "aadhaarNumber", "aadhaarDocument", "panCard"];
    const userColumns = []
    const dataSource = [selectedItem]
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const response1 = await apiServices.getDistrictList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setDistrictData(response?.data?.district_data)
                } else {
                }
            })
            .catch((error) => {
                console.log(error)
            })
        const response3 = await apiServices.getCityList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setCityData(response?.data?.city_data)
                } else {

                }
            })
            .catch((error) => {
            })
        const response4 = await apiServices.getStateList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setStateData(response?.data?.states_data)
                } else {

                }
            })
            .catch((error) => {
            })
    }

    const handleClick = () => {
        navigate("/users")
    }
    user.map((headers) => {
        switch (headers) {
            case "name":
                userColumns.push({
                    title: "User Name",
                    dataIndex: "name",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.name}</a></span>
                    }
                })
                break;
            case "mobileNumber":
                userColumns.push({
                    title: "Mobile Number",
                    dataIndex: "mobile_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.mobile_number}</a></span>
                    }
                })
                break;
            case "email":
                userColumns.push({
                    title: "email",
                    dataIndex: "email",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.email}</a></span>
                    }
                })
                break;
            case "address":
                userColumns.push({
                    title: "Address",
                    dataIndex: "address",
                    render: (_i, item) => {
                        let city, district, state
                        {
                            cityData.map((cityData) => {
                                let x = item?.address?.city_id === cityData?._id ? city = cityData?.city_name : null
                            })
                        }
                        {
                            districtData.map((districtData) => {
                                let x = item?.address?.district_id === districtData?._id ? district = districtData?.district_name : null
                            })
                        }
                        {
                            stateData.map((stateData) => {
                                let x = item?.address?.state_id === stateData?._id ? state = stateData?.state_name : null
                            })
                        }
                        return <span className="tableRowText"><a>{item?.address?.address1 + "," + city + "," + district + "," + state + "," + item?.address?.pinCode}</a></span>
                    }
                })
                break;
            case "aadhaarNumber":
                userColumns.push({
                    title: "Aadhar Number",
                    dataIndex: "aadhar_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.aadhar_number}</a></span>
                    }
                })
                break;
            case "aadhaarDocument":
                userColumns.push({
                    title: "Aadhaar Document",
                    dataIndex: "aadhaar_document",
                    render: (_i, item) => {
                        return (
                            <span className="tableRowText" >
                                <b>
                                    <a href={item?.personalDetails?.aadhar_document ? item?.personalDetails?.aadhar_document : null} target="_blank " style={{ font: "blue" }} >
                                        Click here...{" "}
                                    </a>
                                </b>
                            </span>
                        )
                    }
                })
                break;
            case "panCard":
                userColumns.push({
                    title: "PAN Number",
                    dataIndex: "pan_card_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.pan_card_number}</a></span>
                    }
                })
                break;
        }
    })

    return (
        <div>
            <div>
                <Row style={{ marginBottom: "6%" }}>
                    <Header />
                </Row>
            </div>
            <div className="componentsLoadContainer" style={{ positon: "relative", padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ margin: "10px" }}>
                        <Tooltip title="Close" overlayInnerStyle={{ borderRadius: "5px" }}>
                            <CloseCircleOutlined
                                style={{ color: "#9c5403", fontSize: "28px" }}
                                onClick={handleClick}
                            />
                        </Tooltip>
                    </div>
                    <div style={{ padding: "1%" }}>
                        <Title title="Dealer Details" />
                    </div>
                </div>
                <Divider />
                <Table
                    className="custom-table"
                    columns={userColumns}
                    dataSource={dataSource}
                    pagination={false}
                />
            </div>

        </div>
    )
}
export default UsersView;