const API_URL = {
    get_users: "sg/api/v1/getuser", // Api for get user.
    widgetCountApi: "sg/api/v1/widgets", //Api for widget count.
    create_dealer: "sg/api/v1/createdealer", //Api for create Dealer.
    create_vehicle: "sg/api/v1/createvehicle", //Api for create vehicle.
    create_users: "sg/api/v1/createuser", //Api for create dealer users.
    create_person: "sg/api/v1/createperson", //Api for create Person.
    vehicle_List: "sg/api/v1/vehicle", //Api to get vehicle list.
    dealer_List: "sg/api/v1/dealer",//API for list dealer.
    buyer_List: "sg/api/v1/buyer", //API for list of buyers.
    seller_data: "sg/api/v1/seller", //"API for list of sellers".
    state_List: "sg/api/v1/states",//API for list of states.
    district_List: "sg/api/v1/district",//API for list of Districts.
    city_List: "sg/api/v1/city",//API for list of city.
    person_List: "sg/api/v1/person",//API for list of person.
    user_List:"sg/api/v1/userlist",//API for user List.
    vehicle_type: "sg/api/v1/vehicletype", //Api to get vehicle type dropdown list.
    brand_list: "sg/api/v1/brand", //Api to get brand dropdown list.
    modal_list: "sg/api/v1/model", //Api to get model dropdown list.
    variant_list: "sg/api/v1/variant", //Api to get variant dropdown list.
    rto_office_list: "sg/api/v1/rto", //Api to get rto office list dropdown list.
    police_station_data: "sg/api/v1/policestations", //API for list of police stations.
    upload_Image: "sg/api/v1/uploadimage",//API for image upload.
    update_vehicle: "sg/api/v1/updatevehicle",//API for update vehicle.
    update_dealer: "sg/api/v1/updatedealer", //API to update dealer.
    update_buyer: "sg/api/v1/updatebuyer", //API to update buyer.
    update_seller: "sg/api/v1/updateseller", //API to update seller.
    outward_vehicle: "sg/api/v1/outwardvehicle", //API to update outward vehicle.
    update_person: "sg/api/v1/updateperson", //API to update person.
    update_user:"/sg/api/v1/userlist", //API for update user.
    get_district: "sg/api/v1/getdistrict",//API for list of selected state Districts.
    get_city: "sg/api/v1/getcity",//API for list of city.
    get_brand: "sg/api/v1/getbrand", //Api to get brand dropdown list.
    get_modal: "sg/api/v1/getmodel", 
    get_variant: "sg/api/v1/getvariant", //Api to get variant dropdown list.

}
export default API_URL;
