import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  message,
  Row,
  Input,
  Select,
  Col,
  Radio,
  DatePicker,
  notification,
} from "antd";
import {
  CaretRightOutlined,
  CaretLeftOutlined,
  FileTextFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import UploadDocument from "../../CommonComponents/UploadDocument";
import apiServices from "../../services/apiServices";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { ownershipType } from "./const";
import moment from "moment";
const shownotification = (type, message) => {
  notification[type]({
    message: message,
  });
};
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const indianMobileRegex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/;
const storedData = JSON?.parse(localStorage?.getItem("userData"));
const spaceRegex = /^\S*$/;

export const FirstStepForm = ({
  formData,
  setFormData,
  currentStep,
  setCurrentStep,
}) => {
  const [brandData, setBrandData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});

  const [form] = Form.useForm();
  const onFinish = (values) => {
    let error = {};
    if (values?.VehicleType === "" || values?.VehicleType === undefined) {
      error.vehicleType = "Select vehicle type!";
    }
    if (values?.brand === "" || values?.brand === undefined) {
      error.brand = "Brand is required!";
    }
    if (values?.model === "" || values?.model === undefined) {
      error.model = "Model is required!";
    }
    if (values?.variant === "" || values?.variant === undefined) {
      error.variant = "Variant is required!";
    }
    if (values?.year_of_model === "" || values?.year_of_model === undefined) {
      error.year_of_model = "Year is required!";
    }
    if (values?.millage === "" || values?.millage === undefined) {
      error.millage = "Millage is required!";
    }
    if (values?.ownership_type === "" || values?.ownership_type === undefined) {
      error.ownership_type = "Ownership type is required!";
    }
    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage({});
      setFormData({ ...formData, ...formValues });
      setCurrentStep(currentStep + 1);
    } else {
      return null;
    }
    console.log("Received values of form: ", values);
  };

  const getDate = (event) => {
    const dateTime = new Date(event);
    const formattedDate = dateTime.toLocaleDateString("en-US", {
      year: "numeric",
    });
    return formattedDate;
  };
  const onChange = (e, type) => {
    if (type === "VehicleType") {
      getBrand(e);
      setErrorMessage({ ...errorMessage, ["vehicleType"]: false });
    } else if (type === "brand") {
      getModal(e);
      setErrorMessage({ ...errorMessage, ["brand"]: false });
    } else if (type === "model") {
      getVariant(e);
      setErrorMessage({ ...errorMessage, ["model"]: false });
    } else {
      setErrorMessage({ ...errorMessage, [type]: false });
    }
    if (type === "year_of_model") {
      let date = getDate(e?._d);
      setFormValues({ ...formValues, [type]: date });
    } else {
      setFormValues({ ...formValues, [type]: e });
    }
  };

  useEffect(() => {
    // getBrand();
    // getModal();
    // getVariant();
    getVehicleType();
    let year =
      formData?.year_of_model && new Date(formData?.year_of_model + "-01-01");
    form.setFieldsValue({
      VehicleType: formData?.VehicleType,
      brand: formData?.brand,
      ownership_type: formData?.ownership_type,
      millage: formData?.millage,
      variant: formData?.variant,
      model: formData?.model,
      year_of_model: formData?.year_of_model
        ? moment(formData?.year_of_model, "YYYY")
        : "",
    });
  }, []);

  const getModal = async (id) => {
    try {
      const response = await apiServices.getSelectedModal(id);
      if (response?.data?.status_code === 1) {
        setModelData(response?.data?.model_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVehicleType = async () => {
    try {
      const response = await apiServices.getVehicleType();
      if (response?.data?.status_code === 1) {
        setVehicleTypeList(response?.data?.vehicle_type_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBrand = async (id) => {
    let VariantType = id;
    try {
      const response = await apiServices.getSelectedBrand(VariantType);
      if (response?.data?.status_code === 1) {
        setBrandData(response?.data?.brand_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getVariant = async (id) => {
    try {
      const response = await apiServices.getSelectedVariant(id);
      if (response?.data?.status_code === 1) {
        setVariantData(response?.data?.variant_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Form form={form} onFinish={onFinish} style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginRight: "60px",
            }}
          >
            <Col style={{ padding: "5px" }}>
              Vehicle Type <span style={{ color: "red" }}>*</span>
              <Form.Item name="VehicleType">
                <Select
                  style={{ width: "180px" }}
                  showSearch
                  placeholder="Select Vehicle Type"
                  value={formData?.VehicleType}
                  onChange={(e) => {
                    onChange(e, "VehicleType");
                  }}
                  status={!!errorMessage?.vehicleType === true ? "error" : null}
                >
                  {vehicleTypeList?.map((data, idx) => (
                    <Select.Option
                      value={data?.vehicle_type_name}
                      key={data?.vehicle_type_name}
                    >
                      {data?.vehicle_type_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!!errorMessage?.vehicleType === true ? (
                <p className="error">{errorMessage?.vehicleType}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Variant<span style={{ color: "red" }}>*</span>
              <Form.Item name="variant">
                <Select
                  style={{ width: "180px" }}
                  showSearch
                  placeholder="Select Variant"
                  optionFilterProp="children"
                  onChange={(e) => {
                    onChange(e, "variant");
                  }}
                  status={!!errorMessage?.variant === true ? "error" : null}
                >
                  {variantData?.map((data, idx) => (
                    <Select.Option value={data?._id} key={data?._id}>
                      {data?.variant_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!!errorMessage?.variant === true ? (
                <p className="error">{errorMessage?.variant}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Ownership Type<span style={{ color: "red" }}>*</span>
              <Form.Item name="ownership_type">
                <Select
                  style={{ width: "180px" }}
                  showSearch
                  placeholder="Select Ownership Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    onChange(e, "ownership_type");
                  }}
                  status={
                    !!errorMessage?.ownership_type === true ? "error" : null
                  }
                >
                  <Select.Option disabled>Select Ownership Type</Select.Option>
                  {ownershipType.map((data) => {
                    return (
                      <Select.Option value={data?.type} key={data?.key}>
                        {data?.type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {!!errorMessage?.ownership_type === true ? (
                <p className="error">{errorMessage?.ownership_type}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginRight: "60px",
            }}
          >
            <Col style={{ padding: "5px" }}>
              Brand<span style={{ color: "red" }}>*</span>
              <Form.Item name="brand">
                <Select
                  style={{ width: "180px" }}
                  showSearch
                  placeholder="Select Brand"
                  optionFilterProp="children"
                  onChange={(e) => {
                    onChange(e, "brand");
                  }}
                  status={!!errorMessage?.brand === true ? "error" : null}
                >
                  {brandData?.map((data, idx) => (
                    <Select.Option value={data?._id} key={data?._id}>
                      {data?.brand_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!!errorMessage?.brand === true ? (
                <p className="error">{errorMessage?.brand}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Year<span style={{ color: "red" }}>*</span>
              <Form.Item name="year_of_model">
                <DatePicker
                  format={"YYYY"}
                  picker="year"
                  onChange={(e) => {
                    onChange(e, "year_of_model");
                  }}
                  placeholder={"Select Year"}
                  status={
                    !!errorMessage?.year_of_model === true ? "error" : null
                  }
                  style={{ width: "180px" }}
                />
              </Form.Item>
              {!!errorMessage?.year_of_model === true ? (
                <p className="error">{errorMessage?.year_of_model}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Estimate Amount
              <Form.Item name="estimate_amount">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Estimate Amount"
                  onChange={(e) => {
                    onChange(e?.target?.value, "estimate_amount");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginRight: "60px",
            }}
          >
            <Col style={{ padding: "5px" }}>
              Model<span style={{ color: "red" }}>*</span>
              <Form.Item name="model">
                <Select
                  style={{ width: "180px" }}
                  showSearch
                  placeholder="Select Model"
                  optionFilterProp="children"
                  onChange={(e) => {
                    onChange(e, "model");
                  }}
                  status={!!errorMessage?.model === true ? "error" : null}
                >
                  {modelData?.map((data, idx) => (
                    <Select.Option value={data?._id} key={data?._id}>
                      {data?.model_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!!errorMessage?.model === true ? (
                <p className="error">{errorMessage?.model}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Mileage<span style={{ color: "red" }}>*</span>
              <Form.Item name="millage">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Mileage"
                  onChange={(e) => {
                    onChange(e?.target?.value, "millage");
                  }}
                  status={!!errorMessage?.millage === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.millage === true ? (
                <p className="error">{errorMessage?.millage}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginRight: "60px",
            }}
          ></Row>
        </div>
        <div>
          <Button
            className="custBtn1"
            type="primary"
            style={{ float: "right" }}
            htmlType="submit"
          >
            Next <CaretRightOutlined />
          </Button>
        </div>
      </Form>
    </>
  );
};

export const SecondStepForm = ({
  formData,
  setFormData,
  currentStep,
  setCurrentStep,
}) => {
  const [rcValidationDate, setRcValidationDate] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [form] = Form.useForm();

  const onFinish = (values) => {
    Object?.assign((values.regValidity = rcValidationDate));
    let error = {};
    if (
      values?.registration_number === "" ||
      values?.registration_number === undefined
    ) {
      error.registration_number = "Reg number is required!";
    }
    if (spaceRegex.test(values?.registration_number) === false) {
      error.registration_number = "White spaces are not allowed";
    }
    if (values?.regValidity === "" || values?.regValidity === undefined) {
      error.regValidity = "Reg validity is required!";
    }
    if (values?.engine_number === "" || values?.engine_number === undefined) {
      error.engine_number = "Engine number is required!";
    }
    if (values?.chasis_number === "" || values?.chasis_number === undefined) {
      error.chasis_number = "Chasis number is required!";
    }
    if (values?.owner_name === "" || values?.owner_name === undefined) {
      error.owner_name = "Owner name is required!";
    }
    if (indianMobileRegex.test(values?.owners_mobile_number) === false) {
      error.owners_mobile_number = "Mobile number is required!";
    }
    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage({});
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    } else {
      return null;
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onChange = (date, dateString) => {
    setRcValidationDate(dateString);
  };

  useEffect(() => {
    console?.log(formData?.regValidity, "date");
    form.setFieldsValue({
      registration_number: formData?.registration_number,
      chasis_number: formData?.chasis_number,
      owner_name: formData?.owner_name,
      engine_number: formData?.engine_number,
      owners_mobile_number: formData?.owners_mobile_number,
    });
    if (formData?.regValidity !== undefined) {
      form.setFieldsValue({
        regValidity: moment(formData?.regValidity, "YYYY-MM-DD"),
      });
      setRcValidationDate(formData?.regValidity);
    }
  }, []);
  return (
    <>
      <Form form={form} onFinish={onFinish} style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginRight: "60px",
            }}
          >
            <Col>
              Reg Number<span style={{ color: "red" }}>*</span>
              <Form.Item name="registration_number">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Reg Number"
                  status={
                    !!errorMessage?.registration_number === true
                      ? "error"
                      : null
                  }
                />
              </Form.Item>
              {!!errorMessage?.registration_number === true ? (
                <p className="error">{errorMessage?.registration_number}</p>
              ) : (
                ""
              )}
            </Col>
            <Col>
              Chasis Number<span style={{ color: "red" }}>*</span>
              <Form.Item name="chasis_number">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Chasis Number"
                  status={
                    !!errorMessage?.chasis_number === true ? "error" : null
                  }
                />
              </Form.Item>
              {!!errorMessage?.chasis_number === true ? (
                <p className="error">{errorMessage?.chasis_number}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginRight: "60px",
            }}
          >
            <Col>
              Reg Validity<span style={{ color: "red" }}>*</span>
              <Form.Item name="regValidity">
                <DatePicker
                  format={"YYYY-MM-DD"}
                  onChange={onChange}
                  placeholder="Select Date"
                  style={{ width: "180px" }}
                  status={!!errorMessage?.regValidity === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.regValidity === true ? (
                <p className="error">{errorMessage?.regValidity}</p>
              ) : (
                ""
              )}
            </Col>
            <Col>
              Owner Name<span style={{ color: "red" }}>*</span>
              <Form.Item name="owner_name">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Owner Name"
                  status={!!errorMessage?.owner_name === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.owner_name === true ? (
                <p className="error">{errorMessage?.owner_name}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Col>
              Engine No<span style={{ color: "red" }}>*</span>
              <Form.Item name="engine_number">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Engine Number"
                  status={
                    !!errorMessage?.engine_number === true ? "error" : null
                  }
                />
              </Form.Item>
              {!!errorMessage?.engine_number === true ? (
                <p className="error">{errorMessage?.engine_number}</p>
              ) : (
                ""
              )}
            </Col>
            <Col>
              Owner Mobile Number<span style={{ color: "red" }}>*</span>
              <Form.Item name="owners_mobile_number">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Mobile Number"
                  status={
                    !!errorMessage?.owners_mobile_number === true
                      ? "error"
                      : null
                  }
                />
              </Form.Item>
              {!!errorMessage?.owners_mobile_number === true ? (
                <p className="error">{errorMessage?.owners_mobile_number}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
        <div>
          <Button style={{ margin: "0 8px" }} onClick={handlePrev}>
            <CaretLeftOutlined /> Previous
          </Button>
          <Button
            className="custBtn1"
            type="primary"
            style={{ float: "right" }}
            htmlType="submit"
          >
            Next <CaretRightOutlined />
          </Button>
        </div>
      </Form>
    </>
  );
};

export const ThirdStepForm = ({
  formData,
  setFormData,
  currentStep,
  setCurrentStep,
}) => {
  const [form] = Form.useForm();
  const [imgUrlData, setImgUrlData] = useState({
    fitness_certificate: [],
    insurance_copy: [],
    pollution_certificate: [],
    pictures_of_the_vehicle: [],
    rc_copy: [],
  });
  const [errorMessage, setErrorMessage] = useState(false);

  const onFinish = (values) => {
    let error = {};
    if (
      imgUrlData?.rc_copy === undefined ||
      imgUrlData?.rc_copy?.length === 0
    ) {
      error.rc_copy = "RC copy is required!";
    }

    // if (
    //   imgUrlData?.insurance_copy === undefined ||
    //   imgUrlData?.insurance_copy?.length === 0
    // ) {
    //   error.insurance_copy = "Insurance copy is required!";
    // }
    // if (
    //   imgUrlData?.pollution_certificate === undefined ||
    //   imgUrlData?.pollution_certificate?.length === 0
    // ) {
    //   error.pollution_certificate = "Pollution certificate is required!";
    // }
    // if (
    //   imgUrlData?.fitness_certificate === undefined ||
    //   imgUrlData?.fitness_certificate?.length === 0
    // ) {
    //   error.fitness_certificate = "Fitness certificate is required!";
    // }
    if (
      imgUrlData?.pictures_of_the_vehicle === undefined ||
      imgUrlData?.pictures_of_the_vehicle?.length === 0
    ) {
      error.pictures_of_the_vehicle = "Pictures of the vehicle are required!";
    }
    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage(false);
      setFormData({ ...formData, ...imgUrlData });
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    setImgUrlData({
      fitness_certificate: formData?.fitness_certificate
        ? formData?.fitness_certificate
        : [],
      insurance_copy: formData?.insurance_copy ? formData?.insurance_copy : [],
      pollution_certificate: formData?.pollution_certificate
        ? formData?.pollution_certificate
        : [],
      pictures_of_the_vehicle: formData?.pictures_of_the_vehicle
        ? formData?.pictures_of_the_vehicle
        : [],
      rc_copy: formData?.rc_copy ? formData?.rc_copy : [],
    });
  }, []);

  const handleImgUrl = (e, type) => {
    let url = e;
    console.log(imgUrlData, "URL");
    if (imgUrlData.hasOwnProperty(type) && Array.isArray(imgUrlData[type])) {
      let res = imgUrlData[type].concat(e);
      setImgUrlData((prevState) => ({ ...prevState, [type]: res }));
    } else {
      setImgUrlData((prevState) => ({
        ...prevState,
        [type]: url,
      }));
    }
    setErrorMessage(false);
  };

  const handleRemove = (file, type) => {
    console?.log("removeFile:", file);
    let list = [...imgUrlData?.[type]];
    let newList = [];
    list?.find((data) => {
      if (data?.name !== file?.name) {
        newList?.push(data);
      }
      setImgUrlData((prevState) => ({
        ...prevState,
        [type]: newList,
      }));
    });
  };
  return (
    <Form form={form} onFinish={onFinish} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div>
          <div>
            <h4>
              Upload Documents<span style={{ color: "red" }}>*</span>
            </h4>

            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Col style={{ padding: "5px" }}>
                <Form.Item name="rc_copy">
                  <UploadDocument
                    name="Rc Copy"
                    imgUrl={(e) => handleImgUrl(e, "rc_copy")}
                    imgUrlData={imgUrlData}
                    type="rc_copy"
                    handleRemoveFile={(e) => {
                      handleRemove(e, "rc_copy");
                    }}
                  />
                </Form.Item>
                {!!errorMessage?.rc_copy === true ? (
                  <p className="error">{errorMessage?.rc_copy}</p>
                ) : (
                  ""
                )}
              </Col>
              <Col style={{ padding: "5px" }}>
                <Form.Item name="insurance_copy">
                  <UploadDocument
                    name="Insurance Copy"
                    imgUrl={(e) => handleImgUrl(e, "insurance_copy")}
                    imgUrlData={imgUrlData}
                    type="insurance_copy"
                    handleRemoveFile={(e) => {
                      handleRemove(e, "insurance_copy");
                    }}
                  />
                </Form.Item>
                {!!errorMessage?.insurance_copy === true ? (
                  <p className="error">{errorMessage?.insurance_copy}</p>
                ) : (
                  ""
                )}
              </Col>
              <Col style={{ padding: "5px" }}>
                <Form.Item name="pollution_certificate">
                  <UploadDocument
                    name="Pollution Certificate"
                    imgUrl={(e) => handleImgUrl(e, "pollution_certificate")}
                    imgUrlData={imgUrlData}
                    type="pollution_certificate"
                    handleRemoveFile={(e) => {
                      handleRemove(e, "pollution_certificate");
                    }}
                  />
                </Form.Item>
                {!!errorMessage?.pollution_certificate === true ? (
                  <p className="error">{errorMessage?.pollution_certificate}</p>
                ) : (
                  ""
                )}
              </Col>
              <Col style={{ padding: "5px" }}>
                <Form.Item name="fitness_certificate">
                  <UploadDocument
                    name="Fitness Certificate"
                    imgUrl={(e) => handleImgUrl(e, "fitness_certificate")}
                    imgUrlData={imgUrlData}
                    type="fitness_certificate"
                    handleRemoveFile={(e) => {
                      handleRemove(e, "fitness_certificate");
                    }}
                  />
                </Form.Item>
                {!!errorMessage?.fitness_certificate === true ? (
                  <p className="error">{errorMessage?.fitness_certificate}</p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            ></Row>
          </div>
          <div>
            <h4>
              Upload Pictures of the Vehicle
              <span style={{ color: "red" }}>*</span>
            </h4>
            <Col style={{ padding: "5px" }}>
              <Form.Item name="pictures_of_the_vehicle">
                <UploadDocument
                  name="Pictures of the Vehicle"
                  imgUrl={(e) => handleImgUrl(e, "pictures_of_the_vehicle")}
                  imgUrlData={imgUrlData}
                  type="pictures_of_the_vehicle"
                  handleRemoveFile={(e) => {
                    handleRemove(e, "pictures_of_the_vehicle");
                  }}
                />
              </Form.Item>
              {!!errorMessage?.pictures_of_the_vehicle === true ? (
                <p className="error">{errorMessage?.pictures_of_the_vehicle}</p>
              ) : (
                ""
              )}
            </Col>
          </div>
        </div>
      </div>
      <div>
        <Button style={{ margin: "0 8px" }} onClick={handlePrev}>
          <CaretLeftOutlined /> Previous
        </Button>
        <Button
          className="custBtn1"
          type="primary"
          style={{ float: "right" }}
          htmlType="submit"
        >
          Next <CaretRightOutlined />
        </Button>
      </div>
    </Form>
  );
};

export const FourthStepForm = ({
  formData,
  setFormData,
  currentStep,
  setCurrentStep,
  setIsInvolved,
}) => {
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [imgUrlData, setImgUrlData] = useState({ sellerAadhaar: [] });
  const [form] = Form.useForm();
  const [getStateList, setStateList] = useState([]);
  const [getDistList, setDistList] = useState([]);
  const [getCityList, setCityList] = useState([]);
  const { Option } = Select;
  const userData = useSelector((state) => state.appSlice?.userData);

  useEffect(() => {
    getState();
    getDist();
    getCity();
  }, []);
  const getState = async () => {
    try {
      const response = await apiServices.getStateList();
      if (response?.data?.status_code === 1) {
        setStateList(response?.data?.states_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDist = async () => {
    try {
      const response = await apiServices.getDistrictList();
      if (response?.data?.status_code === 1) {
        setDistList(response?.data?.district_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCity = async () => {
    try {
      const response = await apiServices.getCityList();
      if (response?.data?.status_code === 1) {
        setCityList(response?.data?.city_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish = (values) => {
    values.sellerAadhaar = imgUrlData?.sellerAadhaar;
    let error = {};
    if (values?.seller === "" || values?.seller === undefined) {
      error.seller = "Name is required!";
    }
    if (indianMobileRegex.test(values?.mobileNumber) === false) {
      error.mobileNumber = "Enter valid mobile number!";
    }
    // if (values?.email !== "" || values?.email === undefined) {
    //   if (emailRegex.test(values?.email) === false) {
    //     error.email = "Invalid email id!";
    //   }
    // }
    if (values?.address1 === "" || values?.address1 === undefined) {
      error.address1 = "Address 1 is required!";
    }
    if (values?.state_id === "" || values?.state_id === undefined) {
      error.state_id = "State is required!";
    }
    if (values?.district_id === "" || values?.district_id === undefined) {
      error.district_id = "District is required!";
    }
    if (values?.city_id === "" || values?.city_id === undefined) {
      error.city_id = "City is required!";
    }
    if (values?.pinCode === "" || values?.pinCode === undefined) {
      error.pinCode = "Pin code is required!";
    }
    if (values?.aadharNumber === "" || values?.aadharNumber === undefined) {
      error.aadharNumber = "Aadhar number is required!";
    }
    if (
      imgUrlData?.sellerAadhaar === undefined ||
      imgUrlData?.sellerAadhaar?.length === 0
    ) {
      error.aadhaarDocument = "Aadhaar copy is required!";
    }
    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage({});
      // values.buyer = "buyer";
      // setFormData({ ...formData, ...values });
      handleSubmit(values);
    }
  };

  const handleSubmit = async (values) => {
    setLoadingOnSubmit(true);
    let payload = {
      brand: formData?.brand,
      model: formData?.model,
      variant: formData?.variant,
      rto_location: "rto_location",
      vehicle_type: formData?.VehicleType,
      year_of_model: formData?.year_of_model,
      ownership_type: formData?.ownership_type,
      seller: {
        personalDetails: {
          name: values?.seller,
          mobile_number: values?.mobileNumber,
          aadhar_number: values?.aadharNumber,
          aadhar_document: values?.sellerAadhaar[0]?.url,
          pan_card_number: values?.pan_card_number,
          email: values?.email,
        },
        address: {
          address1: values?.address1,
          address2: values?.address2,
          state_id: values?.state_id,
          district_id: values?.district_id,
          city_id: values?.city_id,
          pinCode: values?.pinCode,
        },
      },
      millage: formData?.millage + "KM",
      registration_number: formData?.registration_number,
      dealer: [
        {
          dealer_id:
            userData?._id !== undefined ? userData?._id : storedData?._id,
          status: true,
        },
      ],
      chasis_number: formData?.chasis_number,
      engine_number: formData?.engine_number,
      registration_validity: formData?.regValidity,
      owner_name: formData?.owner_name,
      owners_mobile_number: formData?.owners_mobile_number,
      rc_copy: formData?.rc_copy[0]?.url,
      is_sold: false,
      insurance_copy: formData?.insurance_copy[0]?.url,
      pollution_certificate: formData?.pollution_certificate[0]?.url,
      fitness_certificate: formData?.fitness_certificate[0]?.url,
      pictures_of_the_vehicle: formData?.pictures_of_the_vehicle?.map(
        (data) => {
          return data?.url;
        }
      ),
    };
    try {
      const response = await apiServices.createInwardVehicle(payload);
      console.log(response, "Final Form Api response");
      if (response?.data?.status_code === 1) {
        setTimeout(() => {
          setLoadingOnSubmit(false);
        }, 200);
        message.success("Form submitted successfully!", 2);
        setCurrentStep(0);
        setFormData({});
        setIsInvolved(false);
      } else {
        setTimeout(() => {
          setLoadingOnSubmit(false);
        }, 200);
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      setTimeout(() => {
        setLoadingOnSubmit(false);
      }, 200);
      console.log(error);
      shownotification("error", error?.response?.data?.message);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    form.setFieldsValue({
      seller: formData?.seller,
      mobileNumber: formData?.mobileNumber,
      email: formData?.email,
      address1: formData?.address1,
      state_id: formData?.state_id,
      district_id: formData?.district_id,
      city_id: formData?.city_id,
      pinCode: formData?.pinCode,
      pan_card_number: formData?.pan_card_number,
      aadharNumber: formData?.aadharNumber,
      isSuspiciousVehicle: formData?.isSuspiciousVehicle,
      aadharNumber: formData?.aadharNumber,
      sellerAadhaar: formData?.sellerAadhaar,
    });
  }, []);

  const handleImgUrl = (e, type) => {
    setImgUrlData((prevState) => ({
      ...prevState,
      [type]: e,
    }));
  };

  const handleRemove = (file, type) => {
    console?.log("removeFile:", file);
    let list = [...imgUrlData?.[type]];
    let newList = [];
    list?.find((data) => {
      if (data?.name !== file?.name) {
        newList?.push(data);
      }
      setImgUrlData((prevState) => ({
        ...prevState,
        [type]: newList,
      }));
    });
  };

  const handleChange = (e) => {
    let val = e?.target?.value;
    setIsInvolved(val);
  };

  return (
    <Form form={form} onFinish={onFinish} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Row
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Col style={{ padding: "5px" }}>
            Seller Name<span style={{ color: "red" }}>*</span>
            <Form.Item name="seller">
              <Input
                style={{ width: "180px" }}
                placeholder="Enter seller name."
                status={!!errorMessage?.seller === true ? "error" : null}
              />
            </Form.Item>
            {!!errorMessage?.seller === true ? (
              <p className="error">{errorMessage?.seller}</p>
            ) : (
              ""
            )}
          </Col>
          <Col style={{ padding: "5px" }}>
            Seller Mobile Number<span style={{ color: "red" }}>*</span>
            <Form.Item name="mobileNumber">
              <Input
                style={{ width: "180px" }}
                placeholder="Enter Mobile Number."
                status={!!errorMessage?.mobileNumber === true ? "error" : null}
              />
            </Form.Item>
            {!!errorMessage?.mobileNumber === true ? (
              <p className="error">{errorMessage?.mobileNumber}</p>
            ) : (
              ""
            )}
          </Col>
          <Col style={{ padding: "5px" }}>
            Email
            <Form.Item name="email">
              <Input
                style={{ width: "180px" }}
                placeholder="Enter Email."
                status={!!errorMessage?.email === true ? "error" : null}
              />
            </Form.Item>
            {!!errorMessage?.email === true ? (
              <p className="error">{errorMessage?.email}</p>
            ) : (
              ""
            )}
          </Col>
          <Col style={{ display: "flex" }}>
            <div style={{ width: "282px", marginRight: "5%" }}>
              Address 1<span style={{ color: "red" }}>*</span>
              <Form.Item name="address1">
                <TextArea
                  placeholder="Enter Address 1"
                  status={!!errorMessage?.address1 === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.address1 === true ? (
                <p className="error">{errorMessage?.address1}</p>
              ) : (
                ""
              )}
            </div>
            <div style={{ width: "282px" }}>
              Address 2
              <Form.Item name="address2">
                <TextArea
                  placeholder="Enter Address 2"
                  status={!!errorMessage?.address2 === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.address2 === true ? (
                <p className="error">{errorMessage?.address2}</p>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col style={{ display: "flex" }}>
            <Col
              style={{
                padding: "5px",
                width: "190px",
                marginTop: "10.5px",
              }}
            >
              Select State<span style={{ color: "red" }}>*</span>
              <Form.Item name="state_id">
                <Select
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  status={!!errorMessage?.state_id === true ? "error" : null}
                >
                  <Option disabled>Select State</Option>
                  {getStateList.map((data) => {
                    return (
                      <Option value={data?._id}>{data?.state_name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {!!errorMessage?.state_id === true ? (
                <p className="error">{errorMessage?.state_id}</p>
              ) : (
                ""
              )}
            </Col>
            <Col
              style={{
                padding: "5px",
                width: "190px",
                marginTop: "10.5px",
              }}
            >
              Select District<span style={{ color: "red" }}>*</span>
              <Form.Item name="district_id">
                <Select
                  showSearch
                  placeholder="Select District"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  status={!!errorMessage?.district_id === true ? "error" : null}
                >
                  <Option disabled>Select District</Option>
                  {getDistList &&
                    getDistList.map((data) => {
                      return (
                        <Option value={data?._id}>{data?.district_name}</Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {!!errorMessage?.district_id === true ? (
                <p className="error">{errorMessage?.district_id}</p>
              ) : (
                ""
              )}
            </Col>
            <Col
              style={{
                padding: "5px",
                width: "190px",
                marginTop: "10.5px",
              }}
            >
              Select City<span style={{ color: "red" }}>*</span>
              <Form.Item name="city_id">
                <Select
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  status={!!errorMessage?.city_id === true ? "error" : null}
                >
                  <Option disabled>Select City</Option>
                  {getCityList &&
                    getCityList.map((data) => {
                      return (
                        <Option value={data?._id}>{data?.city_name}</Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {!!errorMessage?.city_id === true ? (
                <p className="error">{errorMessage?.city_id}</p>
              ) : (
                ""
              )}
            </Col>
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "5px", width: "190px" }}>
            PIN Code<span style={{ color: "red" }}>*</span>
            <Form.Item name="pinCode">
              <Input
                placeholder="PIN Code Number"
                status={!!errorMessage?.pinCode === true ? "error" : null}
              />
            </Form.Item>
            {!!errorMessage?.pinCode === true ? (
              <p className="error">{errorMessage?.pinCode}</p>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "5px" }}>
            PAN Number
            <Form.Item name="pan_card_number">
              <Input
                style={{ width: "180px" }}
                placeholder="Enter PAN Card Number..."
              />
            </Form.Item>
          </Col>
          <Col style={{ padding: "5px" }}>
            Aadhaar Number<span style={{ color: "red" }}>*</span>
            <Form.Item name="aadharNumber">
              <Input
                style={{ width: "180px" }}
                placeholder="Enter Aadhaar Number..."
                status={!!errorMessage?.aadharNumber === true ? "error" : null}
              />
            </Form.Item>
            {!!errorMessage?.aadharNumber === true ? (
              <p className="error">{errorMessage?.aadharNumber}</p>
            ) : (
              ""
            )}
          </Col>
          <Col style={{ padding: "5px", marginLeft: "2px" }}>
            Upload Aadhaar<span style={{ color: "red" }}>*</span>
            <Form.Item name="sellerAadhaar">
              <UploadDocument
                name="Upload Aadhaar Document"
                imgUrl={(e) => handleImgUrl(e, "sellerAadhaar")}
                imgUrlData={imgUrlData}
                type="sellerAadhaar"
                handleRemoveFile={(e) => {
                  handleRemove(e, "sellerAadhaar");
                }}
              />
            </Form.Item>
            {!!errorMessage?.aadhaarDocument === true ? (
              <p className="error">{errorMessage?.aadhaarDocument}</p>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row style={{ display: "flex", flexDirection: "column" }}></Row>
      </div>
      <div>
        <Button style={{ margin: "0 8px" }} onClick={handlePrev}>
          <CaretLeftOutlined /> Previous
        </Button>
        <Button
          className="custBtn1"
          type="primary"
          style={{ float: "right" }}
          htmlType="submit"
        >
          Submit {loadingOnSubmit ? <LoadingOutlined /> : <FileTextFilled />}
        </Button>
      </div>
    </Form>
  );
};
