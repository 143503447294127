import React, { useState } from "react";
import { Row, DatePicker, Divider, Tooltip, Button, Space, Tag } from "antd";
import Header from "../../Header/Header";
import { Form, message, Input, Col, Radio } from "antd";
import {
  CloseCircleOutlined,
  FileTextFilled,
  UploadOutlined,
} from "@ant-design/icons";
import apiServices from "../../services/apiServices";
import { useNavigate } from "react-router-dom";
import PersonDetails from "../../CommonComponents/PersonDetails/form";
import GetDate from "../../CommonComponents/GetDate";

const OutwardVehicles = () => {
  const [collectBuyerData, setCollectBuyerData] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [outwardDate, setOutwardDate] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const selectedVehicle = JSON?.parse(localStorage?.getItem("ViewDetailsData"));

  const handleChange = (e) => {
    let val = e?.target?.value;
    val === 1 ? setCollectBuyerData(true) : setCollectBuyerData(false);
  };

  const getDate = (event) => {
    const dateTime = new Date(event);
    const formattedDate = dateTime.toLocaleDateString("en-US", {
      year: "numeric",
    });
    return formattedDate;
  };

  const outwardTemplates = [
    { key: 1, temp: "Dissatisfied with the estimated value." },
    {
      key: 2,
      temp: "Found a more advantageous deal for my vehicle elsewhere.",
    },
    { key: 3, temp: "Change in plan." },
    { key: 4, temp: "I have some unforeseen circumstances." },
    { key: 5, temp: "Other" },
  ];

  const selectedTempelate = (e) => {
    setSelectedMessage(e);
    form.setFieldsValue({ feedback: e });
  };

  const handleFeedbackmsg = (e) => {
    let val = e?.target?.value;
    setSelectedMessage(val);
  };
  const onChange = (e, type) => {
    let date = GetDate(e?._d);
    setOutwardDate(date);
  };

  const handleClick = () => {
    navigate("/vehicleSearch");
  };

  const handleFormData = async (e, type) => {
    let payload = {};
    if (type === "sold") {
      e.personalDetails.aadhar_document = e?.aadhaar;
      payload.id = selectedVehicle?._id;
      payload.buyer = {
        personalDetails: e.personalDetails,
        address: e.address,
      };
      payload.is_sold = true;
      payload.sold_price = e?.sold_price;
    } else {
      payload.feedback = selectedMessage;
      payload.outwardDate = outwardDate;
      payload.is_sold = true;
      payload.is_not_sold = true;
      payload.id = selectedVehicle?._id;
    }
    try {
      const response = await apiServices
        .vehicleOutward(payload)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            message?.success("Details updated successfully");
            console.log("error", response);
          } else {
            console.log("error", response);
          }
          navigate("/vehicleSearch");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    console.log("selectedVehicle", selectedVehicle);
  };

  return (
    <div className="InwardContainer">
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <Row className="InwardSteps">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Tooltip title="Close" overlayInnerStyle={{ borderRadius: "5px" }}>
            <CloseCircleOutlined
              style={{ color: "#9c5403", fontSize: "28px" }}
              onClick={handleClick}
            />
          </Tooltip>

          <span
            style={{ fontSize: "16px", marginLeft: "10px", color: "#9c5403" }}
          >
            Outward Vehicle
          </span>
        </div>
        <Divider />
        <div style={{ width: "70%", marginLeft: "15%" }}>
          <Form form={form} onFinish={onFinish} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Row>
                <span style={{ padding: "5px" }}>
                  Is Vehicle?<span style={{ color: "red" }}>*</span>
                </span>
                <Form.Item
                  name="isVehicleSold"
                  rules={[
                    {
                      required: true,
                      message: "Select any one option!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={handleChange}
                    style={{ marginLeft: "5px" }}
                  >
                    <Radio value={1}>Sold</Radio>
                    <Radio value={2}>Not Sold</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </div>
          </Form>
          {collectBuyerData === "" ? (
            ""
          ) : collectBuyerData === true ? (
            <Row style={{ marginLeft: "-2%" }}>
              <PersonDetails
                type="outwardVehicle"
                isModal={true}
                formData={(e) => {
                  handleFormData(e, "sold");
                }}
              />
            </Row>
          ) : (
            <Form
              form={form}
              onFinish={handleFormData}
              style={{ width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ padding: "5px" }}>
                    Reason for not Sold?<span style={{ color: "red" }}>*</span>
                  </span>
                  <div style={{ width: "300px", marginBottom: "10px" }}>
                    <Space size={[0, "small"]} wrap>
                      {outwardTemplates.map((data) => (
                        <Tag
                          bordered={false}
                          color="geekblue"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            selectedTempelate(e?.target?.outerText)
                          }
                        >
                          {data?.temp}
                        </Tag>
                      ))}
                    </Space>
                  </div>
                  <Form.Item
                    name="feedback"
                    rules={[
                      {
                        required: true,
                        message: "Enter reason for not sold!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Enter Your Reason"
                      style={{ width: "20rem", borderRadius: "5px" }}
                      onChange={(e) => handleFeedbackmsg(e)}
                    />
                  </Form.Item>
                  <Col style={{ padding: "5px" }}>
                    Outward Date<span style={{ color: "red" }}>*</span>
                    <Form.Item
                      name="dateOfSold"
                      rules={[
                        {
                          required: false,
                          message: "Date is required!",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={(e) => {
                          onChange(e, "outwardDate");
                        }}
                        style={{ width: "250px" }}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ padding: "5px" }}>
                    <Button
                      className="custBtn1"
                      type="primary"
                      style={{ float: "right" }}
                      htmlType="submit"
                    >
                      Submit <FileTextFilled />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </div>
      </Row>
    </div>
  );
};

export default OutwardVehicles;
