import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserAuthContextProvider } from "../src/Components/services/UserAuthContext";
import React, { useState } from "react";
import Login from "./Components/Pages/Login/Login";
import ForgotPassword from "./Components/Pages/Login/forgot";
import PrivateRoute from "./PrivateRoute";
import "antd/dist/antd.min.css";
import Dashboard from "./Components/Pages/Dashboard";
import Users from "./Components/Pages/Users";
import InwardVehicles from "./Components/Pages/InwardVehicle";
import OutwardVehicles from "./Components/Pages/OutwardVehicle";
import "./App.css"
import DealerSearch from "./Components/Pages/DealerSearch";
import VehicleSearch from "./Components/Pages/VehicleSearch";
import VerifiedVehicles from "./Components/Pages/VerifiedVehicles";
import CreateDealer from "./Components/Pages/DealerSearch/CreateDealer";
import ViewDetails from "./Components/Pages/VehicleSearch/viewDetails";
import BuyerDetails from "./Components/Pages/BuyerDetails";
import SellerDetails from "./Components/Pages/SellerDetails";
import CreateUser from "./Components/Pages/Users/CreateUser";
import ViewDealerDetails from "./Components/Pages/DealerSearch/DealerView";
import UsersView from "./Components/Pages/Users/UsersView";
import Profile from "./Components/Pages/Profile/profile";
import IoDealer from "./Components/Pages/IODealer";


function App() {
  const [logged, setLogged] = useState(false);
  const navbarHide = () => {
    setLogged(!logged);
  };

  return (
    <UserAuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login navbarHide={navbarHide} />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />

          <Route path="/profile" element={<PrivateRoute component={Profile} />} />
          <Route path="/dashboard" element={< PrivateRoute component={Dashboard} />} />
          <Route path="/dealerSearch" element={< PrivateRoute component={DealerSearch} />} />
          <Route path="/vehicleSearch" element={< PrivateRoute component={VehicleSearch} />} />
          <Route path="/verifiedVehicle" element={< PrivateRoute component={VerifiedVehicles} />} />
          <Route path="/viewDetails" element={< PrivateRoute component={ViewDetails} />} />
          <Route path="/viewDealer" element={< PrivateRoute component={ViewDealerDetails} />} />
          <Route path="/viewUser" element={< PrivateRoute component={UsersView} />} />
          <Route path="/buyerDetails" element={< PrivateRoute component={BuyerDetails} />} />
          <Route path="/sellerDetails" element={< PrivateRoute component={SellerDetails} />} />
          <Route path="/users" element={< PrivateRoute component={Users} />} />
          <Route path="/inwardVehicle" element={< PrivateRoute component={InwardVehicles} />} />
          <Route path="/outwardVehicle" element={< PrivateRoute component={OutwardVehicles} />} />
          <Route path="/createDealer" element={< PrivateRoute component={CreateDealer} />} />
          <Route path="/createUsers" element={< PrivateRoute component={CreateUser} />} />
          <Route path="/ioDealer" element={< PrivateRoute component={IoDealer} />} />


        </Routes>
      </BrowserRouter>
    </UserAuthContextProvider>
  );
}

export default App;
