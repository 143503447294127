import axios from "axios";
//import { useId } from 'react';
//import baseurls from './baseapiurl'
import API_URL from "./apiEndPoints";

const resurl = process.env.REACT_APP_BASE_URL;
const storedData = JSON?.parse(localStorage?.getItem("userData"));
const userRole = localStorage.getItem("user_role");

class apiServices {
  createInwardVehicle(data) {
    let res = axios.post(resurl + API_URL.create_vehicle, data);
    return res;
  }

  createDealer(data) {
    let res = axios.post(resurl + API_URL.create_dealer, data);
    return res;
  }

  createDealerUsers(data, dealerId) {
    let res = axios.post(
      resurl + API_URL.create_users + `?dealer_id=${dealerId}`,
      data
    );
    return res;
  }

  createPerson(data) {
    let res = axios.post(resurl + API_URL.create_person, data);
    return res;
  }

  getBuyerList() {
    let res = axios.get(resurl + API_URL.buyer_List);
    return res;
  }

  getSellerList() {
    let res = axios.get(resurl + API_URL.seller_data);
    return res;
  }
  getPersonList(userData, pagination) {
    let res = axios.get(
      resurl +
        API_URL.person_List +
        `?dealer_id=${
          storedData?._id !== undefined ? storedData?._id : userData?._id
        }&page=${pagination?.page}&limit=${pagination?.size}`
    );
    return res;
  }
  getUserList(token, pagination, searchQuery) {
    const axiosInstance = {
      Authorization: `token ${token}`,
    };
    if (searchQuery) {
      let res = axios.get(
        resurl +
          API_URL.user_List +
          `?page=${pagination?.page}&limit=${pagination?.size}` +
          "&" +
          `${searchQuery}`,
        { headers: axiosInstance }
      );
      return res;
    } else {
      let res = axios.get(
        resurl +
          API_URL.user_List +
          `?page=${pagination?.page}&limit=${pagination?.size}`,
        { headers: axiosInstance }
      );
      return res;
    }
  }
  getVehicleList(querry) {
    let res = axios.get(resurl + API_URL.vehicle_List + `${querry}`);
    return res;
  }
  getDealerList(userData, pagination) {
    let res;
    if (pagination) {
      res = axios.get(
        resurl +
          API_URL.dealer_List +
          `?page=${pagination?.page}&limit=${pagination?.size}`
      );
    } else {
      res = axios.get(resurl + API_URL.dealer_List);
    }
    return res;
  }
  getStateList() {
    let res = axios.get(resurl + API_URL.state_List);
    return res;
  }
  getDistrictList() {
    let res = axios.get(resurl + API_URL.district_List);
    return res;
  }

  getCityList() {
    let res = axios.get(resurl + API_URL.city_List);
    return res;
  }

  getVehicleType() {
    let res = axios.get(resurl + API_URL.vehicle_type);
    return res;
  }

  getModalList() {
    let res = axios.get(resurl + API_URL.modal_list);
    return res;
  }

  getVariantList() {
    let res = axios.get(resurl + API_URL.variant_list);
    return res;
  }

  getBrandList() {
    let res = axios.get(resurl + API_URL.brand_list);
    return res;
  }

  getPoliceStationList() {
    let res = axios.get(resurl + API_URL.police_station_data);
    return res;
  }

  getWidgetCount(token) {
    const axiosInstance = axios.create({
      baseURL: resurl,
      headers: {
        Authorization: `token ${token}`,
      },
    });

    let res = axiosInstance.get(API_URL?.widgetCountApi);
    return res;
  }
  getSelectedDistrict(stateId) {
    let res = axios.get(resurl + API_URL.get_district + `?stateId=${stateId}`);
    return res;
  }

  getSelectedCity(districtId) {
    let res = axios.get(
      resurl + API_URL.get_city + `?districtId=${districtId}`
    );
    return res;
  }

  getSelectedBrand(vehicleType) {
    let res = axios.get(
      resurl + API_URL.get_brand + `?VehicleType=${vehicleType}`
    );
    return res;
  }

  getSelectedModal(id) {
    let res = axios.get(resurl + API_URL.get_modal + `?brandId=${id}`);
    return res;
  }

  getSelectedVariant(id) {
    let res = axios.get(resurl + API_URL.get_variant + `?modelId=${id}`);
    return res;
  }

  updateVehicle(data) {
    let res = axios.put(resurl + API_URL.update_vehicle, data);
    return res;
  }

  updateDealer(data) {
    let res = axios.put(resurl + API_URL.update_dealer, data);
    return res;
  }

  updateBuyer(data) {
    let res = axios.put(resurl + API_URL.update_buyer, data);
    return res;
  }

  updateSeller(data) {
    let res = axios.put(resurl + API_URL.update_seller, data);
    return res;
  }

  uploadImage(data) {
    let res = axios.post(resurl + API_URL.upload_Image, data);
    return res;
  }
  update_person(data) {
    let res = axios.put(resurl + API_URL.update_person, data);
    return res;
  }
  updateUser(data) {
    let res = axios.put(resurl + API_URL.update_user, data);
    return res;
  }

  searchRegistrationNumber(data) {
    let res = axios.get(resurl + API_URL.vehicle_List, { params: data });
    return res;
  }

  searchDealerName(data) {
    let res = axios.get(resurl + API_URL.dealer_List + "?" + `${data}`);
    // { params: data })
    return res;
  }

  searchUser(data) {
    let res = axios.get(resurl + API_URL.user_List + "?" + `${data}`);
    // { params: data })
    return res;
  }

  vehicleOutward(data) {
    let res = axios.put(resurl + API_URL.outward_vehicle, data);
    return res;
  }
}

export default new apiServices();
