import React, { useState } from "react";
import { Divider, Row, Tooltip, message } from "antd";
import Header from "../../Header/Header";
import { CloseCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/apiServices";
import profileDataService from "../../services/profileservices";
import { updateUserData } from "../../services/appSlice";
import { useDispatch, useSelector } from "react-redux";
import PersonDetails from "../../CommonComponents/PersonDetails/form";

const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const storedData = JSON?.parse(localStorage?.getItem("userData"));
  const userData = useSelector((state) => state.appSlice?.userData);

  const handleFormData = async (e) => {
    setLoadingOnSubmit(true);
    let dealerId =
      storedData?._id !== undefined ? storedData?._id : userData?._id;
    let jwtToken = localStorage.getItem("jwtToken");
    let uid = localStorage.getItem("uid");

    console.log("Form submit outward data:", e);
    e.personalDetails.aadhar_document = e?.aadhaar;
    let payload = {
      password: e?.create_password,
      personalDetails: e?.personalDetails,
      address: e?.address,
    };
    try {
      const response = await apiServices
        .createDealerUsers(payload, dealerId)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            setTimeout(() => {
              setLoadingOnSubmit(false);
            }, 200);
            message?.success("User created successfully");
            console.log("error", response);
            profileDataService
              .getuser({ id: uid }, jwtToken)
              .then((response) => {
                debugger;
                if (response.data.status_code !== "0") {
                  console.log(response);
                  dispatch(updateUserData(response?.data?.Data[0]));
                  localStorage.setItem(
                    "userData",
                    JSON.stringify(response.data?.Data[0])
                  );
                } else {
                  console.log("error");
                }
              });
            navigate("/users");
          } else {
            setTimeout(() => {
              setLoadingOnSubmit(false);
            }, 200);
            console.log("error", response);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="createVehicle componentsLoadContainer">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ margin: "10px" }}>
            <Tooltip title="Close" overlayInnerStyle={{ borderRadius: "5px" }}>
              <CloseCircleOutlined
                style={{ color: "#9c5403", fontSize: "28px" }}
                onClick={() => navigate("/users")}
              />
            </Tooltip>
          </div>
          <div style={{ margin: "10px", marginTop: "10px", float: "left" }}>
            <span
              style={{ fontSize: "16px", marginLeft: "10px", color: "#9c5403" }}
            >
              Create Users
            </span>
          </div>
        </div>
        <Divider />
        <PersonDetails
          type="createUser"
          isModal={false}
          formData={(e) => {
            handleFormData(e);
          }}
          isLoading={loadingOnSubmit}
        />
      </div>
    </div>
  );
};
export default CreateUser;
