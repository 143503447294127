import React, { useState } from "react";
import {
  Col,
  Row,
  Space,
  Image,
  Avatar,
  Dropdown,
  Menu,
} from "antd";
import {
  UserOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Header.css";

const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <div>
            <Link
              to="/profile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Profile
            </Link>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <a rel="noopener noreferrer" href="/#" onClick={() => logoutfun()}>
            Logout
          </a>
        ),
      },
    ]}
  />
);

const logoutfun = () => {
  localStorage.clear();
  console.clear();
  document.location.href = "/";
};

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const stateData = useSelector((state) => state.appSlice);
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const user_name = localStorage.getItem("user_name");

  const userRole = localStorage.getItem("user_role");

  const getMenuItems = () => {
    return (
      <Space size={50} className="menuLinks">
          <Link
            to="/dashboard"
            className={splitLocation[1] === "dashboard" ? "active" : ""}
          >
            Dashboard
          </Link>
        {userRole === "IO" ? (
          <Link
            to="/ioDealer"
            className={splitLocation[1] === "ioDealer" ? "active" : ""}
          >
            Dealer
          </Link>
        ) : (
          ""
        )}
        {userRole === "Super Admin" && (
          <Link
            to="/dealerSearch"
            className={splitLocation[1] === "dealerSearch" ? "active" : ""}
          >
            Dealers
          </Link>
        )}
        {userRole === "Dealer" && (
          <Link
            to="/users"
            className={splitLocation[1] === "users" ? "active" : ""}
          >
            Users
          </Link>
        )}

        {userRole === "IO" ? (
          ""
        ) : (
          <Link
            to="/vehicleSearch"
            className={splitLocation[1] === "vehicleSearch" ? "active" : ""}
          >
            Vehicles
          </Link>
        )}

        {/* <Link
              to="/verifiedVehicle"
              className={splitLocation[1] === "verifiedVehicle" ? "active" : ""}
            >
              Verified Vehicle
            </Link> */}

        {userRole === "Dealer" || userRole === "User" ? (
          <>
            {/* <Link to="/buyerDetails"
                className={splitLocation[1] === "buyerDetails" ? "active" : ""}
              >Buyers</Link>

              <Link to="/sellerDetails"
                className={splitLocation[1] === "sellerDetails" ? "active" : ""}
              >Sellers</Link> */}
            <Link
              to="/inwardVehicle"
              className={splitLocation[1] === "inwardVehicle" ? "active" : ""}
            >
              Inward Vehicle
            </Link>

            {/* <Link to="/outwardVehicle"
                className={splitLocation[1] === "outwardVehicle" ? "active" : ""}
              >Outward Vehicle</Link> */}
          </>
        ) : (
          ""
        )}

        {/* <Link
              to="/profile"
              className={splitLocation[1] === "profile" ? "active" : ""}
            >
              Profile
            </Link> */}
      </Space>
    );
  };
  const getUserDetail = (name, role) => {
    return (
      <div style={{ marginLeft: "2px" }}>
        <div style={{ fontSize: "14px" }}>{name}</div>
        <div style={{ fontSize: "10px", float: "right" }}>
          {role === "IO" ? "Investigation Officer" : role}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="header" style={{}}>
        <Row
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* {userRole !== "IO" ? (
            <Col className="menueBtn">
              <MenuOutlined
                style={{ padding: "1%" }}
                onClick={(e) => {
                  setIsVisible(!isVisible);
                }}
              />
            </Col>
          ) : (
            ""
          )} */}
          {isVisible && <div className="menu">{getMenuItems()}</div>}
          <Col style={{ marginTop: "-10px" }}>
            <Image className="logo" src="./images/logo.png" preview={false} />
          </Col>
          <Col className="userBtn">
            <Space size={32}>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <div
                  className="avatar"
                  style={{
                    background: "#FFF",
                    borderRadius: "50px",
                    padding: "1.5px",
                    textAlign: "center",
                    minWidth: "130px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    display: "flex",
                  }}
                >
                  <Avatar
                    size=""
                    icon={<UserOutlined />}
                    style={{ margin: "2px" }}
                    onClick={(e) => e.preventDefault()}
                  />
                  {getUserDetail(user_name, userRole)}
                </div>
              </Dropdown>
            </Space>
          </Col>
        </Row>
        <Col className={userRole ? "menuItems" : ""}>{getMenuItems()}</Col>
      </div>
    </>
  );
};

export default Header;
