import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Divider,
    Card,
    Table,
    Tooltip,
} from "antd";
import Header from "../../Header/Header";
import apiServices from "../../services/apiServices";
import "./dealerSearch.css";
import { CloseCircleOutlined, DownCircleFilled, EditFilled } from "@ant-design/icons";
import Title from "../../CommonComponents/Title";
import GetDate from "../../CommonComponents/GetDate";
import { useNavigate } from "react-router-dom";
import { Round } from "react-lodash";

const ViewDealerDetails = () => {
    const [districtData, setDistrictData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [policeStationData, setPoliceStationData] = useState([]);
    const [dealerData, setDealerData] = useState([]);
    const selectedItem = JSON.parse(localStorage.getItem("ViewDealerData"));
    const [isLoading, setIsLoading] = useState(false);
    const dealerUser = ["name", "mobileNumber", "email", "address", "aadhaarNumber", "aadhaarDocument", "panCard", "updatedTime"];
    const dealerUserColumn = [];
    const dealerViewData = ["name", "mobileNumber", "email", "address", "linkedPoliceStation", "typeOfDealership","alternativeMobileNumber"];
    const dealerViewDataColumns = [];
    const dealerContactsView = ["name", "mobileNumber", "email", "address1", "address2", "aadhaarNumber", "aadhaarDocument", "panCard"]
    const dealerContactsViewColumns = []
    const dataSource = [selectedItem]
    const navigate = useNavigate();


    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
         await apiServices.getDealerList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setDealerData(response?.data?.dealer_data)
                } else {
                }
            })
            .catch((error) => {
                console.log(error)
            })
         await apiServices.getDistrictList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setDistrictData(response?.data?.district_data)
                } else {
                }
            })
            .catch((error) => {
                console.log(error)
            })
         await apiServices.getPoliceStationList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setPoliceStationData(response?.data?.police_station_data)
                } else {
                }
            })
            .catch((error) => {
                console.log(error)
            })
         await apiServices.getCityList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setCityData(response?.data?.city_data)
                } else {

                }
            })
            .catch((error) => {
                console.log(error)
            })
         await apiServices.getStateList()
            .then((response) => {
                if (response?.data?.status_code === 1) {
                    setStateData(response?.data?.states_data)
                } else {

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    dealerUser.map((headers) => {
        switch (headers) {
            case "name":
                dealerUserColumn.push({
                    title: "User Name",
                    dataIndex: "name",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.name?item?.personalDetails?.name:null}</a></span>
                    }
                })
                break;
            case "mobileNumber":
                dealerUserColumn.push({
                    title: "Mobile Number",
                    dataIndex: "mobile_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.mobile_number?item?.personalDetails?.mobile_number:null}</a></span>
                    }
                })
                break;
            case "email":
                dealerUserColumn.push({
                    title: "email",
                    dataIndex: "email",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.email?item?.personalDetails?.email:null}</a></span>
                    }
                })
                break;
            case "address":
                dealerUserColumn.push({
                    title: "Address",
                    dataIndex: "address",
                    render: (_i, item) => {
                        let city, district, state
                        {
                            cityData.map((cityData) => {
                                let x = item?.address?.city_id === cityData?._id ? city = cityData?.city_name : null
                            })
                        }
                        {
                            districtData.map((districtData) => {
                                let x = item?.address?.district_id === districtData?._id ? district = districtData?.district_name : null
                            })
                        }
                        {
                            stateData.map((stateData) => {
                                let x = item?.address?.state_id === stateData?._id ? state = stateData?.state_name : null
                            })
                        }
                        return <span className="tableRowText"><a>{item?.address?.address1 + "," + city + "," + district + "," + state + "," + item?.address?.pinCode}</a></span>
                    }
                })
                break;
            case "aadhaarNumber":
                dealerUserColumn.push({
                    title: "Aadhar Number",
                    dataIndex: "aadhar_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.aadhar_number?item?.personalDetails?.aadhar_number:null}</a></span>
                    }
                })
                break;
            case "aadhaarDocument":
                dealerUserColumn.push({
                    title: "Aadhaar Document",
                    dataIndex: "aadhaar_document",
                    render: (_i, item) => {
                        return (
                            <span className="tableRowText" >
                                <b>
                                    <a href={item?.personalDetails?.aadhar_document ? item?.personalDetails?.aadhar_document : null} target="_blank " style={{ font: "blue" }} >
                                        Click here...{" "}
                                    </a>
                                </b>
                            </span>
                        )
                    }
                })
                break;
            case "panCard":
                dealerUserColumn.push({
                    title: "PAN Number",
                    dataIndex: "pan_card_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.personalDetails?.pan_card_number ? item?.personalDetails?.pan_card_number:null}</a></span>
                    }
                })
                break;
            case "updatedTime":
                dealerUserColumn.push({
                    title: "Updated Time",
                    dataIndex: "updated_time",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.updated_time?GetDate(item?.updated_time):null}</a></span>
                    }
                })
                break;
            // case "action":
            //     dealerUserColumn.push({
            //         title: "Action",
            //         dataIndex: "action",
            //         render: (_i, item) => {
            //             return (
            //                 <div >
            //                     <a><span style={{ fontSize: "16px" }}><DownCircleFilled /></span></a>
            //                     {"  "}
            //                     <a><span style={{ fontSize: "16px", marginLeft: "10px" }}><EditFilled /></span></a>
            //                 </div>
            //             );
            //         }
            //     })
            //     break;
            default:
                return null;
        }
    })

    dealerViewData.map((headers) => {
        switch (headers) {
            case "name":
                dealerViewDataColumns.push({
                    title: "Dealer Name",
                    dataIndex: "dealer_name",
                    render: (_i, item) => {
                        return <span className="tableRowText">{item?.dealer_name?item?.dealer_name:null}</span>
                    }
                })
                break;
            case "mobileNumber":
                dealerViewDataColumns.push({
                    title: "Mobile Number",
                    dataIndex: "mobile_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.mobile_number?item?.mobile_number:null}</a></span>
                    }
                })
                break;
            case "email":
                dealerViewDataColumns.push({
                    title: "Email",
                    dataIndex: "email",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.email?item?.email:null}</a></span>
                    }
                })
                break;
            case "address":
                dealerViewDataColumns.push({
                    title: "Address",
                    dataIndex: "address",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.address?item?.address:null}</a></span>
                    }
                })
                break;
            case "linkedPoliceStation":
                dealerViewDataColumns.push({
                    title: "Linked Police Station",
                    dataIndex: "linked_police_station",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.linked_police_station[0]?.police_station_name}</a></span>
                    }
                })
                break;
            case "typeOfDealership":
                dealerViewDataColumns.push({
                    title: "Type Of Dealership",
                    dataIndex: "type_of_dealership",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.type_of_dealership?item?.type_of_dealership:null}</a></span>
                    }
                })
                break;
                case "alternativeMobileNumber":
                    dealerViewDataColumns.push({
                        title: "Alternative Mobile Number",
                        dataIndex: "alternate_mobile_no",
                        render: (_i, item) => {
                            return <span className="tableRowText"><a>{item?.alternate_mobile_no?item?.alternate_mobile_no:null}</a></span>
                        }
                    })
                    break;
            default:
                return null;
        }
    })

    dealerContactsView.map((headers) => {
        switch (headers) {
            case "name":
                dealerContactsViewColumns.push({
                    title: "Name",
                    dataIndex: "owner_name",
                    render: (_i, item) => {
                        return <span className="tableRowText">{item?.dealer_contacts[0]?.personalDetails?.name?item?.dealer_contacts[0]?.personalDetails?.name:null}</span>
                    }
                })
                break;
            case "mobileNumber":
                dealerContactsViewColumns.push({
                    title: "Mobile Number",
                    dataIndex: "mobile_number",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.mobile_number?item?.mobile_number:null}</a></span>
                    }
                })
                break;
            case "email":
                dealerContactsViewColumns.push({
                    title: "Email",
                    dataIndex: "email",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.email?item?.email:null}</a></span>
                    }
                })
                break;
            case "address1":
                dealerContactsViewColumns.push({
                    title: "Address 1",
                    dataIndex: "address1",
                    render: (_i, item) => {
                        let city, district, state
                        {
                            cityData.map((cityData) => {
                                let x = item?.dealer_contacts[0]?.address?.city_id === cityData?._id ? city = cityData?.city_name : null
                            })
                        }
                        {
                            districtData.map((districtData) => {
                                let x = item?.dealer_contacts[0]?.address?.district_id === districtData?._id ? district = districtData?.district_name : null
                            })
                        }
                        {
                            stateData.map((stateData) => {
                                let x = item?.dealer_contacts[0]?.address?.state_id === stateData?._id ? state = stateData?.state_name : null
                            })
                        }
                        return <span className="tableRowText"><a>{item?.dealer_contacts[0].address?.address1 + "," + city + "," + district + "," + state + "," + item?.dealer_contacts[0].address?.pinCode}</a></span>
                    }
                })
                break;
            case "address2":
                dealerContactsViewColumns.push({
                    title: "Address 2",
                    dataIndex: "address2",
                    render: (_i, item) => {
                        return <span className="tableRowText"><a>{item?.dealer_contacts[0].address?.address2?item?.dealer_contacts[0].address?.address2:null}</a></span>
                    }
                })
                break;
                case "panCard":
                dealerContactsViewColumns.push({
                    title: "Pancard",
                    dataIndex: "pan_card_number",
                    render: (_i, item) => {
                        return <span className="tableRowText">{item?.dealer_contacts[0].personalDetails.pan_card_number?item?.dealer_contacts[0].personalDetails.pan_card_number:null}</span>
                    }
                })
                break;
                case "aadhaarNumber":
                dealerContactsViewColumns.push({
                    title: "Aadhaar Number",
                    dataIndex: "aadhar_number",
                    render: (_i, item) => {
                        return <span className="tableRowText">{item?.dealer_contacts[0].personalDetails.aadhar_number?item?.dealer_contacts[0].personalDetails.aadhar_number:null}</span>
                    }
                })
                break;
            default:
                return null;
        }
    })
    const handleClick = () => {
        navigate("/dealerSearch");
    };
    return (
        <div>
            <Row style={{ marginBottom: "6%" }}>
                <Header />
            </Row>
            <div className="viewDealer componentsLoadContainer" style={{ positon: "relative", padding: "10px" }}>
                <div >
                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ margin: "10px" }}>
                                <Tooltip title="Close" overlayInnerStyle={{borderRadius:"5px"}}>
                                <CloseCircleOutlined
                                    style={{ color: "#9c5403", fontSize: "28px" }}
                                    onClick={handleClick}
                                />
                                </Tooltip>
                            </div>
                            <div style={{ padding: "1%" }}>
                                <Title title="Dealer Details" />
                            </div>
                        </div>
                        <Divider />
                        <Title title="Dealer Details" />
                        <Table
                            className="custom-table"
                            columns={dealerViewDataColumns}
                            dataSource={dataSource}
                            pagination={false}
                        />
                        <Title title="Dealer Contacts" />

                        <Table
                            className="custom-table"
                            columns={dealerContactsViewColumns}
                            dataSource={dataSource}
                            pagination={false}
                        />
                        <Title title="Users" />
                        <Table
                            loading={isLoading}
                            className="custom-table"
                            columns={dealerUserColumn}
                            dataSource={selectedItem?.users}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewDealerDetails;