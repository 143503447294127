import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Divider,
  Row,
  Select,
  message,
  Tooltip,
} from "antd";
import Header from "../../Header/Header";
import {
  CaretRightOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/apiServices";
import PersonDetails from "../../CommonComponents/PersonDetails/form";

const CreateDealer = () => {
  const [errorMessage, setErrorMessage] = useState([]);
  const [formVisible, setFormVisible] = useState(true);
  const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);
  const [dealershipDetails, setDealershipDetails] = useState({});
  const [policeStationData, setPoliceStationData] = useState([]);
  const indianMobileRegex =
    /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/;
  const delearsshipOptions = [
    "Two wheeler",
    "Autos",
    "Cars",
    "Buses/lorry/ heavy vehicles",
  ];

  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (data) => {
    let errors = {};
    if (data?.dealer_name === "" || data?.dealer_name === undefined) {
      errors.dealer_name = "Enter valid name!";
    }
    if (indianMobileRegex.test(data?.mobile_number) === false) {
      errors.mobile_number = "Enter valid mobile number!";
    }
    if (emailRegex.test(data?.email) === false) {
      errors.email = "Enter valid email!";
    }
    if (data?.address === undefined || data?.address === "") {
      errors.address = "Enter address!";
    }
    if (data?.create_password === undefined || data?.create_password === "") {
      errors.create_password = "Create password!";
    }
    if (
      data?.type_of_dealership === undefined ||
      data?.type_of_dealership === ""
    ) {
      errors.type_of_dealership = "Enter dealership type!";
    }
    if (
      data?.linked_police_station === undefined ||
      data?.linked_police_station === ""
    ) {
      errors.linked_police_station = "Select linked police station!";
    }
    if (data?.alternative_mobile_number) {
      if (indianMobileRegex.test(data?.alternative_mobile_number) === false) {
        errors.alternative_mobile_number = "Enter valid mobile number!";
      }
    }
    setErrorMessage(errors);
    if (Object.keys(errors).length === 0) {
      setErrorMessage({});
      setFormVisible(false);
      setDealershipDetails(data);
    } else {
      return null;
    }
  };

  const fetchData = async () => {
    try {
      const response = await apiServices
        .getPoliceStationList()
        .then((response) => {
          setPoliceStationData(response?.data?.police_station_data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (data, type) => {
    setErrorMessage({ ...errorMessage, [type]: false });
  };

  const handlePrevious = (e) => {
    setFormVisible(e);
    form.setFieldValue({
      dealer_name: dealershipDetails?.dealer_name,
      mobile_number: dealershipDetails?.mobile_number,
      email: dealershipDetails?.email,
      address: dealershipDetails?.address,
      create_password: dealershipDetails?.create_password,
      type_of_dealership: dealershipDetails?.type_of_dealership,
      linked_police_station: dealershipDetails?.linked_police_station,
    });
  };

  const handleFormData = async (e) => {
    setLoadingOnSubmit(true);
    console.log("Form submit data:", e);
    e.personalDetails.aadhar_document = e?.aadhaar;
    let payload = {
      dealer_name: dealershipDetails?.dealer_name,
      mobile_number: dealershipDetails?.mobile_number,
      address: dealershipDetails?.address,
      email: dealershipDetails?.email,
      linked_police_station: dealershipDetails?.linked_police_station,
      type_of_dealership: dealershipDetails?.type_of_dealership,
      password: dealershipDetails?.create_password,
      alternate_mobile_no: dealershipDetails?.alternative_mobile_number,
      dealer_contacts: {
        personalDetails: e?.personalDetails,
        address: e?.address,
      },
    };
    try {
      const response = await apiServices
        .createDealer(payload)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            setTimeout(() => {
              setLoadingOnSubmit(false);
            }, 200);
            message?.success("Dealer created successfully");
            form.resetFields();
            setFormVisible(true);
          } else {
            console.log("error", response);
            setTimeout(() => {
              setLoadingOnSubmit(false);
            }, 200);
          }
        });
    } catch (error) {
      setTimeout(() => {
        setLoadingOnSubmit(false);
      }, 200);
      console.log(error);
    }
  };

  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="createVehicle componentsLoadContainer">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ margin: "10px" }}>
            <Tooltip title="Close" overlayInnerStyle={{ borderRadius: "5px" }}>
              <CloseCircleOutlined
                onClick={() => navigate("/dealerSearch")}
                style={{ color: "#9c5403", fontSize: "28px" }}
              />
            </Tooltip>
          </div>
          <div style={{ margin: "10px", marginTop: "10px", float: "left" }}>
            <span
              style={{ fontSize: "16px", marginLeft: "10px", color: "#9c5403" }}
            >
              Create Dealer
            </span>
          </div>
        </div>
        <Divider />
        <div style={{ margin: "10px" }}></div>
        <div style={{ padding: "1%", marginLeft: "15%" }}>
          {formVisible ? (
            <Form onFinish={onFinish} layout="horizental" form={form}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <span style={{ color: "#f08981" }}>*</span>Dealer Name
                    <Form.Item name="dealer_name">
                      <Input
                        placeholder="Enter Dealer Name"
                        status={
                          !!errorMessage?.dealer_name === true ? "error" : null
                        }
                        onChange={(e) => {
                          onChange(e, "dealer_name");
                        }}
                      />
                    </Form.Item>
                    {!!errorMessage?.dealer_name === true ? (
                      <p className="error">{errorMessage?.dealer_name}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <span style={{ color: "#f08981" }}>*</span>Mobile Number
                    <Form.Item name="mobile_number">
                      <Input
                        placeholder="Enter Mobile Number"
                        status={
                          !!errorMessage?.mobile_number === true
                            ? "error"
                            : null
                        }
                        onChange={(e) => {
                          onChange(e, "mobile_number");
                        }}
                      />
                    </Form.Item>
                    {!!errorMessage?.mobile_number === true ? (
                      <p className="error">{errorMessage?.mobile_number}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    Password<span style={{ color: "red" }}>*</span>
                    <Form.Item name="create_password">
                      <Input
                        placeholder="Create Password"
                        status={
                          !!errorMessage?.create_password === true
                            ? "error"
                            : null
                        }
                        onChange={(e) => {
                          onChange(e, "create_password");
                        }}
                      />
                    </Form.Item>
                    {!!errorMessage?.create_password === true ? (
                      <p className="error">{errorMessage?.create_password}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <span style={{ color: "#f08981" }}>*</span>Email
                    <Form.Item name="email">
                      <Input
                        placeholder="Enter Email Address"
                        status={!!errorMessage?.email === true ? "error" : null}
                        onChange={(e) => {
                          onChange(e, "email");
                        }}
                      />
                    </Form.Item>
                    {!!errorMessage?.email === true ? (
                      <p className="error">{errorMessage?.email}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <span style={{ color: "#f08981" }}>*</span>Linked Police
                    Station
                    <Form.Item name="linked_police_station">
                      <Select
                        showSearch
                        placeholder="Select Linked Police Station"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        status={
                          !!errorMessage?.linked_police_station === true
                            ? "error"
                            : null
                        }
                        onChange={(e) => {
                          onChange(e, "linked_police_station");
                        }}
                      >
                        <Option disabled>Select P.S</Option>
                        {policeStationData.map((data) => {
                          return (
                            <Option value={data?._id}>
                              {data?.police_station_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {!!errorMessage?.linked_police_station === true ? (
                      <p className="error">
                        {errorMessage?.linked_police_station}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    Alternative Mobile Number
                    <Form.Item name="alternative_mobile_number">
                      <Input
                        placeholder="Enter alternative Mobile Number"
                        status={
                          !!errorMessage?.alternative_mobile_number === true
                            ? "error"
                            : null
                        }
                      />
                    </Form.Item>
                    {!!errorMessage?.alternative_mobile_number === true ? (
                      <p className="error">
                        {errorMessage?.alternative_mobile_number}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    marginRight: "60px",
                  }}
                >
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <span style={{ color: "red" }}>*</span>Type Of Dealership
                    <Form.Item name="type_of_dealership">
                      <Select
                        style={{ width: "180px" }}
                        showSearch
                        placeholder="Select dealership type"
                        value={delearsshipOptions}
                        status={
                          !!errorMessage?.type_of_dealership === true
                            ? "error"
                            : null
                        }
                      >
                        {delearsshipOptions?.map((data) => (
                          <Select.Option value={data}>{data}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {!!errorMessage?.type_of_dealership === true ? (
                      <p className="error">
                        {errorMessage?.type_of_dealership}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col style={{ padding: "5px", width: "190px" }}>
                    <span style={{ color: "#f08981" }}>*</span>Address
                    <Form.Item name="address">
                      <Input
                        placeholder="Enter Address"
                        status={
                          !!errorMessage?.address === true ? "error" : null
                        }
                        onChange={(e) => {
                          onChange(e, "address");
                        }}
                      />
                    </Form.Item>
                    {!!errorMessage?.address === true ? (
                      <p className="error">{errorMessage?.address}</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Col>
                  <Form.Item>
                    <div className="nextBtn">
                      <Button
                        className="custBtn1"
                        type="primary"
                        htmlType="submit"
                      >
                        Next <CaretRightOutlined />
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </div>
            </Form>
          ) : (
            <Row style={{ marginLeft: "-20%" }}>
              <PersonDetails
                type="createDealer"
                isModal={false}
                formData={(e) => {
                  handleFormData(e);
                }}
                isForm1Visible={(e) => handlePrevious(e)}
                isLoading={loadingOnSubmit}
              />
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateDealer;
