import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Divider,
  Avatar,
  Form,
  Select,
  Tooltip,
  Space,
  message,
  DatePicker,
  Switch,
  Checkbox,
} from "antd";
import Title from "../../CommonComponents/Title";
import Header from "../../Header/Header";
import {
  DoubleRightOutlined,
  DownCircleFilled,
  ExclamationCircleOutlined,
  FilterOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import apiServices from "../../services/apiServices";
import "../Dashboard/dashboard.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CountWidget from "../../CommonComponents/UiComponent/countWidget";
import { ownershipType } from "../InwardVehicle/const";
import moment from "moment";
import GetDate from "../../CommonComponents/GetDate";

const { Search } = Input;

const VehicleSearch = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [current, setCurrent] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [pageSize, setPageSize] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [getStateList, setStateList] = useState([]);
  const [getDistList, setDistList] = useState([]);
  const [getCityList, setCityList] = useState([]);
  const [listType, setListType] = useState(false);
  const [listTypeAll, setListTypeAll] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [switchDisable, setSwitchDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [model, setModel] = useState([]);
  const [brand, setBrand] = useState([]);
  const [variant, setVariant] = useState([]);
  const userRole = localStorage.getItem("user_role");
  const userData = useSelector((state) => state.appSlice?.userData);
  const storedData = JSON?.parse(localStorage?.getItem("userData"));
  const userId = !!userData === false ? userData?._id : storedData?._id;
  const typeList = ["Inward", "Outward", "Unsold"];
  const spaceRegex = /^\S*$/;
  const vehicleDetails =
    userRole === "Super Admin"
      ? [
          "s.no",
          "registrationNumber",
          "VehicleInfo",
          "yearOfModel",
          "variant",
          "type",
          "status",
          "action",
        ]
      : [
          "s.no",
          "registrationNumber",
          "VehicleInfo",
          "yearOfModel",
          "variant",
          "type",
          "inwardDate",
          "action",
        ];
  const pageLimit = [5, 10, 50, 100];
  const navigate = useNavigate();
  const vehicleColumns = [];

  useEffect(() => {
    fetchData(1, 10);
  }, [listType, filterData]);

  const fetchData = async (pages, size) => {
    setCurrent(pages);
    setPageSize(size);
    setIsLoading(true);
    let pageData = { page: pages, size: size };
    let id = userRole !== "Super Admin" ? userId : "";

    let querry;
    if (
      listTypeAll === "all" ||
      listTypeAll === "" ||
      listTypeAll === undefined
    ) {
      querry = `?dealer_id=${id}&page=${pages}&limit=${size}`;
    } else if (listTypeAll === "Inward") {
      querry = `?dealer_id=${id}&page=${pages}&limit=${size}&is_sold=false`;
    } else if (listTypeAll === "Outward") {
      querry = `?dealer_id=${id}&page=${pages}&limit=${size}&is_sold=true&is_not_sold=false`;
    } else {
      querry = `?dealer_id=${id}&page=${pages}&limit=${size}&is_sold=true&is_not_sold=true`;
    }
    let queryString = "";
    if (Object.keys(filterData).length > 0) {
      queryString = Object.keys(filterData)
        .map((key) => `${key}=${filterData[key]}`)
        .join("&");
    }

    let is_superAdmin =
      userRole === "Super Admin"
        ? `?page=${pages}&limit=${size}` + "&" + `${queryString}`
        : Object.keys(filterData).length < 0
        ? querry
        : querry + "&" + `${queryString}`;

    const response = await apiServices
      .getVehicleList(is_superAdmin)
      .then((response) => {
        if (
          (response?.data?.status_code === 1) &
          (response?.data?.vehicle_data?.length > 0)
        ) {
          setVehicleData(response?.data?.vehicle_data);
          setTotalRecords(response?.data?.count);
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            setVehicleData([]);
          }, 200);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(true);
      });
  };
  useEffect(() => {
    getState();
    getDist();
    getCity();
  }, []);

  const getState = async () => {
    try {
      const response = await apiServices.getStateList();
      if (response?.data?.status_code === 1) {
        setStateList(response?.data?.states_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDist = async () => {
    try {
      const response = await apiServices.getDistrictList();
      if (response?.data?.status_code === 1) {
        setDistList(response?.data?.district_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCity = async () => {
    try {
      const response = await apiServices.getCityList();
      if (response?.data?.status_code === 1) {
        setCityList(response?.data?.city_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    filters();
    setPageSize();
  }, []);

  const filters = async () => {
    const response1 = await apiServices
      .getModalList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setModel(response?.data?.model_data);
        } else {
          console.log("error in status code = 0");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const response2 = await apiServices
      .getBrandList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setBrand(response?.data?.brand_data);
        } else {
          console.log("error in status code = 0");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const response3 = await apiServices
      .getVariantList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setVariant(response?.data?.variant_data);
        } else {
          console.log("error in status code = 0");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChangePage = (pagination, filters, sorter) => {
    fetchData(pagination?.current, pagination?.pageSize);
    setCurrent(pagination?.current);
    setPageSize(pagination?.pageSize);
  };

  const handleVehicleViewData = (data) => {
    localStorage.setItem("ViewDetailsData", JSON.stringify(data));
    navigate("/viewDetails");
  };

  const getVehicleInfo = (item) => {
    let brand = item?.brand[0]?.brand_name;
    let model = item?.model[0]?.model_name;
    return <b>{brand + " " + model}</b>;
  };

  vehicleDetails.map((headers) => {
    switch (headers) {
      case "registrationNumber":
        vehicleColumns.push({
          title: "Registration Number",
          dataIndex: "registration_number",
          render: (_i, item) => {
            return <a className="tableRowText">{item?.registration_number}</a>;
          },
        });
        break;
      case "s.no":
        vehicleColumns.push({
          title: "S.No",
          dataIndex: "s.no",
          render: (_i, item, index) => {
            if (current && current > 1) {
              return <span>{(current - 1) * pageSize + index + 1}</span>;
            } else {
              return <span>{index + 1}</span>;
            }
          },
        });
        break;
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  {item?.pictures_of_the_vehicle[0] ? (
                    <Avatar
                      size="large"
                      src={item?.pictures_of_the_vehicle[0]}
                    />
                  ) : (
                    ""
                  )}
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {getVehicleInfo(item)}
                </span>
              </div>
            );
          },
        });
        break;
      case "brand":
        vehicleColumns.push({
          title: "Brand",
          dataIndex: "brand_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.brand[0]?.brand_name}</a>
              </span>
            );
          },
        });
        break;
      case "model":
        vehicleColumns.push({
          title: "Model",
          dataIndex: "model",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.model[0]?.model_name}</span>
            );
          },
        });
        break;
      case "yearOfModel":
        vehicleColumns.push({
          title: "Year of Model",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.year_of_model}</a>
              </span>
            );
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {item?.variant[0]?.variant_name}
              </span>
            );
          },
        });
        break;
      case "type":
        vehicleColumns.push({
          title: "Ownership Type",
          dataIndex: "ownership_type",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.ownership_type}</a>
              </span>
            );
          },
        });
        break;
      case "status":
        vehicleColumns.push({
          title: "Status",
          dataIndex: "is_sold",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a> {item?.is_sold === true ? "Sold" : "Unsold"} </a>
              </span>
            );
          },
        });
        break;
      case "inwardDate":
        vehicleColumns.push({
          title: "Inward Date",
          dataIndex: "created_time",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {GetDate(item?.created_time)}
              </span>
            );
          },
        });
        break;
      case "action":
        vehicleColumns.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <>
                <Button
                  onClick={() => handleVehicleViewData(item)}
                  style={{ marginRight: "8px", marginRight: "2px" }}
                >
                  view
                </Button>
                <Button>QR</Button>
                {userRole === "Dealer" || userRole === "User" ? (
                  item?.is_sold === false || item?.is_sold === null ? (
                    <a>
                      <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                        {/* <img src="./images/truck-svgrepo-com.svg" alt="Outward" style={{width:"3.5%", font:"#9c5403"}}/> */}
                        <Tooltip
                          title="Outward Vehicle"
                          overlayInnerStyle={{ borderRadius: "5px" }}
                        >
                          <UploadOutlined
                            rotate={90}
                            onClick={() => [
                              localStorage.setItem(
                                "ViewDetailsData",
                                JSON.stringify(item)
                              ),
                              navigate("/outwardVehicle"),
                            ]}
                          />
                        </Tooltip>
                      </span>
                    </a>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </>
            );
          },
        });
        break;
      default:
        return null;
    }
  });

  const onSearch = async (data, source) => {
    setSearchData(data);
  };

  const handleSearch = async () => {
    let error = {};
    if (searchData === "") {
      message?.error("Reg number is required!");
      error.search = "Reg number is required!";
    } else if (searchData?.length < 8) {
      message?.error("Enter a valid reg number!");
      error.search = "Enter valid reg number!";
    } else if (spaceRegex.test(searchData) === false) {
      error.search = "White spaces are not allowed";
      message?.error("White spaces are not allowed!");
    }

    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage({});
      const queryParams = {
        registration_number: searchData,
      };
      setIsLoading(true);
      const response = await apiServices
        .searchRegistrationNumber(queryParams)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            setVehicleData(response?.data?.vehicle_data);
            setTotalRecords(response?.data?.count);
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          } else {
            setVehicleData("");
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(true);
        });
    }
  };

  const handleReset = async () => {
    setSearchData("");
    setErrorMessage({});
    setFilterData({});
    fetchData(1, 10);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getDate = (event) => {
    const dateTime = new Date(event);
    const formattedDate = dateTime.toLocaleDateString("en-US", {
      year: "numeric",
    });
    return formattedDate;
  };

  const handleSelect = (e, type) => {
    if (type === "yearOfModel") {
      delete filterData?.mileage;
      let year = getDate(e?._d);
      setFilterData({ ...filterData, ["year_of_model"]: year });
    } else if (type === "brand") {
      delete filterData?.model;
      delete filterData?.variant;
      delete filterData?.ownershipType;
      delete filterData?.year_of_model;
      delete filterData?.mileage;
      setFilterData({ ...filterData, [type]: e });
    } else if (type === "model") {
      delete filterData?.variant;
      delete filterData?.ownershipType;
      delete filterData?.year_of_model;
      delete filterData?.mileage;
      setFilterData({ ...filterData, [type]: e });
    } else if (type === "variant") {
      delete filterData?.ownershipType;
      delete filterData?.year_of_model;
      delete filterData?.mileage;
      setFilterData({ ...filterData, [type]: e });
    } else if (type === "ownershipType") {
      delete filterData?.year_of_model;
      delete filterData?.mileage;
      setFilterData({ ...filterData, [type]: e });
    } else {
      setFilterData({ ...filterData, [type]: e });
    }
  };

  const handleList = (e, type) => {
    if (type === "checkbox") {
      setListTypeAll("all");
      fetchData(1, 10);
    } else {
      setListTypeAll(e);
      fetchData(1, 10);
    }
  };
  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="vehicle componentsLoadContainer">
        <Row className="tableContant">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Space>
                <Title title="Vehicles" />
                {userRole !== "Super Admin" && (
                  <>
                    <Col>
                      <Select
                        showSearch
                        placeholder="Select "
                        optionFilterProp="children"
                        style={{ width: "130px" }}
                        // status={!!errorMessage?.state_id === true ? "error" : null}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(e) => handleList(e, "dropdown")}
                      >
                        <Option disabled>Select State</Option>
                        {typeList.map((data) => {
                          return <Option value={data}>{data}</Option>;
                        })}
                      </Select>
                    </Col>
                    <Title title="All" />
                    <Checkbox
                      onChange={(e) => {
                        handleList(e, "checkbox");
                      }}
                      style={{ marginTop: "-8px" }}
                    />
                  </>
                )}
              </Space>
            </div>

            <div style={{ marginLeft: "-12%", marginTop: "0px" }}>
              <Space direction="verticle">
                <Col>
                  <Input
                    value={searchData}
                    className="inputSearch1"
                    placeholder="Search vehicle"
                    onChange={(e) => onSearch(e.target.value)}
                    suffix={
                      !!errorMessage?.search === true ? (
                        <ExclamationCircleOutlined style={{ color: "red" }} />
                      ) : (
                        ""
                      )
                    }
                    style={{
                      width: "120px",
                      borderRadius: "25px",
                      border:
                        !!errorMessage?.search === true
                          ? "solid red 0.5px"
                          : "solid #d9d9d9 0.5px",
                    }}
                  />
                </Col>
                <button
                  // className="searchBtn"
                  onClick={handleSearch}
                  style={{
                    fontSize: "18px",
                    background: "#FBA03A",
                    border: "none",
                    borderRadius: "49%",
                    color: "#fff",
                  }}
                >
                  <SearchOutlined className="searchIcon" />
                </button>
                <Button onClick={handleReset} style={{ borderRadius: "25px" }}>
                  Reset
                </Button>
              </Space>
            </div>
          </div>
          <Divider style={{ marginTop: "1%" }} />
          <div
            className="custom-scrollbar-container"
            style={{ marginTop: "-1.5%" }}
          >
            <Space direction="verticle">
              <Select
                value={filterData?.brand}
                showSearch
                onSelect={(e) => handleSelect(e, "brand")}
                placeholder="Brand"
                style={{ width: "150px" }}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                <Option disabled>Select Brand</Option>
                {brand.map((data) => {
                  return <Option value={data?._id}>{data?.brand_name}</Option>;
                })}
              </Select>
              <Select
                value={filterData?.model}
                showSearch
                onSelect={(e) => handleSelect(e, "model")}
                placeholder="Model"
                style={{ width: "150px" }}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                <Option disabled>Select Model</Option>
                {model.map((data) => {
                  return <Option value={data?._id}>{data?.model_name}</Option>;
                })}
              </Select>
              <Select
                value={filterData?.variant}
                showSearch
                onSelect={(e) => handleSelect(e, "variant")}
                placeholder="Variant"
                style={{ width: "150px" }}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                <Option disabled>Select Variant</Option>
                {variant.map((data) => {
                  return (
                    <Option value={data?._id}>{data?.variant_name}</Option>
                  );
                })}
              </Select>
              <Select
                value={filterData?.ownershipType}
                showSearch
                onSelect={(e) => handleSelect(e, "ownershipType")}
                placeholder="Ownership Type"
                style={{ width: "150px" }}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                <Option disabled>Select Type</Option>
                {ownershipType.map((data) => {
                  return (
                    <Option value={data?.type} key={data?.key}>
                      {data?.type}
                    </Option>
                  );
                })}
              </Select>
              <DatePicker
                // value={
                //   filterData?.year_of_model
                //     ? moment(filterData?.year_of_model, "YYYY")
                //     : ""
                // }
                picker="year"
                placeholder="Year of model"
                onChange={(e) => {
                  handleSelect(e, "yearOfModel");
                }}
                format={"YYYY"}
                style={{ width: "150px" }}
              />
              <Select
                value={filterData?.mileage}
                showSearch
                onSelect={(e) => handleSelect(e, "mileage")}
                placeholder="Mileage"
                style={{ width: "150px" }}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                <Option disabled>Select Mileage</Option>
              </Select>
              <FilterOutlined />
              <Space>
                <span style={{ fontSize: "13px", width: "150px" }}>
                  FILTERS{" "}
                </span>
              </Space>
            </Space>
          </div>
          <Divider style={{ marginTop: "0.8%" }} />
        </Row>
        <div
          style={{
            justifyContent: "normal",
            padding: "1%",
            marginTop: "-2%",
          }}
        >
          <Table
            className="custom-table"
            columns={vehicleColumns}
            dataSource={vehicleData}
            loading={isLoading}
            onChange={onChangePage}
            rowClassName={(_r, i) =>
              _r?.is_sold !== false || _r.is_sold === null ? "isSoldRow" : ""
            }
            // rowHighlightTest= {}
            pagination={{
              current: current,
              total: totalRecords,
              showSizeChanger: true,
              pageSizeOptions: pageLimit,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleSearch;
