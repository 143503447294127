import { createSlice } from "@reduxjs/toolkit";

const initial_state = {
  sidebarShow: true,
  asideShow: false,
  theme: "default",
  userData: [],
  positionData: [],
  strategyType:'',
  selectedStrategy:{},
  displayDematAccount:false,
  loggedUser:""
};
const appSlice = createSlice({
  name: "app/data",
  initialState: initial_state,
  userData: [],
  positionData: [],
  strategyType:'',
  loggedUser:"",
  selectedStrategy:{},
  displayDematAccount:false,
  reducers: {
    updateUserData: (state, action) => {
      return { ...state, userData: action.payload };
    },
    loggedInUser: (state, action) => {
      return { ...state, loggedUser: action.payload };
    },
    updatePositonData: (state, action) => {
      return { ...state, positionData: action.payload };
    },
    updateSidebarShow: (state, action) => {
      return { ...state, sidebarShow: !state.sidebarShow };
    },
    getStartegyType: (state, action) => {
      return { ...state, strategyType: action.payload };
    },
    getDematAccounts: (state, action) => {
      return { ...state, displayDematAccount: action.payload };
    },
  },
});
export const { updateUserData, updateSidebarShow, updatePositonData, getStartegyType,getSelectedStartegy,getDematAccounts, loggedInUser} =
  appSlice.actions;
export default appSlice.reducer;
