import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Card,
  Divider,
  Avatar,
  Space,
  Tooltip,
  message,
} from "antd";
import Header from "../../Header/Header";
import "./dashboard.css";
import TitleActionsContainer from "../../CommonComponents/TitleActionsContainer";
import { DownCircleFilled } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import apiServices from "../../services/apiServices";
import ImageSlider from "../../CommonComponents/Carousel";
import GetDate from "../../CommonComponents/GetDate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InvestigationDashboard = () => {
  const vehicleDetails = [
    "s.no",
    "registrationNumber",
    "VehicleInfo",
    "yearOfModel",
    "variant",
    "type",
    "action",
  ];
  const [searchData, setSearchData] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isModalOpenVerify, setIsModalOpenVerify] = useState(false);
  const [isModalOpenView, setIsModalOpenView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const pageLimit = [5, 10, 50, 100];
  const vehicleColumns = [];
  const userData = useSelector((state) => state.appSlice?.userData);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const spaceRegex = /^\S*$/;
  const userRole = localStorage.getItem("user_role");

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg?msg:"",
    });
  };

  useEffect(() => {
    getVehicleList(1, 10);
  }, []);

  const getVehicleList = async (pages, size) => {
    let pageData = { page: pages, size: size };
    let querry = `?page=${pages}&limit=${size}`;
    setIsLoading(true);
    try {
      const response = await apiServices.getVehicleList(querry);
      if (response?.data?.status_code === 1) {
        setTotalRecords(response?.data?.count);
        setVehicleList(response?.data?.vehicle_data);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getVehicleInfo = (item) => {
    let brand = item?.brand[0]?.brand_name;
    let model = item?.model[0]?.model_name;
    // let year_of_model = item?.year_of_model
    return <b>{brand + " " + model}</b>;
  };

  const onChangePage = (pagination, filters, sorter) => {
    let querry = `?page=${pagination?.current}&limit=${pagination?.pageSize}`;
    getVehicleList(querry);
    setCurrent(pagination?.current);
  };

  const handleVehicleViewData = (data) => {
    localStorage.setItem("ViewDetailsData", JSON.stringify(data));
    navigate("/viewDetails");
  };

  //eslint-disable-next-line
  vehicleDetails.map((headers) => {
    switch (headers) {
      case "s.no":
        vehicleColumns.push({
          title: "S.No",
          dataIndex: "s.no",
          render: (_i, item, index) => {
            return <span>{index + 1}</span>;
          },
        });
        break;
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  <Avatar size="large" src={item?.pictures_of_the_vehicle[0]} />
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {getVehicleInfo(item)}
                </span>
              </div>
            );
          },
        });
        break;
      case "brand":
        vehicleColumns.push({
          title: "Brand",
          dataIndex: "brand_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.brand[0]?.brand_name}</a>
              </span>
            );
          },
        });
        break;
      case "model":
        vehicleColumns.push({
          title: "Model",
          dataIndex: "model",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.model[0]?.model_name}</span>
            );
          },
        });
        break;
      case "yearOfModel":
        vehicleColumns.push({
          title: "Year of Model",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.year_of_model}</a>
              </span>
            );
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {item?.variant[0]?.variant_name}
              </span>
            );
          },
        });
        break;
      case "type":
        vehicleColumns.push({
          title: "Ownership Type",
          dataIndex: "ownership_type",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.ownership_type}</a>
              </span>
            );
          },
        });
        break;
      case "registrationNumber":
        vehicleColumns.push({
          title: "Registration Number",
          dataIndex: "registration_number",
          render: (_i, item) => {
            return <a className="tableRowText">{item?.registration_number}</a>;
          },
        });
        break;
      case "action":
        vehicleColumns.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <>
                <a>
                  <span style={{ fontSize: "16px" }}>
                    <Tooltip
                      title="View"
                      overlayInnerStyle={{ borderRadius: "5px" }}
                    >
                      <DownCircleFilled
                        onClick={() => handleVehicleViewData(item)}
                      />
                    </Tooltip>
                  </span>
                </a>
              </>
            );
          },
        });
        break;
      default:
        return null;
    }
  });

  const widgetDetails = [
    { title: "Registerd Vehicles", value: "112893" },
    { title: "Registerd Dealers", value: "112893" },
    { title: "Inward Vehicles", value: "112893" },
    { title: "Outward Vehicles", value: "112893" },
  ];

  const openModal = (type, item) => {
    if (type === "verify") {
      setIsModalOpenVerify(true);
    } else {
      setIsModalOpenView(true);
      setSelectedItem(item);
    }
  };

  const images = [
    { key: 1, value: selectedItem?.pictures_of_the_vehicle },
    { key: 2, value: selectedItem?.pictures_of_the_vehicle },
    { key: 3, value: selectedItem?.pictures_of_the_vehicle },
  ];

  const handleOk = (type) => {
    type === "verify" ? setIsModalOpenVerify(false) : setIsModalOpenView(false);
  };

  const handleCancel = (type) => {
    type === "verify" ? setIsModalOpenVerify(false) : setIsModalOpenView(false);
  };

  const getModalTitle = (item) => {
    return (
      <>
        <b>
          {item?.brand !== undefined && item?.brand[0]?.brand_name}{" "}
          {item?.model !== undefined && item?.model[0]?.model_name}
        </b>
        <Divider type="vertical" />
        <b>{item?.year_of_model !== undefined && item?.year_of_model}</b>
        <Divider type="vertical" />
        <b>{item?.variant !== undefined && item?.variant[0]?.variant_name}</b>
        <Divider type="vertical" />{" "}
        <b>{item?.millage !== undefined && item?.millage}</b>
      </>
    );
  };

  const viewDetailedInfo = (item) => {
    return (
      <>
        <div>
          <div className="vehicleDetails">
            <Card className="card">
              <b>Owner Details</b>
              <Divider />
              <Col>
                <span>Owner Name</span> <b>{item?.owner_name}</b>
              </Col>
              <Col>
                <span>Owner Number</span> <b>{item?.owners_mobile_number}</b>
              </Col>
            </Card>
            <Card className="card">
              <b>Registration Details</b>
              <Divider />
              <Col>
                <span>Reg Number</span> <b>{item?.registration_number}</b>
              </Col>
              <Col>
                <span>Reg Validity</span>{" "}
                <b>{GetDate(item?.registration_validity)}</b>
              </Col>
              <Col>
                <span>Chasis Number</span> <b>{item?.chasis_number}</b>
              </Col>
            </Card>
            <Card className="card">
              <b>Documents Attached</b>
              <Divider />
              <Col>
                <span>RC Copy</span>{" "}
                <b>
                  <a href={item?.rc_copy} target="_blank ">
                    {" "}
                    Click here...{" "}
                  </a>
                </b>
              </Col>
              <Col>
                <span>Insurance Copy</span>{" "}
                <b>
                  <a href={item?.insurance_copy} target="_blank ">
                    {" "}
                    Click here...{" "}
                  </a>
                </b>
              </Col>
              <Col>
                <span>Pollution Certificate</span>{" "}
                <b>
                  <a href={item?.pollution_certificate} target="_blank ">
                    {" "}
                    Click here...{" "}
                  </a>
                </b>
              </Col>
              <Col>
                <span>Fitness Certificate</span>{" "}
                <b>
                  <a href={item?.fitness_certificate} target="_blank ">
                    {" "}
                    Click here...{" "}
                  </a>
                </b>
              </Col>
            </Card>
          </div>
          <ImageSlider images={images} />
        </div>
      </>
    );
  };

  const handleChange = (e) => {
    setSearchData(e?.target?.value);
  };

  const onSearch = async (data, source) => {
    if (spaceRegex.test(data) === false) {
      error("Spaces are not allowed!");
    } else {
      setIsLoading(true);
      const queryParams = {
        registration_number: searchData,
        role: userRole,
      };
      const response = await apiServices
        .searchRegistrationNumber(queryParams)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            setVehicleData(response?.data?.vehicle_data);
            setTotalRecords(response?.data?.count);
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          } else if (response?.data?.status_code === 0) {
            error(response?.data?.message);
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
            setVehicleData("")
          } else {
            error("Invalid details")
            setVehicleData("");
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(true);
        });
    }
  };

  const handleReset = async () => {
    setSearchData("");
    setVehicleData([]);
    // fetchData(1, 10)
  };
  return (
    <div>
      {contextHolder}
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="componentsLoadContainer" style={{ padding: "1%" }}>
        <TitleActionsContainer>
          {/* <CountWidget title="Dashboard" /> */}
        </TitleActionsContainer>
        <Row className="cards">
          {/* {widgetDetails?.map((data) => (
            <Card className="card">
              <div gutter={8}>
                <Row>
                  <Statistic title={data?.title} value={data?.value} />
                </Row>
              </div>
            </Card>
          ))} */}
        </Row>
        <div>
          <Row
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            <Space direction="verticle">
              <span>Search Vehicle</span>
              <Search
                value={searchData}
                placeholder="Enter Registration Number"
                // allowClear
                onChange={handleChange}
                enterButton="Search"
                // size="large"
                onSearch={onSearch}
              />
              <Button onClick={handleReset}>Reset</Button>
            </Space>
          </Row>
        </div>
        <Divider />
        <div>
          {vehicleData?.length > 0 && (
            <Table
              className="custom-table"
              columns={vehicleColumns}
              dataSource={vehicleData}
              loading={isLoading}
              onChange={onChangePage}
              pagination={{
                current: current,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: pageLimit,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestigationDashboard;
