import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import apiServices from "../../services/apiServices";
import TextArea from "antd/lib/input/TextArea";
import UploadDocument from "../../CommonComponents/UploadDocument";
import NumericInput from "../UiComponent/inputNumric";
import {
  CaretLeftOutlined,
  FileTextFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import "./form.css";

const PersonDetails = (props) => {
  const [getStateList, setStateList] = useState([]);
  const [getDistList, setDistList] = useState([]);
  const [getCityList, setCityList] = useState([]);
  const [imgUrlData, setImgUrlData] = useState({ aadhaarDocument: [] });
  const [formValues, setFormValues] = useState({});
  const [outwardDate, setOutwardDate] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [stateSelect, setStateSelect] = useState("");
  const [districtSelect, setDistrictSelect] = useState("");

  const { Option } = Select;
  const [form] = Form.useForm();
  const storedData = JSON?.parse(localStorage?.getItem("userData"));
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const indianMobileRegex =
    /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/;

  useEffect(() => {
    getState();
    getDist();
    getCity();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: props?.values?.name,
      mobileNumber: props?.values?.mobileNumber,
      email: props?.values?.email,
      aadharNumber: props?.values?.aadharNumber,
      pan_card_number: props?.values?.pan_card_number,
      aadharDocument: props?.values?.aadhar_document,
      address1: props?.values?.address1,
      address2: props?.values?.address2,
      pinCode: props?.values?.pinCode,
      state_id: props?.values?.state_id,
      district_id: props?.values?.district_id,
      city_id: props?.values?.city_id,
    });
  }, [props?.values]);

  const getState = async () => {
    try {
      const response = await apiServices.getStateList();
      if (response?.data?.status_code === 1) {
        setStateList(response?.data?.states_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      district_id: "",
      city_id: "",
    });
    getDist();
  }, [stateSelect]);

  const getDist = async () => {
    if (stateSelect) {
      try {
        const response = await apiServices.getSelectedDistrict(stateSelect);
        if (response?.data?.status_code === 1) {
          setDistList(response?.data?.district_data);
        } else {
          console.log("error", "Data Not Availble !");
        }
      } catch (error) {
        console.log("error");
      }
    } else {
      setDistList("");
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      city_id: "",
    });
    getCity();
  }, [districtSelect]);

  const getCity = async () => {
    if (districtSelect) {
      try {
        const response = await apiServices.getSelectedCity(districtSelect);
        if (response?.data?.status_code === 1) {
          setCityList(response?.data?.city_data);
        } else {
          console.log("error", "Data Not Availble !");
        }
      } catch (error) {
        console.log("error");
      }
    } else {
      setCityList("");
    }
  };

  const handleImgUrl = (e, type) => {
    setImgUrlData((prevState) => ({
      ...prevState,
      [type]: e,
    }));
  };

  useEffect(() => {
    console?.log("uploaded list", imgUrlData);
  }, [imgUrlData]);

  const onChangeDate = (date, dateString) => {
    setOutwardDate(dateString);
  };

  const onChange = (e, type) => {
    if (type === "state_id") {
      setDistList("");
      setCityList("");
      setStateSelect(e);
    }
    if (type === "district_id") {
      setDistrictSelect(e);
    }
    setFormValues({ ...formValues, [type]: e });
  };

  const onFinish = async (data) => {
    console.log(data);
    let soldPrice = Number(data?.sold_price);
    Object?.assign((data.dateOfSold = outwardDate));
    let error = {};
    if (data?.name === "" || data?.name === undefined) {
      error.name = "Name is required!";
    }
    if (indianMobileRegex.test(data?.mobileNumber) === false) {
      error.mobileNumber = "Invalid mobile number!";
    }
    if (
      props?.type === "createUser" &&
      emailRegex.test(data?.email) === false
    ) {
      error.email = "Email is required!";
    }
    if (
      props?.type === "createUser" &&
      emailRegex.test(data?.email) === false
    ) {
      error.email = "Invalid email id!";
    }
    if (data?.address1 === "" || data?.address1 === undefined) {
      error.address1 = "Address 1 is required!";
    }
    if (data?.state_id === "" || data?.state_id === undefined) {
      error.state_id = "State is required!";
    }
    if (data?.district_id === "" || data?.district_id === undefined) {
      error.district_id = "District is required!";
    }
    if (data?.city_id === "" || data?.city_id === undefined) {
      error.city_id = "City is required!";
    }
    if (data?.pinCode === "" || data?.pinCode === undefined) {
      error.pinCode = "Pin code is required!";
    }

    if (data?.aadharNumber === "" || data?.aadharNumber === undefined) {
      error.aadharNumber = "Aadhar number is required!";
    }
    if (
      imgUrlData?.aadhaarDocument === undefined ||
      imgUrlData?.aadhaarDocument?.length === 0
    ) {
      error.aadhaarDocument = "Aadhaar copy is required!";
    }
    if (
      (data?.create_password === undefined || data?.create_password === "") &&
      props?.type === "createUser"
    ) {
      error.create_password = "Create password is required!";
    }
    if (
      (data?.dateOfSold === undefined || data?.dateOfSold === "") &&
      props?.type === "outwardVehicle"
    ) {
      error.dateOfSold = "Outward date is required!";
    }
    if (soldPrice === NaN) {
      error.sold_price = "Sold price is required!";
    }
    if (
      (data?.sold_price === undefined || data?.sold_price === "") &&
      props?.type === "outwardVehicle"
    ) {
      error.sold_price = "Sold price is required!";
    }
    setErrorMessage(error);
    if (Object.keys(error).length === 0) {
      setErrorMessage({});
      let userData = {
        personalDetails: {
          name: data?.name,
          mobile_number: data?.mobileNumber,
          email: data?.email,
          aadhar_number: data?.aadharNumber,
          pan_card_number: data?.pan_card_number,
        },
        address: {
          address1: data?.address1,
          address2: data?.address2,
          state_id: data?.state_id,
          district_id: data?.district_id,
          city_id: data?.city_id,
          pinCode: data?.pinCode,
        },
        aadhaar:
          imgUrlData?.aadhaarDocument !== undefined
            ? imgUrlData?.aadhaarDocument[0]?.url
            : "",
        create_password: data?.create_password,
        sold_price: data?.sold_price,
        dealer: [{ dealer_id: storedData?._id, status: true }],
      };
      props?.formData(userData);
    } else {
      return null;
    }
  };
  const handleRemove = (file, type) => {
    console?.log("removeFile:", file);
    let list = [...imgUrlData?.[type]];
    let newList = [];
    list?.find((data) => {
      if (data?.name !== file?.name) {
        newList?.push(data);
      }
      setImgUrlData((prevState) => ({
        ...prevState,
        [type]: newList,
      }));
    });
  };
  return (
    <div style={{ padding: "1%", marginLeft: props?.isModal ? "" : "15%" }}>
      {props?.type === "outwardVehicle" && (
        <h3 style={{ padding: "1%" }}>Enter Buyer Details</h3>
      )}
      <Form
        className="Form"
        form={form}
        onFinish={onFinish}
        style={{
          width: props?.isModal ? "100%" : "90%",
          display: "flex",
          padding: "1%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Row className="Row">
            <Col style={{ padding: "5px" }}>
              Name<span style={{ color: "red" }}>*</span>
              <Form.Item name="name">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    onChange(e, "name");
                  }}
                  status={!!errorMessage?.name === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.name === true ? (
                <p className="error">{errorMessage?.name}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Mobile Number<span style={{ color: "red" }}>*</span>
              <Form.Item name="mobileNumber">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Mobile Number"
                  onChange={(e) => {
                    onChange(e, "mobileNumber");
                  }}
                  status={
                    !!errorMessage?.mobileNumber === true ? "error" : null
                  }
                />
              </Form.Item>
              {!!errorMessage?.mobileNumber === true ? (
                <p className="error">{errorMessage?.mobileNumber}</p>
              ) : (
                ""
              )}
            </Col>
            <Col style={{ padding: "5px" }}>
              Email
              {props?.type === "createUser" && (
                <span style={{ color: "red" }}>*</span>
              )}
              <Form.Item name="email">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter Email"
                  onChange={(e) => {
                    onChange(e, "email");
                  }}
                  status={!!errorMessage?.email === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.email === true ? (
                <p className="error">{errorMessage?.email}</p>
              ) : (
                ""
              )}
            </Col>
            <Col className="textBoxContainer1">
              <div className="textBoxContant" style={{}}>
                Address 1<span style={{ color: "red" }}>*</span>
                <Form.Item name="address1">
                  <TextArea
                    placeholder="Enter Address 1"
                    status={!!errorMessage?.address1 === true ? "error" : null}
                    onChange={(e) => {
                      onChange(e, "address1");
                    }}
                  />
                </Form.Item>
                {!!errorMessage?.address1 === true ? (
                  <p className="error">{errorMessage?.address1}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="textBoxContant">
                Address 2
                <Form.Item name="address2">
                  <TextArea
                    placeholder="Enter Address 2"
                    onChange={(e) => {
                      onChange(e, "address2");
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className="textBoxContainer2">
              <Col
                style={{
                  padding: "5px",
                  width: "190px",
                  marginTop: "10.5px",
                }}
              >
                Select State<span style={{ color: "red" }}>*</span>
                <Form.Item name="state_id">
                  <Select
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    status={!!errorMessage?.state_id === true ? "error" : null}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e) => {
                      onChange(e, "state_id");
                    }}
                  >
                    <Option disabled>Select State</Option>
                    {getStateList.map((data) => {
                      return (
                        <Option value={data?._id}>{data?.state_name}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {!!errorMessage?.state_id === true ? (
                  <p className="error">{errorMessage?.state_id}</p>
                ) : (
                  ""
                )}
              </Col>
              <Col
                style={{
                  padding: "5px",
                  width: "190px",
                  marginTop: "10.5px",
                }}
              >
                Select District<span style={{ color: "red" }}>*</span>
                <Form.Item name="district_id">
                  <Select
                    showSearch
                    placeholder="Select District"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e) => {
                      onChange(e, "district_id");
                    }}
                    status={
                      !!errorMessage?.district_id === true ? "error" : null
                    }
                  >
                    <Option disabled>Select District</Option>
                    {getDistList &&
                      getDistList.map((data) => {
                        return (
                          <Option value={data?._id}>
                            {data?.district_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {!!errorMessage?.district_id === true ? (
                  <p className="error">{errorMessage?.district_id}</p>
                ) : (
                  ""
                )}
              </Col>
              <Col
                style={{
                  padding: "5px",
                  width: "190px",
                  marginTop: "10.5px",
                }}
              >
                Select City<span style={{ color: "red" }}>*</span>
                <Form.Item name="city_id">
                  <Select
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e) => {
                      onChange(e, "city_id");
                    }}
                    status={!!errorMessage?.city_id === true ? "error" : null}
                  >
                    <Option disabled>Select City</Option>
                    {getCityList &&
                      getCityList.map((data) => {
                        return (
                          <Option value={data?._id}>{data?.city_name}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {!!errorMessage?.city_id === true ? (
                  <p className="error">{errorMessage?.city_id}</p>
                ) : (
                  ""
                )}
              </Col>
            </Col>
          </Row>
          <Row className="Row">
            <Col style={{ padding: "5px", width: "190px" }}>
              Pin Code<span style={{ color: "red" }}>*</span>
              <Form.Item name="pinCode">
                <Input
                  placeholder="PIN Code Number"
                  status={!!errorMessage?.pinCode === true ? "error" : null}
                  onChange={(e) => {
                    onChange(e, "pinCode");
                  }}
                />
              </Form.Item>
              {!!errorMessage?.pinCode === true ? (
                <p className="error">{errorMessage?.pinCode}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row className="Row">
            <Col style={{ padding: "5px" }}>
              PAN Number
              <Form.Item name="pan_card_number">
                <Input
                  style={{ width: "180px" }}
                  placeholder="Enter PAN Card Number"
                  onChange={(e) => {
                    onChange(e, "pan_card_number");
                  }}
                />
              </Form.Item>
            </Col>
            <Col style={{ padding: "5px" }}>
              Aadhaar Number<span style={{ color: "red" }}>*</span>
              <Form.Item name="aadharNumber">
                <NumericInput
                  placeholder="Enter Aadhaar Number"
                  status={
                    !!errorMessage?.aadharNumber === true ? "error" : null
                  }
                  style={{ width: "180px" }}
                  onChange={(e) => {
                    onChange(e, "aadharNumber");
                  }}
                />
              </Form.Item>
              {!!errorMessage?.aadharNumber === true ? (
                <p className="error">{errorMessage?.aadharNumber}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
        <div>
          <Col style={{ padding: "5px" }}>
            Upload Aadhaar<span style={{ color: "red" }}>*</span>
            <Form.Item name="aadhaarDocument">
              <UploadDocument
                name="Upload Aadhaar Document"
                imgUrl={(e) => handleImgUrl(e, "aadhaarDocument")}
                imgUrlData={imgUrlData}
                type="aadhaarDocument"
                handleRemoveFile={(e) => {
                  handleRemove(e, "aadhaarDocument");
                }}
              />
            </Form.Item>
            {!!errorMessage?.aadhaarDocument === true ? (
              <p className="error">{errorMessage?.aadhaarDocument}</p>
            ) : (
              ""
            )}
          </Col>
          {props?.type === "outwardVehicle" && (
            <Col style={{ padding: "5px" }}>
              Outward Date<span style={{ color: "red" }}>*</span>
              <Form.Item name="dateOfSold">
                <DatePicker
                  onChange={onChangeDate}
                  placeholder="Select Date"
                  style={{ width: "210px" }}
                  status={!!errorMessage?.dateOfSold === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.dateOfSold === true ? (
                <p className="error">{errorMessage?.dateOfSold}</p>
              ) : (
                ""
              )}
            </Col>
          )}
          {props.type === "outwardVehicle" && (
            <Col style={{ padding: "5px" }}>
              Sold Price
              <Form.Item name="sold_price">
                <Input
                  style={{ width: "210px" }}
                  placeholder="Sold price"
                  // onChange={(e) => {
                  //   onChange(e, "pan_card_number");
                  // }}
                  status={!!errorMessage?.sold_price === true ? "error" : null}
                />
              </Form.Item>
              {!!errorMessage?.sold_price === true ? (
                <p className="error">{errorMessage?.sold_price}</p>
              ) : (
                ""
              )}
            </Col>
          )}
          {props?.type === "createUser" && (
            <Col style={{ padding: "5px", width: "220px" }}>
              Password<span style={{ color: "red" }}>*</span>
              <Form.Item name="create_password">
                <Input.Password
                  type="password"
                  placeholder="Create Password"
                  status={
                    !!errorMessage?.create_password === true ? "error" : null
                  }
                  onChange={(e) => {
                    onChange(e, "create_password");
                  }}
                />
              </Form.Item>
              {!!errorMessage?.create_password === true ? (
                <p className="error">{errorMessage?.create_password}</p>
              ) : (
                ""
              )}
            </Col>
          )}
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: props?.type === "createDealer" ? "" : "7.5rem",
            }}
          >
            {props?.type === "createDealer" && (
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    props?.isForm1Visible(true);
                  }}
                >
                  <CaretLeftOutlined /> Previous
                </Button>
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit{" "}
                {props?.isLoading ? <LoadingOutlined /> : <FileTextFilled />}
              </Button>
            </Form.Item>
          </Col>
        </div>
      </Form>
    </div>
  );
};
export default PersonDetails;
