import {
  Form,
  Row,
  Col,
  Divider,
  Input,
  Button,
    } from "antd";
import Title from "../Title";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";


const AdvanceSearch = (props) =>{
const [searchData, setSearchData] = useState({});
const [form] = Form.useForm();
//   searchType = "dealerSearch"
let fieldName;
let fieldType = ["Dealer", "User"]
fieldName = fieldType.find(str => str === props?.searchType);

const handleSearchInput = (e, type) =>{
  if(type === "name" && props?.searchType=== "Dealer"){
      setSearchData({...searchData, ["dealer_name"]:e?.target?.value}) 
  }else if(type === "name" && props?.searchType=== "User"){
      setSearchData({...searchData, ["name"]:e?.target?.value}) 
  }else{
      setSearchData({...searchData, [type]:e?.target?.value}) 
  }
}

const handleSubmit = (data) =>{
 props?.searchData(searchData)
}
  return(
      <div
          className="componentsLoadContainer toggleSearch"
          style={{ width: "23%", padding: "0.7%" }}
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col style={{ fontSize: "17px" }}>
              <Title title={"Search" + " "+`${fieldName}`} />
            </Col>
            <CloseCircleOutlined
              className="searchIcon"
              style={{ fontSize: "26px", color: "#9c5403" }}
              onClick={() => props.setOpenSearch(false) }
            />
          </Row>
          <Divider style={{ marginTop: "-0.1px" }} />
          <Form
            className="Form"
            form={form}
            onFinish={handleSubmit}
            // style={{ padding: "1%"}}
          >
            <Row style={{ marginLeft: "10%" }}>
              <Col style={{ padding: "5px" }}>
               {fieldName} Name
                <Form.Item name="name">
                  <Input
                    style={{ width: "180px" }}
                    placeholder="Enter Name"
                    onChange={(e)=>{handleSearchInput(e, "name")}}
                    />
                </Form.Item>
              </Col>
              <Col style={{ padding: "5px" }}>
                Email
                <Form.Item name="email">
                  <Input
                    style={{ width: "180px" }}
                    placeholder="Enter Email"
                    onChange={(e)=>{handleSearchInput(e, "email")}}
                    />
                </Form.Item>
              </Col>
              <Col style={{ padding: "5px" }}>
                Mobile Number
                <Form.Item name="mobile_number">
                  <Input
                    style={{ width: "180px" }}
                    placeholder="Enter Mobile Number"
                    onChange={(e)=>{handleSearchInput(e, "mobile_number")}}
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row
              className="toggleBtn"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Col>
                <Form.Item>
                  <Button
                    size="small"
                    type="primary"
                    onClick={(e)=>[props?.handleReset(e), form.resetFields() ]}
                    style={{ marginRight: "10px" }}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="small">
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
  )
}

export default AdvanceSearch