import React from "react"

const Title = ({title}) => {
  return (
    <>
      <h3 style={{marginTop:"6px", color:"#9c5403"}}>{title}</h3>
    </>
  )
}

export default Title
