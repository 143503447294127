import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCY5P-Pd2VsylknLEMDQlos6_2g0tceUeg",
  authDomain: "safegadi-2b2a6.firebaseapp.com",
  projectId: "safegadi-2b2a6",
  storageBucket: "safegadi-2b2a6.appspot.com",
  messagingSenderId: "889460725089",
  appId: "1:889460725089:web:61e65726b8478d54306120",
  measurementId: "G-KLH12QWRMC"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
