import { React, createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updatePassword,

  // getIdToken,
} from "firebase/auth";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
const userAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function updatePasswordUser(newPassword) {
    return updatePassword(user, newPassword);
  }
  function generatePasswordResetLink(email) {
    return sendPasswordResetEmail(auth, email);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, "", "");
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }
  function token() {
    var token = "";
    onAuthStateChanged(auth, (currentuser) => {
      token = currentuser?.accessToken;
    });
    return token;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      localStorage.setItem("jwtToken", currentuser?.accessToken);
      localStorage.setItem("currentuser", JSON.stringify(currentuser));
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        updatePasswordUser,
        signUp,
        logOut,
        googleSignIn,
        setUpRecaptha,
        generatePasswordResetLink,
        token,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
