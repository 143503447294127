import {
  Divider,
  Col,
  Carousel,
  Card,
  Button,
  Row,
  Modal,
  Form,
  Input,
  Select,
  Avatar,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import { CloseCircleOutlined, EditFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import GetDate from "../../CommonComponents/GetDate";
import Title from "../../CommonComponents/Title";
import UploadDocument from "../../CommonComponents/UploadDocument";
import TextArea from "antd/lib/input/TextArea";
import apiServices from "../../services/apiServices";
import PersonDetails from "../../CommonComponents/PersonDetails/form";
import CountWidget from "../../CommonComponents/UiComponent/countWidget";

const ViewDetails = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState();
  const [formValues, setFormValues] = useState({});
  const [imgUrlData, setImgUrlData] = useState({});
  const [form] = Form.useForm();
  const [getStateList, setStateList] = useState([]);
  const [getDistList, setDistList] = useState([]);
  const [getCityList, setCityList] = useState([]);
  const { Option } = Select;
  const [open, setOpen] = useState(false);
  const [modelTitle, setModelTitle] = useState("");
  const navigate = useNavigate();
  const vehicleData = JSON.parse(localStorage.getItem("ViewDetailsData"));
  const userRole = localStorage.getItem("user_role");
  const vehicleDetails = [vehicleData];
  // const vehicleDetails=  Object.keys(vehicleData).map((key) => ({ [key]: vehicleData[key] }));

  const getAddress = (type) => {
    let detailsOf = type === "Seller" ? "seller" : "buyer";

    let address1 = vehicleData[detailsOf][0]?.address?.address1;
    let address2 =
      vehicleData[detailsOf][0]?.address?.address2 !== undefined
        ? vehicleData[detailsOf][0]?.address?.address2
        : "";
    let state = vehicleData[detailsOf][0]?.address?.state;
    let district = vehicleData[detailsOf][0]?.address?.district;
    let city = vehicleData[detailsOf][0]?.address?.city;
    let pinCode = vehicleData[detailsOf][0]?.address?.pinCode;
    return `${address1} ${address2} ${pinCode}`;
  };

  const getVehicleInfo = (item) => {
    let brand = item?.brand[0]?.brand_name;
    let model = item?.model[0]?.model_name;
    return <b>{brand + " " + model}</b>;
  };
  const delearInfo = [
    "dealer_name",
    "email",
    "mobile_number",
    "type_of_dealership",
    "linked_police_station",
    "created_time",
    "address",
  ];
  const dataIndex1 = [
    "Vehile Name",
    "VehicleInfo",
    "brand",
    "yearOfModel",
    "variant",
    "Mileage",
    "type",
    "inward_date",
    "sold_price",
  ];
  const dataIndex2 = ["ownerName", "ownerNumber"];
  const dataIndex3 = [
    "name",
    "number",
    "email",
    "pan_card_number",
    "aadhaar",
    "address",
    "Action",
  ];

  const dataIndex4 = [
    "registrationNumber",
    "registrationValidity",
    "chasisNumber",
    "rcCopy",
    "insuranceCopy",
    "pollutionCertificate",
    "fitnessCertificate",
  ];
  const dealerShipDetails = [];
  const vehicleColumns = [];
  const regDetails = [];
  const ownerDetails = [];
  const buyerDetails = [];
  const sellerDetails = [];

  delearInfo.map((headers) => {
    switch (headers) {
      case "dealer_name":
        dealerShipDetails.push({
          title: "Dealer Name",
          dataIndex: "dealer_name",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.dealer_name}
                </span>
              </div>
            );
          },
        });
        break;
      case "email":
        dealerShipDetails.push({
          title: "Email",
          dataIndex: "email",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.email}
                </span>
              </div>
            );
          },
        });
        break;
      case "mobile_number":
        dealerShipDetails.push({
          title: "Mobile Number",
          dataIndex: "mobile_number",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.mobile_number}</span>;
          },
        });
        break;
      case "type_of_dealership":
        dealerShipDetails.push({
          title: "Dealership Type",
          dataIndex: "type_of_dealership",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.type_of_dealership}</span>
            );
          },
        });
        break;
      case "linked_police_station":
        dealerShipDetails.push({
          title: "Linked PS",
          dataIndex: "linked_police_station",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.linked_police_station[0]?.police_station_name}</a>
              </span>
            );
          },
        });
        break;
      case "created_time":
        dealerShipDetails.push({
          title: "Inward Date",
          dataIndex: "created_time",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {GetDate(item?.created_time)}
              </span>
            );
          },
        });
        break;
      case "address":
        dealerShipDetails.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.address}</span>;
          },
        });
        break;
      default:
        return null;
    }
  });
  dataIndex1.map((headers) => {
    switch (headers) {
      case "brand":
        vehicleColumns.push({
          title: "Brand",
          dataIndex: "brand",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.brand[0]?.brand_name}
                </span>
              </div>
            );
          },
        });
        break;
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {getVehicleInfo(item)}
                </span>
              </div>
            );
          },
        });
        break;
      case "yearOfModel":
        vehicleColumns.push({
          title: "Year of Model",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.year_of_model}</a>
              </span>
            );
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {item?.variant[0]?.variant_name}
              </span>
            );
          },
        });
        break;
      case "type":
        vehicleColumns.push({
          title: "Ownership Type",
          dataIndex: "ownership_type",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.ownership_type}</a>
              </span>
            );
          },
        });
        break;
      case "registrationNumber":
        vehicleColumns.push({
          title: "Registration Number",
          dataIndex: "registration_number",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.registration_number}</span>
            );
          },
        });
        break;
      case "Mileage":
        vehicleColumns.push({
          title: "Mileage",
          dataIndex: "Mileage",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.millage}</span>;
          },
        });
        break;
      case "inward_date":
        vehicleColumns.push({
          title: "Inward Date",
          dataIndex: "created_time",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {GetDate(item?.created_time)}
              </span>
            );
          },
        });
        break;
      case "sold_price":
        vehicleColumns.push({
          title: "Sold price",
          dataIndex: "sold_price",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.sold_price}</a>
              </span>
            );
          },
        });
        break;
      // case "registrationValidity":
      //   vehicleColumns.push({
      //     title: "Reg. Validity",
      //     dataIndex: "registrationValidity",
      //     render: (_i, item) => {
      //       return (
      //         <span className="tableRowText">
      //           {GetDate(item?.registration_validity)}
      //         </span>
      //       );
      //     },
      //   });
      //   break;
      // case "chasisNumber":
      //   vehicleColumns.push({
      //     title: "Chasis Number",
      //     dataIndex: "chasisNumber",
      //     render: (_i, item) => {
      //       return <span className="tableRowText">{item?.chasis_number}</span>;
      //     },
      //   });
      //   break;
      // case "rcCopy":
      //   vehicleColumns.push({
      //     title: "RC Copy",
      //     dataIndex: "rcCopy",
      //     render: (_i, item) => {
      //       return (
      //         <span className="tableRowText">
      //           <b>
      //             <a href={item?.rc_copy} target="_blank ">
      //               Click here...{" "}
      //             </a>
      //           </b>
      //         </span>
      //       );
      //     },
      //   });
      //   break;
      // case "insuranceCopy":
      //   vehicleColumns.push({
      //     title: "Insurance Copy",
      //     dataIndex: "insuranceCopy",
      //     render: (_i, item) => {
      //       return (
      //         <span className="tableRowText">
      //           <b>
      //             <a href={item?.insurance_copy} target="_blank ">
      //               Click here...{" "}
      //             </a>
      //           </b>
      //         </span>
      //       );
      //     },
      //   });
      //   break;
      // case "pollutionCertificate":
      //   vehicleColumns.push({
      //     title: "Pollution Certificate",
      //     dataIndex: "pollutionCertificate",
      //     render: (_i, item) => {
      //       return (
      //         <span className="tableRowText">
      //           <b>
      //             <a href={item?.pollution_certificate} target="_blank ">
      //               Click here...{" "}
      //             </a>
      //           </b>
      //         </span>
      //       );
      //     },
      //   });
      //   break;
      // case "fitnessCertificate":
      //   vehicleColumns.push({
      //     title: "Fitness Certificate",
      //     dataIndex: "fitnessCertificate",
      //     render: (_i, item) => {
      //       return (
      //         <span className="tableRowText">
      //           <b>
      //             <a href={item?.fitness_certificate} target="_blank ">
      //               Click here...{" "}
      //             </a>
      //           </b>
      //         </span>
      //       );
      //     },
      //   });
      //   break;
      default:
        return null;
    }
  });
  dataIndex4.map((headers) => {
    switch (headers) {
      case "registrationValidity":
        regDetails.push({
          title: "Reg. Validity",
          dataIndex: "registrationValidity",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {GetDate(item?.registration_validity)}
              </span>
            );
          },
        });
        break;
      case "chasisNumber":
        regDetails.push({
          title: "Chasis Number",
          dataIndex: "chasisNumber",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.chasis_number}</span>;
          },
        });
        break;
      case "rcCopy":
        regDetails.push({
          title: "RC Copy",
          dataIndex: "rcCopy",
          render: (_i, item) => {
            return (
              <span className="">
                <b>
                  <a
                    href={item?.rc_copy}
                    target="_blank "
                    style={{ font: "blue" }}
                  >
                    Click here...{" "}
                  </a>
                </b>
              </span>
            );
          },
        });
        break;
      case "insuranceCopy":
        regDetails.push({
          title: "Insurance Copy",
          dataIndex: "insuranceCopy",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <b>
                  <a href={item?.insurance_copy} target="_blank ">
                    Click here...{" "}
                  </a>
                </b>
              </span>
            );
          },
        });
        break;
      case "pollutionCertificate":
        regDetails.push({
          title: "Pollution Certificate",
          dataIndex: "pollutionCertificate",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <b>
                  <a href={item?.pollution_certificate} target="_blank ">
                    Click here...{" "}
                  </a>
                </b>
              </span>
            );
          },
        });
        break;
      case "fitnessCertificate":
        regDetails.push({
          title: "Fitness Certificate",
          dataIndex: "fitnessCertificate",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <b>
                  <a href={item?.fitness_certificate} target="_blank ">
                    Click here...{" "}
                  </a>
                </b>
              </span>
            );
          },
        });
        break;
      default:
        return null;
    }
  });
  dataIndex2.map((headers) => {
    switch (headers) {
      case "ownerName":
        ownerDetails.push({
          title: "Owner Name",
          dataIndex: "ownerName",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.owner_name}
                </span>
              </div>
            );
          },
        });
        break;
      case "ownerNumber":
        ownerDetails.push({
          title: "Owner Number",
          dataIndex: "ownerNumber",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.owners_mobile_number}
                </span>
              </div>
            );
          },
        });
        break;
      default:
        return null;
    }
  });

  dataIndex3.map((headers) => {
    switch (headers) {
      case "name":
        buyerDetails.push({
          title: "Name",
          dataIndex: "name",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.buyer[0]?.personalDetails?.name}
                </span>
              </div>
            );
          },
        });
        break;
      case "number":
        buyerDetails.push({
          title: "Mobile Number",
          dataIndex: "number",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.buyer[0]?.personalDetails?.mobile_number}
                </span>
              </div>
            );
          },
        });
        break;
      case "email":
        buyerDetails.push({
          title: "Email",
          dataIndex: "email",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.buyer[0]?.personalDetails?.email}
                </span>
              </div>
            );
          },
        });
        break;
      case "pan_card_number":
        buyerDetails.push({
          title: "PAN Number",
          dataIndex: "pan_card_number",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.buyer[0]?.personalDetails?.pan_card_number}
                </span>
              </div>
            );
          },
        });
        break;
      case "aadhaar":
        buyerDetails.push({
          title: "Aadhaar",
          dataIndex: "aadhaar",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                {vehicleData?.buyer?.length !== 0 ? (
                  <span className="tableRowText" style={{ marginTop: "4%" }}>
                    <b>
                      <a
                        href={item?.buyer[0]?.personalDetails?.aadhar_document}
                        target="_blank "
                      >
                        Click here...
                      </a>
                    </b>
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
          },
        });
        break;
      case "address":
        buyerDetails.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                {
                  <span className="tableRowText" style={{ marginTop: "4%" }}>
                    {vehicleData?.buyer?.length !== 0
                      ? getAddress("Buyer")
                      : ""}
                  </span>
                }
              </div>
            );
          },
        });
        break;
      case "Action":
        buyerDetails.push({
          title: "Action",
          dataIndex: "Action",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                {
                  <span className="tableRowText" style={{ marginTop: "4%" }}>
                    {userRole === "Dealer" || userRole === "User" ? (
                      <EditFilled
                        onClick={() => handleEditDetials("Seller")}
                        style={{ fontSize: "16px", color: "#9c5403" }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                }
              </div>
            );
          },
        });
        break;
      default:
        return null;
    }
  });

  dataIndex3.map((headers) => {
    switch (headers) {
      case "name":
        sellerDetails.push({
          title: "Name",
          dataIndex: "name",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.seller[0]?.personalDetails?.name}
                </span>
              </div>
            );
          },
        });
        break;
      case "number":
        sellerDetails.push({
          title: "Mobile Number",
          dataIndex: "number",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.seller[0]?.personalDetails?.mobile_number}
                </span>
              </div>
            );
          },
        });
        break;
      case "email":
        sellerDetails.push({
          title: "Email",
          dataIndex: "email",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.seller[0]?.personalDetails?.email}
                </span>
              </div>
            );
          },
        });
        break;
      case "pan_card_number":
        sellerDetails.push({
          title: "PAN Number",
          dataIndex: "pan_card_number",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.seller[0]?.personalDetails?.pan_card_number}
                </span>
              </div>
            );
          },
        });
        break;
      case "aadhaar":
        sellerDetails.push({
          title: "Aadhaar",
          dataIndex: "aadhaar",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                {vehicleData?.seller?.length !== 0 ? (
                  <span className="tableRowText" style={{ marginTop: "4%" }}>
                    <b>
                      <a
                        href={item?.seller[0]?.personalDetails?.aadhar_document}
                        target="_blank "
                      >
                        Click here...
                      </a>
                    </b>
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
          },
        });
        break;
      case "address":
        sellerDetails.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                {
                  <span className="tableRowText" style={{ marginTop: "4%" }}>
                    {vehicleData?.seller?.length !== 0
                      ? getAddress("Seller")
                      : ""}
                  </span>
                }
              </div>
            );
          },
        });
        break;
      case "Action":
        sellerDetails.push({
          title: "Action",
          dataIndex: "Action",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                {
                  <span className="tableRowText" style={{ marginTop: "4%" }}>
                    {userRole === "Dealer" || userRole === "User" ? (
                      <EditFilled
                        onClick={() => handleEditDetials("Buyer")}
                        style={{ fontSize: "16px", color: "#9c5403" }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                }
              </div>
            );
          },
        });
        break;
      default:
        return null;
    }
  });
  const handleEditDetials = (type) => {
    let detailsOf = type === "Seller" ? "seller" : "buyer";
    setOpen(true);
    setModelTitle(type);
    setFormValues({
      name: vehicleData[detailsOf][0]?.personalDetails?.name,
      mobileNumber: vehicleData[detailsOf][0]?.personalDetails?.mobile_number,
      email: vehicleData[detailsOf][0]?.personalDetails?.email,
      aadharNumber: vehicleData[detailsOf][0]?.personalDetails?.aadhar_number,
      pan_card_number:
        vehicleData[detailsOf][0]?.personalDetails?.pan_card_number,
      aadharDocument:
        vehicleData[detailsOf][0]?.personalDetails?.aadhar_document,
      address1: vehicleData[detailsOf][0]?.address?.address1,
      address2: vehicleData[detailsOf][0]?.address?.address2,
      pinCode: vehicleData[detailsOf][0]?.address?.pinCode,
      state_id: vehicleData[detailsOf][0]?.address?.state_id,
      district_id: vehicleData[detailsOf][0]?.address?.district_id,
      city_id: vehicleData[detailsOf][0]?.address?.city_id,
    });
  };
  const getDetails = (type) => {
    let detailsOf = type === "Seller" ? "seller" : "buyer";
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>{type}</Col>
          <Col style={{ marginBottom: "1%" }}>
            {type === "Buyer" && vehicleData[detailsOf][0] === undefined ? (
              <span style={{ fontSize: "16px", color: "#9c5403" }}>
                <CountWidget title="Not sold" />
              </span>
            ) : userRole === "Dealer" || userRole === "User" ? (
              <EditFilled
                onClick={() => handleEditDetials(type)}
                style={{ fontSize: "16px", color: "#9c5403" }}
              />
            ) : (
              ""
            )}
          </Col>
        </div>
        <Card>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "1%" }}>
              <div> Name</div>
              <div> Mobile </div>
              <div> Email</div>
              <div> PAN Number</div>
              <div> Aadhaar</div>
              <div> Address</div>
            </div>
            <div>
              <div>
                <b>
                  {vehicleData[detailsOf][0] ? (
                    vehicleData[detailsOf][0]?.personalDetails?.name
                  ) : (
                    <span style={{ color: "white" }}>-</span>
                  )}
                </b>
              </div>
              <div>
                <b>
                  {vehicleData[detailsOf][0] ? (
                    vehicleData[detailsOf][0]?.personalDetails?.mobile_number
                  ) : (
                    <span style={{ color: "white" }}>-</span>
                  )}
                </b>
              </div>
              <div>
                <b>
                  {vehicleData[detailsOf][0] ? (
                    vehicleData[detailsOf][0]?.personalDetails?.email
                  ) : (
                    <span style={{ color: "white" }}>-</span>
                  )}
                </b>
              </div>
              <div>
                <b>
                  {vehicleData[detailsOf][0] ? (
                    vehicleData[detailsOf][0]?.personalDetails?.pan_card_number
                  ) : (
                    <span style={{ color: "white" }}>-</span>
                  )}
                </b>
              </div>
              <div>
                <b>
                  <a
                    href={
                      vehicleData[detailsOf][0]?.personalDetails
                        ?.aadhar_document
                    }
                    target="_blank "
                  >
                    {" "}
                    {vehicleData[detailsOf][0] ? (
                      "Click here..."
                    ) : (
                      <span style={{ color: "white" }}>-</span>
                    )}
                  </a>
                </b>
              </div>
              <div>
                <b>
                  {vehicleData[detailsOf][0] ? (
                    getAddress(type)
                  ) : (
                    <span style={{ color: "white" }}>-</span>
                  )}
                </b>
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  };
  const CustomPrevArrow = ({ onClick }) => (
    <Button
      className="carousel-arrow"
      onClick={onClick}
      style={{ background: "#fff" }}
    >
      &lt;
    </Button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <Button className="carousel-arrow" onClick={onClick}>
      &gt;
    </Button>
  );

  const handleClick = () => {
    if (userRole !== "IO") {
      navigate("/vehicleSearch");
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    getState();
    getDist();
    getCity();
  }, []);

  const getState = async () => {
    try {
      const response = await apiServices.getStateList();
      if (response?.data?.status_code === 1) {
        setStateList(response?.data?.states_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDist = async () => {
    try {
      const response = await apiServices.getDistrictList();
      if (response?.data?.status_code === 1) {
        setDistList(response?.data?.district_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCity = async () => {
    try {
      const response = await apiServices.getCityList();
      if (response?.data?.status_code === 1) {
        setCityList(response?.data?.city_data);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish = (values) => {
    values.sellerAadhaar = imgUrlData?.sellerAadhaar;
    values.buyer = "buyer";
    setFormData({ ...formData, ...values });
  };

  useEffect(() => {}, []);

  const handleImgUrl = (e, type) => {
    setImgUrlData((prevState) => ({
      ...prevState,
      [type]: e,
    }));
  };

  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div
        className="componentsLoadContainer"
        style={{ fontSize: "16px", marginTop: "8%" }}
      >
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col style={{ margin: "10px" }}>
            <CloseCircleOutlined
              style={{ color: "#9c5403", fontSize: "28px" }}
              onClick={handleClick}
            />
          </Col>
          <Col style={{ padding: "1%" }}>
            <Title title={"Vehicle Details"} />
          </Col>
        </Row>
        <Divider />
        <div>
          <div
            className="custom-scrollbar-container"
            style={{ padding: "1%", marginLeft: "0%" }}
          >
            <Title title={"Vehicle Details"} />
            <Table
              className="custom-table"
              columns={vehicleColumns}
              dataSource={vehicleDetails}
              pagination={false}
            />
          </div>
          <div
            className="custom-scrollbar-container"
            style={{ padding: "1%", marginLeft: "0%" }}
          >
            <Title title={"Reg. Details"} />
            <Table
              className="custom-table"
              columns={regDetails}
              dataSource={vehicleDetails}
              pagination={false}
            />
          </div>
          <div
            className="custom-scrollbar-container"
            style={{ padding: "1%", marginLeft: "0%" }}
          >
            <Title title={"Owner Details"} />
            <Table
              className="custom-table"
              columns={ownerDetails}
              dataSource={vehicleDetails}
              pagination={false}
            />
          </div>
        </div>
        <div>
          <div
            className="custom-scrollbar-container"
            style={{ padding: "1%", marginLeft: "0%" }}
          >
            <Title title={"Dealership Details"} />
            <Table
              className="custom-table"
              columns={dealerShipDetails}
              dataSource={vehicleDetails[0]?.dealer}
              pagination={false}
            />
          </div>
          <div
            className="custom-scrollbar-container"
            style={{ padding: "1%", marginLeft: "0%" }}
          >
            <Title title="Seller Details" />
            <Table
              className="custom-table"
              columns={sellerDetails}
              dataSource={vehicleDetails}
              pagination={false}
            />
          </div>
          {vehicleData?.buyer?.length !== 0 ? (
            <div
              className="custom-scrollbar-container"
              style={{ padding: "1%", marginLeft: "0%" }}
            >
              <Title title="Buyer Details" />
              <Table
                className="custom-table"
                columns={buyerDetails}
                dataSource={vehicleDetails}
                pagination={false}
              />
            </div>
          ) : (
            ""
          )}
          <Modal
            title={"Edit " + modelTitle}
            open={open}
            footer={false}
            onCancel={() => {
              setOpen(false);
            }}
          >
            <PersonDetails
              type={modelTitle}
              isModal={true}
              values={formValues}
            />
          </Modal>
          <Divider />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="divCarousel">
            <Carousel
              autoplay
              arrows={true}
              prevArrow={<CustomPrevArrow />}
              nextArrow={<CustomNextArrow />}
              className="imgCarousel"
            >
              {vehicleData &&
                vehicleData?.pictures_of_the_vehicle.map((data, idx) => (
                  <div key={idx + 1}>
                    <img className="img" src={data} alt={`Image ${idx + 1}`} />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewDetails;
