import React, { useState } from "react";
import { Upload, Button, message, List, Tooltip, Modal } from "antd";
import { DeleteOutlined, FullscreenOutlined, UploadOutlined } from "@ant-design/icons";
import apiServices from "../../services/apiServices";

const UploadDocument = (props) => {
  const [preview, setPreview] = useState(false)
  const [selectedImg, setSelectedImg] = useState("")
  const handleUpload = async (file) => {
    try {
      let url = [];
      let fileObj = {};
      const formData = new FormData();
      formData.append("image", file);

      fileObj.name = file?.name;
      fileObj.size = file?.size;
      fileObj.type = file?.type;

      const response = await apiServices.uploadImage(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.status_code === 1) {
        fileObj.url = response?.data?.image_url;
        url.push(fileObj);
        props?.imgUrl(url);
        message.success("Image uploaded successfully!");
      } else {
        message.error("Failed to upload image.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = (file) => {
    console?.log("remove file:", file);
    props?.handleRemoveFile(file);
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      if (file?.size < 125000) {
        if (props?.type === "pictures_of_the_vehicle") {
        let item = [...props?.imgUrlData?.pictures_of_the_vehicle]
        const isDuplicate = item.some(itm => itm.name === file?.name);
          if (props?.imgUrlData["pictures_of_the_vehicle"]?.length < 5 && !isDuplicate) {
            handleUpload(file)
              .then(() => onSuccess(file))
              .catch(() => onError(file));
          } else {
            !isDuplicate?message.error("File upload limit exceeds."):message.error("File already exist.");
          }
        } else {
          if (props?.imgUrlData[props?.type]?.length < 1) {
            handleUpload(file)
              .then(() => onSuccess(file))
              .catch(() => onError(file));
          } else {
            message.error("File upload limit exceeds.");
          }
        }
      } else {
        onError(file);
        message.error("File size exceeds the allowed limit (100KB).");
      }
    }, 0);
  };

  const handlePreview = (file) =>{
    setPreview(true) 
    setSelectedImg(file?.url)
  }
  const handleClose = ()=>{
    setPreview(false)
  }

  return (
    <>
      <Upload
        customRequest={customRequest}
        showUploadList={false}
        accept="image/*"
        maxCount={props?.type === "pictures_of_the_vehicle" ? 5 : 1}
      >
        <Button icon={<UploadOutlined />}>{props?.name}</Button>
      </Upload>
      {props?.imgUrlData[props?.type]?.length > 0 && (
        <List
          dataSource={props?.imgUrlData[props?.type]}
          renderItem={(file) => (
            <List.Item
              actions={[
               <div>

                <Tooltip title={"Preview"}> 
              <FullscreenOutlined onClick={() => handlePreview(file)} style={{marginRight:"6px"}}/>
              </Tooltip>
              <Tooltip title={"Delete"}> 
              <DeleteOutlined onClick={() => handleRemove(file)} />
              </Tooltip>
              </div>
            ]}
            >
              {file.name}
            </List.Item>
          )}
        />
      )}
      <Modal open={preview} onCancel={handleClose} footer={false} centered>
      <iframe
          title="Preview"
          src={selectedImg}
          style={{ width: '70%', height: '300px', border: 'none',marginLeft:"20%", alignContent:"center" }}
        />   
      </Modal>
    </>
  );
};

export default UploadDocument;
