import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Statistic, Table, Card, Divider, Avatar } from "antd";
import Title from "../../CommonComponents/Title";
import Header from "../../Header/Header";
import "./dashboard.css";
import { CarOutlined } from "@ant-design/icons";
import apiServices from "../../services/apiServices";
import { useSelector } from "react-redux";
import CountWidget from "../../CommonComponents/UiComponent/countWidget";

const DealersDashboard = () => {
  const [vehicleData, setVehicleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [dashboardWidget, setDashboardWidget] = useState([]);
  const pageLimit = [5, 10, 50, 100];
  const vehicleColumns = [];
  const loggedUser = useSelector((state) => state.appSlice?.loggedUser);
  const userRole = localStorage.getItem("user_role");
  const userData = useSelector((state) => state.appSlice?.userData);
  const storedData = JSON?.parse(localStorage?.getItem("userData"));
  const token = localStorage?.getItem("jwtToken");
  const userId = !!userData === false ? userData?._id : storedData?._id;

  //eslint-disable-next-line
  vehicleData.map((headTitle) => {
    switch (headTitle) {
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  <Avatar
                    size="large"
                    src="https://imgs.search.brave.com/52jI6pldqCOOmm1EDA5bCEeFhBXUkyCSBwK36Itbuyw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5pc3RvY2twaG90/by5jb20vaWQvMTMw/NzA4NjU2Ny9waG90/by9nZW5lcmljLW1v/ZGVybi1zdXYtY2Fy/LWluLWNvbmNyZXRl/LWdhcmFnZS5qcGc_/cz02MTJ4NjEyJnc9/MCZrPTIwJmM9ZWg2/RUE0ZzQ2MnpmVmc1/YTNpUHdNc2JObFRH/WnFZaFpGVWhjTG9h/TERTcz0"
                  />
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.vehicleInfo}
                </span>
              </div>
            );
          },
        });
        break;
      case "modal":
        vehicleColumns.push({
          title: "Modal",
          dataIndex: "Modal",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.Modal}</span>;
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "Variant",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.Variant}</span>;
          },
        });
        break;
      case "regNumber":
        vehicleColumns.push({
          title: "Reg Number",
          dataIndex: "regNumber",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.regNumber}</span>;
          },
        });
        break;
      default:
        return null;
    }
  });

  const dealerWidgets = [
    { title: "Total Vehicles", value: "112893" },
    { title: "Total Users", value: "112893" },
    { title: "Inward Vehicles", value: "112893" },
    { title: "Outward Vehicles", value: "112893" },
  ];

  const usersWidgets = [
    { title: "Total Vehicles", value: "112893" },
    { title: "Inward Vehicles", value: "112893" },
    { title: "Outward Vehicles", value: "112893" },
  ];

  const superAdminWidgets = [
    { title: "Total Vehicles", value: "112893" },
    { title: "Total Dealers", value: "112893" },
    { title: "Outward Vehicles", value: "112893" },
  ];

  const dashboardWidgets = (data) => {
    if (userRole === "Super Admin") {
      return superAdminWidgets;
    } else if (userRole === "Dealer") {
      return dealerWidgets;
    } else if (userRole === "User") {
      return usersWidgets;
    } else {
      return [];
    }
  };

  const vehicleDetails = [
    "s.no",
    "registrationNumber",
    "VehicleInfo",
    "yearOfModel",
    "variant",
    "type",
    "action",
  ];

  useEffect(() => {
    fetchData(1, 10);
    getWidgetCount();
  }, []);

  const getTitle = (title) => {
    if (title === "vehicle_count") {
      return "Total vehicles";
    } else if (title === "sold_vehicles") {
      return "Sold vehicles";
    } else if (title === "unsold_vehicles") {
      return "Unsold vehicles";
    } else if (title === "users") {
      return "Users";
    } else if (title === "dealers") {
      return "Dealers";
    } else {
      return "";
    }
  };

  const getWidgetCount = async () => {
    try {
      const response = await apiServices.getWidgetCount(token);
      if (response?.data?.status_code === 1) {
        let inputObject = response?.data;
        inputObject.widgetCount = [];
        for (const key in inputObject?.widgets[0]) {
          let title = getTitle(key);
          if (
            inputObject?.widgets[0].hasOwnProperty(key) &&
            key !== "role" &&
            key !== "status_code"
          ) {
            inputObject.widgetCount.push({
              key: title,
              count: inputObject?.widgets[0]?.[key],
            });
          }
        }
        setDashboardWidget(inputObject?.widgetCount);
      } else {
        console.log("error", "Data Not Availble !");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchData = async (pages, size) => {
    let id = userRole !== "Super Admin" ? userId : "";
    let querry =
      userRole === "Super Admin"
        ? `?page=${pages}&limit=${size}`
        : `?dealer_id=${id}&page=${pages}&limit=${size}`;
    setIsLoading(true);
    const response = await apiServices
      .getVehicleList(querry)
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setTotalRecords(response?.data?.count);
          let vehicleList = [];
          for (
            let i = 0;
            i < 5 && i < response?.data?.vehicle_data.length;
            i++
          ) {
            vehicleList.push(response?.data?.vehicle_data[i]);
          }
          setVehicleData(vehicleList);
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(true);
      });
  };

  const onChangePage = (pagination, filters, sorter) => {
    fetchData(pagination?.current, pagination?.pageSize);
    setCurrent(pagination?.current);
  };

  const getVehicleInfo = (item) => {
    let brand = item?.brand[0]?.brand_name;
    let model = item?.model[0]?.model_name;
    return <b>{brand + " " + model}</b>;
  };

  vehicleDetails.map((headers) => {
    switch (headers) {
      case "s.no":
        vehicleColumns.push({
          title: "S.No",
          dataIndex: "s.no",
          render: (_i, item, index) => {
            return <span>{index + 1}</span>;
          },
        });
        break;
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  <Avatar size="large" src={item?.pictures_of_the_vehicle[0]} />
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {getVehicleInfo(item)}
                </span>
              </div>
            );
          },
        });
        break;
      case "brand":
        vehicleColumns.push({
          title: "Brand",
          dataIndex: "brand_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.brand[0]?.brand_name}</a>
              </span>
            );
          },
        });
        break;
      case "model":
        vehicleColumns.push({
          title: "Model",
          dataIndex: "model",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.model[0]?.model_name}</span>
            );
          },
        });
        break;
      case "yearOfModel":
        vehicleColumns.push({
          title: "Year of Model",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.year_of_model}</a>
              </span>
            );
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "variant_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                {item?.variant[0]?.variant_name}
              </span>
            );
          },
        });
        break;
      case "type":
        vehicleColumns.push({
          title: "Ownership Type",
          dataIndex: "ownership_type",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.ownership_type}</a>
              </span>
            );
          },
        });
        break;
      case "registrationNumber":
        vehicleColumns.push({
          title: "Registration Number",
          dataIndex: "registration_number",
          render: (_i, item) => {
            return (
              <a className="tableRowText">{item?.registration_number}</a>
            );
          },
        });
        break;
      default:
        return null;
    }
  });

  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="componentsLoadContainer" style={{ padding: "0.6%" }}>
        <Title title="Dashboard" />
        <Row className="cards">
          {dashboardWidget?.map((data) => (
            <Card className="card">
              <div gutter={8}>
                <Row>
                  <Statistic
                    className="statisticCard"
                    title={data?.key}
                    value={data?.count}
                    style={{}}
                  />
                </Row>
              </div>
            </Card>
          ))}
        </Row>
        <div>
          <div>
            <span style={{ color: "#9c5403" }}>Registered Vehicles</span>
            <CarOutlined style={{ fontSize: "17px", float: "right" }} />{" "}
          </div>
          <div>
            <Divider style={{ marginTop: "-0.1%" }} />
            <Table
              className="custom-table"
              columns={vehicleColumns}
              dataSource={vehicleData}
              loading={isLoading}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealersDashboard;
