import React from "react";
import {
  Image,
  Button,
  Row,
  Col,
  Form,
  Input,
  notification,
  Spin,
  Divider,
} from "antd";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./login.css";
import { useUserAuth } from "../../services/UserAuthContext";
import profileDataService from "../../services/profileservices";
import { updateUserData, loggedInUser } from "../../services/appSlice";

const shownotification = (type, message) => {
  notification[type]({
    message: message,
  });
};

const Login = ({ navbarHide }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { logIn, signUp } = useUserAuth();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    debugger;
    logIn(values.username, values.password)
      .then((response) => {
        shownotification("success", "Login successful");
        const uid = response.user.uid;
        const payload = { id: uid };
        localStorage.setItem("uid", uid);
        localStorage.setItem("jwtToken", response?.user?.accessToken);
        profileDataService
          .getuser(payload, response?.user?.accessToken)
          .then((response) => {
            debugger;
            if (response.data.status_code !== "0") {
              console.log(response);
              setLoading(false);
              navigate("/dashboard");
              dispatch(updateUserData(response?.data?.Data[0]));
              dispatch(loggedInUser(response?.data?.Role));
              console.log("user Role:", response?.data?.Role);
              localStorage.setItem("user_role", response?.data?.Role);
              localStorage.setItem("user_name", response?.data?.Name);
              // localStorage.setItem("user_role", "IO");
              localStorage.setItem("userid", response.data?.Data[0]?._id);
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data?.Data[0])
              );
              navbarHide();
              if (response?.data?.Role === "IO") {
                localStorage.setItem(
                  "dealer_list",
                  JSON.stringify(response.data)
                );
              }
            } else {
              shownotification("error", "Data Not Availble !");
              setLoading(false);
            }
          })
          .catch((error) => {
            shownotification("error", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        shownotification("error", "Email or password is wrong");
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <div
      className="rootContainer"
      style={{ position: "relative", zIndex: 9999 }}
    >
      <Row className="login">
        <Col sm={8} className="textCenter">
          <Image
            className="loginImg"
            src="./images/SafeGadi.png"
            preview={false}
          />
        </Col>
        <Divider type="vertical" />
        {loading && (
          <Spin
            size="lagre"
            style={{
              position: "absolute",
              zIndex: "999",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
        <Col className="loginForm">
          <Form
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                {
                  type: "email",
                  message: "Enter a valid email address!",
                },
                {
                  required: true,
                  message: "Email is required!",
                },
              ]}
            >
              <Input className="inputBox" type="text" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required!",
                },
              ]}
            >
              <Input.Password className="inputBox" />
            </Form.Item>
            {/* <Link
              className="text-md"
              style={{ marginLeft: "45%" }}
              to="/forgotpassword"
            >
              Forgot password?
            </Link> */}
            <Button type="primary" htmlType="submit">
              Login
            </Button>
            {/* <Link to="/forgotPassword">Forgot Password ?</Link> */}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
