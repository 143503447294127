import React from "react";

const GetDate = (event) => {
    const dateTime = new Date(event);
    const formattedDate = dateTime.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
    return formattedDate;
}
export default GetDate