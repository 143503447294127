import React, { useState } from "react";
import { Steps, Row, Divider, } from "antd";
import Header from "../../Header/Header";
import { FirstStepForm } from "./formSteps";
import { SecondStepForm } from "./formSteps";
import { ThirdStepForm } from "./formSteps";
import { FourthStepForm } from "./formSteps";
// import { FifthStepForm } from "./formSteps";
import Title from "../../CommonComponents/Title";
import CountWidget from "../../CommonComponents/UiComponent/countWidget";

const { Step } = Steps;
const getVehicleDetailsObj = () => {
  return {
    VehicleType: "",
    yearOfModel: "",
    brand: "",
    mileage: "",
    variant: "",
    ownershipType: "",
    regNumber: "",
    chasisNumber: "",
    engineNo: "",
    ownerMobileNumber: "",
    rcCopy: "",
    insuranceCopy: "",
    pollutioncertificate: "",
    fitnessCertificate: "",
    vehiclePictures: "",
    ownersName: "",
    mobileNumber: "",
    address: "",
    aadharNumber: "",
    aadharDocument: "",
    isSuspiciousVehicle: "",
  };
};
const InwardVehicles = () => {
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const obj = getVehicleDetailsObj();
  const [vehicleDetailsObj, setVehicleDetailsObj] = useState(obj);
  const [isInvolved, setIsInvolved] = useState(false)
  const steps = [
    {
      title: "Vehicle Details",
      key:0,
      content: (
        <FirstStepForm
          formData={formData}
          setFormData={setFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
    {
      title: "Registration Details ",
      key:1,
      content: (
        <SecondStepForm
          formData={formData}
          setFormData={setFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
    {
      title: "Upload Documents",
      key:2,
      content: (
        <ThirdStepForm
          formData={formData}
          setFormData={setFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
    {
      title: "Seller Details",
      key:3,
      content: (
        <FourthStepForm
          formData={formData}
          setFormData={setFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setIsInvolved={setIsInvolved}
        />
      ),
    },
    // {
    //   title: isInvolved ? "Generate QR code" : "Alert Investigation Agencies",
    //   key:4,
    //   content: (
    //     <FifthStepForm
    //       formData={formData}
    //       setFormData={setFormData}
    //       currentStep={currentStep}
    //       setCurrentStep={setCurrentStep}
    //       isInvolved={isInvolved}
    //       setIsInvolved={setIsInvolved}
    //     />
    //   ),
    // },
  ];

  const handleFormChange = (stepIndex, changedData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [stepIndex]: changedData,
    }));
  };


  return (
    <div className="InwardContainer">
     <Row style={{marginBottom:"6%"}}> 
        <Header />
      </Row>
      <Row className="InwardSteps tableContant" >
     <span style={{marginBottom:"10px"}}> 
     <Title title="Inward Vehicles"/>
      </span>
      <Steps current={currentStep} size="small">
            {steps.map((step) => (
              <Step key={step.title} title={step?.key===currentStep?<span style={{color:"#fba03a"}}>{step.title}</span>:step.title}/>
            ))}
          </Steps>
          <Divider/>
        <Row style={{ width: "70%", marginLeft: "15%" }}>
          <Row className="steps-content"  >
            {React.cloneElement(steps[currentStep].content, {
              onDataChange: (data) => handleFormChange(currentStep, data),
            })}
          </Row>

        </Row>
      </Row>
    </div>
  );
};

export default InwardVehicles;
