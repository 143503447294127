import React from 'react'

const TitleActionsContainer = ( props ) => {
  return (
    <div className='tableTop' xs={24}>
        {props.children}
    </div>
  )
}

export default TitleActionsContainer