import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Row,
  Table,
  Modal,
  Col,
  Card,
  Divider,
  Input,
  Button,
  Spin,
  notification,
  Tooltip,
  Space,
  Radio,
  Menu,
  Segmented,
  message,
} from "antd";
import Header from "../../Header/Header";
import "./dealerSearch.css";
import Title from "../../CommonComponents/Title";
import TitleActionsContainer from "../../CommonComponents/TitleActionsContainer";
import { mockDealer } from "./const";
import {
  DownCircleFilled,
  PlusCircleFilled,
  EditFilled,
  FileTextFilled,
  SearchOutlined,
  MailOutlined,
  AppstoreOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/apiServices";
import CountWidget from "../../CommonComponents/UiComponent/countWidget";
import { useSelector } from "react-redux";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch";

const { Option } = Select;

const DealerSearch = () => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dealerData, setDealerData] = useState([]);
  const [count, setCount] = useState("");
  const [districtData, setDistrictData] = useState([]);
  const [selectedViewItem, setSelectedViewItem] = useState([]);
  const [selectedEditItem, setSelectedEditItem] = useState([]);
  const [policeStationData, setPoliceStationData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const userData = useSelector((state) => state.appSlice?.userData);
  const [searchMessage, setSearchMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [pageSize, setPageSize] = useState([]);
  const [dealerEdit, setDealerEdit] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState(false);
  const [currentEdit, setCurrentEdit] = useState("app");
  const [linkedPS, setLinkedPS] = useState("");
  const indianMobileRegex = /^(?:\+91)?(?:[6789]\d{9})$/;
  const pageLimit = [5, 10, 50, 100];
  const { Search } = Input;
  const [form] = Form.useForm();

  const dealerDetails = [
    "s.no",
    "name",
    "dealershipType",
    "mobileNumber",
    "address",
    "linkedPoliceStation",
    "action",
  ];
  const dealersColumn = [];
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(1, 10);
  }, []);

  const fetchData = async (pages, size) => {
    setCurrent(pages);
    setPageSize(size);
    let pageData = { page: pages, size: size };
    setIsLoading(true);
    await apiServices
      .getDealerList(userData, pageData)
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setTotalRecords(response?.data?.count);
          setDealerData(response?.data?.dealer_data);
          let len =
            response?.data?.dealer_data !== undefined
              ? response?.data?.dealer_data?.length
              : "";
          setCount(len);
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        } else {
          setIsLoading(true);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });

    await apiServices
      .getDistrictList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setDistrictData(response?.data?.district_data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    await apiServices
      .getPoliceStationList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setPoliceStationData(response?.data?.police_station_data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    await apiServices
      .getCityList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setCityData(response?.data?.city_data);
        } else {
        }
      })
      .catch((error) => {});
    await apiServices
      .getStateList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setStateData(response?.data?.states_data);
        } else {
        }
      })
      .catch((error) => {});
  };

  const shownotification = (type, message) => {
    notification[type]({
      message: message,
    });
  };
  const handleDealerViewData = (data) => {
    localStorage.setItem("ViewDealerData", JSON.stringify(data));
    navigate("/viewDealer");
    setOpen(true);
    setSelectedViewItem(data);
  };
  const handleDealerEditData = (data) => {
    setOpenEdit(true);
    form.setFieldsValue({
      dealer_name: data?.dealer_name,
      mobile_number: data?.mobile_number,
      address: data?.address,
      email: data?.email,
      linked_police_station:
        data?.linked_police_station[0]?.police_station_name,
      type_of_dealership: data?.type_of_dealership,
      name_contact: data?.dealer_contacts[0]?.personalDetails?.name,
      mobile_number_contact:
        data?.dealer_contacts[0]?.personalDetails?.mobile_number,
      address_contact: data?.dealer_contacts[0]?.address?.address1,
      address2_contact: data?.dealer_contacts[0]?.address?.address2,
      state_id_contact: data?.dealer_contacts[0]?.address?.state_id,
      district_id_contact: data?.dealer_contacts[0]?.address?.district_id,
      city_id_contact: data?.dealer_contacts[0]?.address?.city_id,
      pan_card_number_contact:
        data?.dealer_contacts[0]?.personalDetails?.pan_card_number,
      aadhaar_number_contact:
        data?.dealer_contacts[0]?.personalDetails?.aadhar_number,
      pin_code_contact: data?.dealer_contacts[0]?.address?.pinCode,
    });
    setSelectedEditItem(data);
  };
  const onFinish = async (data, type) => {
    let error = {};
    console.log(data, "dealerFinish");
    if (type === "dealer") {
      if (data?.address === "" || data?.address === undefined) {
        error.address = "Address is required";
      }
      if (data?.dealer_name === "" || data?.dealer_name === undefined) {
        error.dealer_name = "Name is required";
      }
      if (indianMobileRegex.test(data?.mobile_number) === false) {
        error.mobile_number = "Enter valid mobile number";
      }
      if (linkedPS === "" || linkedPS === undefined) {
        error.linked_police_station = "Select linked PS";
      }
      if (
        data?.type_of_dealership === "" ||
        data?.type_of_dealership === undefined
      ) {
        error.type_of_dealership = "Type of dealership is required";
      }
      setErrorMessage(error);
      if (Object.keys(error).length === 0) {
        setErrorMessage({});
        let editDealer = {
          id: selectedEditItem._id,
          dealer_name: data?.dealer_name,
          mobile_number: data?.mobile_number,
          address: data?.address,
          linked_police_station: linkedPS
            ? linkedPS
            : selectedEditItem.linked_police_station[0]._id,
          type_of_dealership: data?.type_of_dealership,
          // dealer_contacts: data?.dealer_contacts,
        };
        try {
          const response = await apiServices.updateDealer(editDealer);
          if (response.data?.status_code === 1) {
            message.success(response?.data?.message);
            setOpenEdit(false);
            setErrorMessage({});
          } else {
            message.error(response?.data?.message);
            setOpenEdit(false);
          }
        } catch (error) {
          console.log(error);
          message.error("Dealer failed to update");
          setOpenEdit(false);
        }
      }
    } else {
      let error2 = {};
      if (
        data?.aadhaar_number_contact === "" ||
        data?.aadhaar_number_contact === undefined
      ) {
        error2.aadhaar_number_contact = "Aadhaar is required";
      }
      if (data?.name_contact === "" || data?.name_contact === undefined) {
        error2.name_contact = "Name is required";
      }
      // if (
      //   data?.mobile_number_contact === "" ||
      //   data?.mobile_number_contact === undefined
      // ) {
      //   error2.mobile_number_contact = "Mobile Number is required";
      // }
      if (data?.address_contact === "" || data?.address_contact === undefined) {
        error2.address_contact = "Address is required";
      }
      if (
        data?.state_id_contact === "" ||
        data?.state_id_contact === undefined
      ) {
        error2.state_id_contact = "Select state";
      }
      if (
        data?.district_id_contact === "" ||
        data?.district_id_contact === undefined
      ) {
        error2.district_id_contact = "Select district";
      }
      if (data?.city_id_contact === "" || data?.city_id_contact === undefined) {
        error2.city_id_contact = "Select city";
      }
      if (
        data?.pin_code_contact === "" ||
        data?.pin_code_contact === undefined
      ) {
        error2.pin_code_contact = "Select pincode";
      }
      setErrorMessage2(error2);
      if (Object.keys(error2).length === 0) {
        setErrorMessage2({});
        let editDealerContact = {
          id: selectedEditItem?._id,
          personalDetails: {
            name: data?.name_contact,
            aadhar_number: data?.aadhaar_number_contact,
            pan_card_number: data?.pan_card_number_contact,
            // mobile_number_contact: data?.mobile_number_contact,
          },
          address: {
            address1: data?.address_contact,
            address2: data?.address2_contact,
            state_id: data?.state_id_contact,
            district_id: data?.district_id_contact,
            city_id: data?.city_id_contact,
            pinCode: data?.pin_code_contact,
          },
        };
        try {
          const response = await apiServices.update_person(editDealerContact);
          if (response?.data?.status_code === 1) {
            message.success(response?.data?.message);
            setOpenEdit(false);
            setErrorMessage2({});
          } else {
            message.error(response?.data?.message);
            setOpenEdit(false);
          }
        } catch (error) {
          console.log(error);
          message.error("Dealer contact failed to update");
          setOpenEdit(false);
        }
      }
    }
    fetchData(1, 10);
  };
  const getModalTitle = (data) => {
    return (
      <div>
        <Row>
          <Col>
            <b>{data?.dealer_name ? data?.dealer_name : null}</b>
          </Col>
        </Row>
      </div>
    );
  };

  dealerDetails.map((headers) => {
    switch (headers) {
      case "s.no":
        dealersColumn.push({
          title: "S.No",
          dataIndex: "s.no",
          render: (_i, item, index) => {
            if (current && current > 1) {
              return <span>{(current - 1) * pageSize + index + 1}</span>;
            } else {
              return <span>{index + 1}</span>;
            }
          },
        });
        break;
      case "name":
        dealersColumn.push({
          title: "Dealers Name",
          dataIndex: "dealer_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.dealer_name}</a>
              </span>
            );
          },
        });
        break;
      case "dealershipType":
        dealersColumn.push({
          title: "Dealership Type",
          dataIndex: "type_of_dealership",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.type_of_dealership}</span>
            );
          },
        });
        break;
      case "mobileNumber":
        dealersColumn.push({
          title: "Mobile Number",
          dataIndex: "mobile_number",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.mobile_number}</a>
              </span>
            );
          },
        });
        break;
      case "address":
        dealersColumn.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.address}</span>;
          },
        });
        break;
      case "linkedPoliceStation":
        dealersColumn.push({
          title: "Linked P.S",
          dataIndex: "address",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.linked_police_station[0]?.police_station_name}</a>
              </span>
            );
          },
        });
        break;
      case "action":
        dealersColumn.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <div>
                <Button
                  onClick={() => handleDealerViewData(item)}
                  style={{ marginRight: "2px" }}
                >
                  view
                </Button>
                <span style={{ fontSize: "13px" }}>
                  <Button>QR</Button>
                </span>
                <Tooltip
                  title="Edit"
                  overlayInnerStyle={{ borderRadius: "5px" }}
                >
                  <a>
                    <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                      <EditFilled onClick={() => handleDealerEditData(item)} />
                    </span>
                  </a>
                </Tooltip>
              </div>
            );
          },
        });
        break;
      default:
        return null;
    }
  });
  const onChange = (value, type) => {
    if (type === "name") {
    }
  };

  const handleSearch = async (data) => {
    let queryString = "";
    if (Object.keys(data).length > 0) {
      queryString = Object.keys(data)
        .map((key) => `${key}=${data[key]}`)
        .join("&");
    }
    console.log(queryString, "dataQueryString");
    // const queryParams = {
    //   dealer_name: searchData,
    // };
    const response = await apiServices
      .searchDealerName(queryString)
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setTotalRecords(response?.data?.count);
          setDealerData(response?.data?.dealer_data);
          setTimeout(() => {
            setIsLoading(false);
          }, 140);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 140);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });
  };
  const handleReset = async () => {
    fetchData(1, 10);
  };
  const onChangePage = (pagination, filters, sorter) => {
    fetchData(pagination?.current, pagination?.pageSize);
    setCurrent(pagination?.current);
    setPageSize(pagination?.pageSize);
  };
  useEffect(() => {
    setCurrentEdit("Dealer");
    setDealerEdit(true);
  }, []);

  const handleDealerEdit = (e) => {
    if (dealerEdit) {
      setCurrentEdit("Dealer Contact");
    } else {
      setCurrentEdit("Dealer");
    }
    setDealerEdit(!dealerEdit);
  };

  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="toggleContainer">
        <div
          className=" componentsLoadContainer"
          style={{
            positon: "relative",
            padding: "0.6%",
            width: openSearch ? "80%" : "100%",
          }}
        >
          <Row
            className="accLinks"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col style={{ fontSize: "17px" }}>
              <Title title="Dealers" />
            </Col>
            <Col style={{ float: "right" }}>
              <Space direction="verticle">
                {/* <Input
                  value={searchData}
                  className="inputSearch1"
                  placeholder="Search dealer"
                  onChange={(e) => onSearch(e.target.value)}
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                  }}
                /> */}
                {!openSearch && (
                  <button
                    onClick={(e) => {
                      setOpenSearch(true);
                    }}
                    style={{
                      fontSize: "16px",
                      background: "#FBA03A",
                      border: "none",
                      borderRadius: "49%",
                      color: "#fff",
                    }}
                  >
                    <SearchOutlined className="searchIcon" />
                  </button>
                )}
                {/* <Button onClick={handleReset} style={{ borderRadius: "25px" }}>
                  Reset
                </Button> */}
                {/* <Divider type="vertical" /> */}
                <div>
                  <PlusCircleFilled
                    onClick={() => {
                      navigate("/createDealer");
                    }}
                    style={{
                      fontSize: "28px",
                      color: "#fba03b",
                    }}
                  />
                </div>
              </Space>
            </Col>
            <Divider style={{ marginTop: "-0.1%" }} />
          </Row>
          <div
            style={{
              justifyContent: "normal",
              padding: "1%",
            }}
          >
            <Table
              loading={isLoading}
              className="custom-table"
              columns={dealersColumn}
              dataSource={dealerData}
              onChange={onChangePage}
              pagination={{
                current: current,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: pageLimit,
              }}
            />
          </div>
          <Modal
            title={getModalTitle(selectedViewItem)}
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={500}
          >
            <div
              style={{
                marginLeft: "50px",
                fontSize: "17px",
              }}
            >
              {dealerData.map((data) => {
                return data?._id === selectedViewItem._id ? (
                  <div className="vehicleDetails">
                    <Card className="card">
                      <b>Dealer Details</b>
                      <Divider />
                      <Col>
                        <span>Dealer Name</span>{" "}
                        <b>{data?.dealer_name ? data?.dealer_name : null}</b>
                      </Col>
                      <Col>
                        <span>Mobile Number</span>{" "}
                        <b>
                          {data?.mobile_number ? data?.mobile_number : null}
                        </b>
                      </Col>
                      <Col>
                        <span>Email</span>{" "}
                        <b>{data?.email ? data?.email : null}</b>
                      </Col>
                      <Col>
                        <span>Address</span>{" "}
                        <b>{data?.address ? data?.address : null}</b>
                      </Col>
                    </Card>
                    <Card className="card">
                      <b>Other Details</b>
                      <Divider />
                      <Col>
                        <span>Linked Police Station</span>{" "}
                        <b>
                          {data?.linked_police_station[0]?.police_station_name
                            ? data?.linked_police_station[0]
                                ?.police_station_name
                            : null}
                        </b>
                      </Col>
                      <Col>
                        <span>Dealer Contacts </span>{" "}
                        <b>
                          {data?.dealer_contacts ? data?.dealer_contacts : null}
                        </b>
                      </Col>
                      <Col>
                        <span>Dealership Type</span>{" "}
                        <b>
                          {data?.type_of_dealership
                            ? data?.type_of_dealership
                            : null}
                        </b>
                      </Col>
                    </Card>
                  </div>
                ) : null;
              })}
            </div>
          </Modal>
          <Modal
            centered
            open={openEdit}
            footer={false}
            onCancel={() => setOpenEdit(false)}
            width={600}
            title={
              <div>
                <EditOutlined style={{ marginRight: "2%" }} />
                <Segmented
                  options={["Dealer", "Dealer Contact"]}
                  value={currentEdit}
                  onChange={handleDealerEdit}
                />
              </div>
            }
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {dealerEdit ? (
                <Form
                  onFinish={(e) => onFinish(e, "dealer")}
                  layout="horizental"
                  form={form}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        marginRight: "60px",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Dealer Name<span style={{ color: "red" }}>*</span>
                        <Form.Item name="dealer_name">
                          <Input
                            placeholder="Enter Dealer Name"
                            status={
                              !!errorMessage?.dealer_name === true
                                ? "error"
                                : null
                            }
                          />
                        </Form.Item>
                        {!!errorMessage?.dealer_name === true ? (
                          <p className="error">{errorMessage?.dealer_name}</p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col
                        style={{
                          padding: "5px",
                          width: "190px",
                        }}
                      >
                        Linked Police Station{" "}
                        <span style={{ color: "red" }}>*</span>
                        <Form.Item name="linked_police_station">
                          <Select
                            showSearch
                            placeholder="Enter Linked police station"
                            optionFilterProp="children"
                            onChange={(e) => setLinkedPS(e)}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            status={
                              !!errorMessage?.linked_police_station === true
                                ? "error"
                                : null
                            }
                          >
                            <Option disabled>Select P.S</Option>
                            {policeStationData.map((data) => {
                              return (
                                <Option value={data?._id}>
                                  {data?.police_station_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        {!!errorMessage?.linked_police_station === true ? (
                          <p className="error">
                            {errorMessage?.linked_police_station}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        marginRight: "60px",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Mobile Number<span style={{ color: "red" }}>*</span>
                        <Form.Item name="mobile_number">
                          <Input
                            placeholder="Enter Mobile Number"
                            status={
                              !!errorMessage?.mobile_number === true
                                ? "error"
                                : null
                            }
                          />
                        </Form.Item>
                        {!!errorMessage?.mobile_number === true ? (
                          <p className="error">{errorMessage?.mobile_number}</p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Address<span style={{ color: "red" }}>*</span>
                        <Form.Item name="address">
                          <Input
                            placeholder="Enter Address"
                            status={
                              !!errorMessage?.address === true ? "error" : null
                            }
                          />
                        </Form.Item>
                        {!!errorMessage?.address === true ? (
                          <p className="error">{errorMessage?.address}</p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        marginRight: "60px",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Type of Dealership
                        <span style={{ color: "red" }}>*</span>
                        <Form.Item name="type_of_dealership">
                          <Input
                            placeholder="Enter type of Dealership"
                            status={
                              !!errorMessage?.type_of_dealership === true
                                ? "error"
                                : null
                            }
                          />
                        </Form.Item>
                        {!!errorMessage?.type_of_dealership === true ? (
                          <p className="error">
                            {errorMessage?.type_of_dealership}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Divider style={{ margin: "9px" }} />
                    <div style={{ width: "100%", marginBottom: "-12px" }}>
                      <Row style={{ float: "right" }}>
                        <Col>
                          <Form.Item>
                            <Button
                              onClick={() => setOpenEdit(false)}
                              size="small"
                              style={{ marginRight: "10px" }}
                            >
                              Cancel
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              className="custBtn1"
                              type="primary"
                              htmlType="submit"
                              size="small"
                            >
                              Submit <FileTextFilled />
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              ) : (
                <Form
                  onFinish={(e) => onFinish(e, "dealerContacts")}
                  layout="horizental"
                  form={form}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        marginRight: "60px",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Name<span style={{ color: "red" }}>*</span>
                        <Form.Item name="name_contact">
                          <Input
                            placeholder="Enter Name"
                            status={
                              !!errorMessage2?.linked_police_station === true
                                ? "error"
                                : null
                            }
                          />
                        </Form.Item>
                        {!!errorMessage2?.name_contact === true ? (
                          <p className="error">{errorMessage2?.name_contact}</p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Select State<span style={{ color: "red" }}>*</span>
                        <Form.Item name="state_id_contact">
                          <Select
                            showSearch
                            placeholder="Select State"
                            optionFilterProp="children"
                            status={
                              !!errorMessage2?.state_id === true
                                ? "error"
                                : null
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            <Option disabled>Select State</Option>
                            {stateData.map((data) => {
                              return (
                                <Option value={data?._id}>
                                  {data?.state_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        {!!errorMessage2?.state_id_contact === true ? (
                          <p className="error">
                            {errorMessage2?.state_id_contact}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Address2
                        <Form.Item name="address2_contact">
                          <Input placeholder="Enter Secondary Address" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        marginRight: "60px",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Aadhaar Number<span style={{ color: "red" }}>*</span>
                        <Form.Item
                          name="aadhaar_number_contact"
                          status={
                            !!errorMessage2?.aadhaar_number_contact === true
                              ? "error"
                              : null
                          }
                        >
                          <Input placeholder="Aadhaar Number" />
                        </Form.Item>
                        {!!errorMessage2?.aadhaar_number_contact === true ? (
                          <p className="error">
                            {errorMessage2?.aadhaar_number_contact}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Select District<span style={{ color: "red" }}>*</span>
                        <Form.Item
                          name="district_id_contact"
                          status={
                            !!errorMessage2?.district_id_contact === true
                              ? "error"
                              : null
                          }
                        >
                          <Select
                            showSearch
                            placeholder="Select District"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            status={
                              !!errorMessage2?.district_id === true
                                ? "error"
                                : null
                            }
                          >
                            <Option disabled>Select District</Option>
                            {districtData &&
                              districtData.map((data) => {
                                return (
                                  <Option value={data?._id}>
                                    {data?.district_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        {!!errorMessage2?.district_id_contact === true ? (
                          <p className="error">
                            {errorMessage2?.district_id_contact}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        marginRight: "60px",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        PAN Number
                        <Form.Item name="pan_card_number_contact">
                          <Input placeholder="PAN Number" />
                        </Form.Item>
                      </Col>
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Select City<span style={{ color: "red" }}>*</span>
                        <Form.Item name="city_id_contact">
                          <Select
                            showSearch
                            placeholder="Select City"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            status={
                              !!errorMessage2?.city_id === true ? "error" : null
                            }
                          >
                            <Option disabled>Select City</Option>
                            {cityData &&
                              cityData.map((data) => {
                                return (
                                  <Option value={data?._id}>
                                    {data?.city_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        {!!errorMessage2?.city_id_contact === true ? (
                          <p className="error">
                            {errorMessage2?.city_id_contact}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                      }}
                    >
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Address<span style={{ color: "red" }}>*</span>
                        <Form.Item
                          name="address_contact"
                          status={
                            !!errorMessage2?.address_contact === true
                              ? "error"
                              : null
                          }
                        >
                          <Input placeholder="Enter Address" />
                        </Form.Item>
                        {!!errorMessage2?.address_contact === true ? (
                          <p className="error">
                            {errorMessage2?.address_contact}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col style={{ padding: "5px", width: "190px" }}>
                        Pincode<span style={{ color: "red" }}>*</span>
                        <Form.Item
                          name="pin_code_contact"
                          status={
                            !!errorMessage2?.pin_code_contact === true
                              ? "error"
                              : null
                          }
                        >
                          <Input placeholder="Enter Valid Pincode" />
                        </Form.Item>
                        {!!errorMessage2?.pin_code_contact === true ? (
                          <p className="error">
                            {errorMessage2?.pin_code_contact}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Divider style={{ margin: "9px" }} />
                    <div style={{ width: "100%", marginBottom: "-12px" }}>
                      <Row style={{ float: "right" }}>
                        <Col>
                          <Form.Item>
                            <Button
                              onClick={() => setOpenEdit(false)}
                              size="small"
                              style={{ marginRight: "10px" }}
                            >
                              Cancel
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button
                              className="custBtn1"
                              type="primary"
                              htmlType="submit"
                              size="small"
                              style={{ marginRight: "33px" }}
                            >
                              Submit <FileTextFilled />
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          </Modal>
        </div>
        {openSearch && (
          <AdvanceSearch
            searchType="Dealer"
            searchData={(e) => {
              handleSearch(e);
            }}
            setOpenSearch={() => {
              setOpenSearch(false);
            }}
            handleReset={handleReset}
          />
        )}
      </div>
    </div>
  );
};

export default DealerSearch;
