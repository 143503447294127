import { Row, Table } from "antd";
import Header from "../../Header/Header";
import React, { useState } from "react";

const IoDealer = () => {
  const [current, setCurrent] = useState(1);
  const pageLimit = [5, 10, 50, 100];
  const [pageSize, setPageSize] = useState([]);

  const dealer_list = JSON.parse(localStorage.getItem("dealer_list"));
  console.log(dealer_list, "dealer_List");
  const totalRecords = dealer_list.Dealer_list.length;

  const dealerDetails = [
    "s.no",
    "name",
    "dealershipType",
    "mobileNumber",
    "address",
  ];
  const dealersColumn = [];
  const onChangePage = (pagination, filters, sorter) => {
    setCurrent(pagination?.current);
    setPageSize(pagination?.pageSize);
  };

  dealerDetails.map((headers) => {
    switch (headers) {
      case "s.no":
        dealersColumn.push({
          title: "S.No",
          dataIndex: "sno",
          render: (_i, item, index) => {
            if (current && current > 1) {
              return <span>{(current - 1) * pageSize + index + 1}</span>;
            } else {
              return <span>{index + 1}</span>;
            }
          },
        });
        break;
      case "name":
        dealersColumn.push({
          title: "Dealers Name",
          dataIndex: "dealer_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.dealer_name}</a>
              </span>
            );
          },
        });
        break;
      case "dealershipType":
        dealersColumn.push({
          title: "Dealership Type",
          dataIndex: "type_of_dealership",
          render: (_i, item) => {
            return (
              <span className="tableRowText">{item?.type_of_dealership}</span>
            );
          },
        });
        break;
      case "mobileNumber":
        dealersColumn.push({
          title: "Mobile Number",
          dataIndex: "mobile_number",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.mobile_number}</a>
              </span>
            );
          },
        });
        break;
      case "address":
        dealersColumn.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.address}</span>;
          },
        });
        break;
    }
  });

  return (
    <div>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div className="componentsLoadContainer" style={{ padding: "1%" }}>
      <Table
          //   loading={isLoading}
          className="custom-table"
          columns={dealersColumn}
          dataSource={dealer_list.Dealer_list}
          onChange={onChangePage}
          pagination={{
            current: current,
            total: totalRecords,
            showSizeChanger: true,
            pageSizeOptions: pageLimit,
          }}
        />
      </div>
    </div>
  );
};
export default IoDealer;
