//import { React } from 'react'
import axios from "axios";
import API_URL from "./apiEndPoints";
//import { useId } from 'react';
//import baseurls from './baseapiurl'

const resurl = process.env.REACT_APP_BASE_URL;
class profileservices {
  getuser(data, token) {
    const axiosInstance = axios.create({
      baseURL: resurl,
      headers: {
        'Authorization': `token ${token}`,
      },
    });

    let res = axiosInstance.get(API_URL?.get_users +`?id=${data?.id}`);
    return res;
  }

  putuser(data) {
    let res = axios.put(resurl + "/user", data);
    return res;
  }
}

export default new profileservices();
