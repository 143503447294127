import React from "react";
import { Col, Divider, Avatar } from "antd";
import "./dashboard.css";
import DealersDashboard from "./DealersDashboard";
import InvestigationDashboard from "./InvestigationDashboard";

const Dashboard = () => {
  const vehicleData = ["VehicleInfo", "modal", "variant", "regNumber"];
  const dealerData = ["Avatar", "DealerInfo", "State", "District"];

  const vehicleColumns = [];
  const dealerColumns = [];

  const getRoalBasedDashboard = () => {
    const userRole = localStorage.getItem("user_role");
    if (userRole === "Super Admin") {
      return <DealersDashboard />;
    } else if (userRole === "Dealer") {
      return <DealersDashboard />;
    } else if (userRole === "User") {
      return <DealersDashboard />;
    } else if (userRole === "IO") {
      return <InvestigationDashboard />;
    }
  };
  //eslint-disable-next-line
  vehicleData.map((headTitle) => {
    switch (headTitle) {
      case "VehicleInfo":
        vehicleColumns.push({
          title: "Vehicle Info",
          dataIndex: "vehicleInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  <Avatar
                    size="large"
                    src="https://imgs.search.brave.com/52jI6pldqCOOmm1EDA5bCEeFhBXUkyCSBwK36Itbuyw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5pc3RvY2twaG90/by5jb20vaWQvMTMw/NzA4NjU2Ny9waG90/by9nZW5lcmljLW1v/ZGVybi1zdXYtY2Fy/LWluLWNvbmNyZXRl/LWdhcmFnZS5qcGc_/cz02MTJ4NjEyJnc9/MCZrPTIwJmM9ZWg2/RUE0ZzQ2MnpmVmc1/YTNpUHdNc2JObFRH/WnFZaFpGVWhjTG9h/TERTcz0"
                  />
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.vehicleInfo}
                </span>
              </div>
            );
          },
        });
        break;
      case "modal":
        vehicleColumns.push({
          title: "Modal",
          dataIndex: "Modal",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.Modal}</span>;
          },
        });
        break;
      case "variant":
        vehicleColumns.push({
          title: "Variant",
          dataIndex: "Variant",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.Variant}</span>;
          },
        });
        break;
      case "regNumber":
        vehicleColumns.push({
          title: "Reg Number",
          dataIndex: "regNumber",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.regNumber}</span>;
          },
        });
        break;
      default:
        return null;
    }
  });

  dealerData.map((headTitle) => {
    switch (headTitle) {
      case "DealerInfo":
        dealerColumns.push({
          title: "Dealer Info",
          dataIndex: "DealerInfo",
          render: (_i, item) => {
            return (
              <div style={{ display: "flex" }}>
                <Col>
                  <Avatar
                    size="large"
                    src="https://imgs.search.brave.com/IR_BJXevYFvukslUcJzI-vmnWUeH2Ox2E9pWE2LnjGM/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA1LzM3LzMyLzU3/LzM2MF9GXzUzNzMy/NTcyNl9HdGdqUml5/YzM3QkxQbjlPbWlz/QlZWWmVjOWZyTGFM/MC5qcGc"
                  />
                  <Divider type="vertical" />
                </Col>
                <span className="tableRowText" style={{ marginTop: "4%" }}>
                  {item?.DealerInfo}
                </span>
              </div>
            );
          },
        });
        break;
      case "State":
        dealerColumns.push({
          title: "State",
          dataIndex: "State",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.State}</span>;
          },
        });
        break;
      case "District":
        dealerColumns.push({
          title: "District",
          dataIndex: "District",
          render: (_i, item) => {
            return <span className="tableRowText">{item?.District}</span>;
          },
        });
        break;
      default:
        return null;
    }
  });

  const widgetDetails = [
    { title: "Registerd Vehicles", value: "112893" },
    { title: "Registerd Dealers", value: "112893" },
    { title: "Inward Vehicles", value: "112893" },
    { title: "Outward Vehicles", value: "112893" },
  ];

  return <>{getRoalBasedDashboard()}</>;
};

export default Dashboard;
