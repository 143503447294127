import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Col,
  Divider,
  Card,
  Space,
  Form,
  Input,
  Row,
  Button,
  message,
  Spin,
} from "antd";
import Header from "../../Header/Header";
import apiServices from "../../services/apiServices";
import {
  DownCircleFilled,
  EditFilled,
  FileTextFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import "./sellerDetails.css";
import GetDate from "../../CommonComponents/GetDate";
import Title from "../../CommonComponents/Title";
import UploadDocument from "../../CommonComponents/UploadDocument";
import { mobileNumberValidation, nameValidation, AadhaarValidation } from "../../CommonComponents/Validations/Validations";


const { Search } = Input;

const SellerDetails = () => {
  const [sellerData, setSellerData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrlData, setImgUrlData] = useState({});


  const sellerColumn = [];
  const sellerDetails = ["owner_name", "mobile_number", "address", "action"];
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const fetchData = async () => {
    setIsLoading(true)
    const response = await apiServices.getSellerList()
      .then((response) => {
        if (response?.data?.status_code === 1) {
          setSellerData(response?.data?.seller_data)
          setTimeout(() => {
            setIsLoading(false)
          }, 200)
        } else {
          setIsLoading(true)
        }
      })
      .catch((error) => {
        setIsLoading(true)
        console.log(error)
      })
  }
  useEffect(() => {
    fetchData();
  }, []);
  const handleSellerViewData = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };
  const handleSellerEditData = (data) => {
    setSelectedItem(data);
    setOpenEdit(true);
    form.setFieldsValue({
      aadhar_number: data?.aadhar_number,
      address: data?.address,
      owner_name: data?.owner_name,
      mobile_number: data?.mobile_number,
      aadhar_document: data?.aadhar_document,
    });
  };

  const getModalTitle = (data) => {
    return (
      <div>
        <Col>
          <b> {data?.owner_name} </b>
        </Col>
      </div>
    );
  };
  sellerDetails.map((headers) => {
    switch (headers) {
      case "owner_name":
        sellerColumn.push({
          title: "Seller Name",
          dataIndex: "owner_name",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.owner_name}</a>
              </span>
            );
          },
        });
        break;
      case "mobile_number":
        sellerColumn.push({
          title: "Mobile Number",
          dataIndex: "mobile_number",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.mobile_number}</a>
              </span>
            );
          },
        });
        break;
      case "address":
        sellerColumn.push({
          title: "Address",
          dataIndex: "address",
          render: (_i, item) => {
            return (
              <span className="tableRowText">
                <a>{item?.address}</a>
              </span>
            );
          },
        });
        break;
      case "action":
        sellerColumn.push({
          title: "Action",
          dataIndex: "action",
          render: (_i, item) => {
            return (
              <div>
                <a>
                  <span style={{ fontSize: "16px" }}>
                    <DownCircleFilled
                      onClick={() => handleSellerViewData(item)}
                    />
                  </span>
                </a>
                {"  "}
                <a>
                  <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                    <EditFilled onClick={() => handleSellerEditData(item)} />
                  </span>
                </a>
              </div>
            );
          },
        });
        break;
      default:
        return null;
    }
  });
  const onFinish = async (data) => {
    let errors = {};

    if (nameValidation(data?.owner_name) || data?.owner_name === undefined) {
      errors.owner_name = "Enter Valid name"
    }
    if (mobileNumberValidation(data?.mobile_number)) {
      errors.mobile_number = "Enter Valid Mobile Number"
    }
    if (data?.address === undefined || data?.address === "") {
      errors.address = "Enter Contacts"
    }
    if (AadhaarValidation(data?.aadhar_number)) {
      errors.aadhar_number = "Enter Valid Aadhaar"
    }
    setErrorMessage(errors)
    if (Object.keys(errors).length === 0) {
      setErrorMessage("")
      let editSeller = {
        id: selectedItem?._id,
        owner_name: data?.owner_name,
        mobile_number: data?.mobile_number,
        address: data?.address,
        aadhar_number: data?.aadhar_number,
        aadhar_document: data?.aadhar_document,
      };
      const response = await apiServices.updateSeller(editSeller)
        .then((response) => {
          if (response?.data?.status_code === 1) {
            setSellerData(response?.data?.seller_data)
            setIsLoading(false)
          } else {
            setIsLoading(true)
          }
        })
        .catch((error) => {
          setIsLoading(true)
          console.log(error)
        })
    }
  };
  const handleImgUrl = (e, type) => {
    setImgUrlData((prevState) => ({
      ...prevState, [type]: e,
    }));
  }

  const onChange = (data, type) => {
    setErrorMessage({ ...errorMessage, [type]: false });
  }
  return (
    <>
      <Row style={{ marginBottom: "6%" }}>
        <Header />
      </Row>
      <div
        className="seller componentsLoadContainer"
        style={{ padding: "10px",position:"relative" }}
      >
        <Row className="accLinks" style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>
            <Title title="Sellers" />
          </Col>
          <Col style={{ float: "right" }}>
            <Search
              placeholder="Enter Mobile Number"
              // onSearch={onSearch}
              // onChange={(e) => setSearchData(e.target.value)}
              // value={searchData}
              enterButton
              size="medium"
              style={{
                width: 200,
              }}
            />
            <Button className="custBtn1"
            // onClick={handleReset}
            >Reset</Button>
          </Col>
          <Divider />
        </Row>
        <div
          style={{
            justifyContent: "normal",
            padding: "1%",
          }}
        >
         {!isLoading ?
          <Table
            className="custom-table"
            columns={sellerColumn}
            dataSource={sellerData}
          />
          : <Spin style={{ position:"absolute",top:"50%",left:"50%"  }} />
          }
        </div>
        <Modal
          title={getModalTitle(selectedItem)}
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={500}
        >
          <div style={{ marginLeft: "50px", fontSize: "17px" }}>
            {sellerData.map((data) => {
              return data?._id === selectedItem?._id ? (
                <div className="buyerDetails">
                  <Card className="card">
                    <b>Seller Details</b>
                    <Divider />
                    <Col>
                      <span>Seller Name</span>{" "}
                      <b>{data?.owner_name ? data?.owner_name : null}</b>
                    </Col>
                    <Col>
                      <span>Mobile Number</span>{" "}
                      <b>{data?.mobile_number ? data?.mobile_number : null}</b>
                    </Col>
                    <Col>
                      <span>Address</span>{" "}
                      <b>{data?.address ? data?.address : null}</b>
                    </Col>
                    <Col>
                      <span>Aadhaar Number</span>{" "}
                      <b>{data?.aadhar_number ? data?.aadhar_number : null}</b>
                    </Col>
                    <Col>
                      <span>PAN Number</span>{" "}
                      <b>
                        {data?.pan_card_number ? data?.pan_card_number : null}
                      </b>
                    </Col>
                  </Card>
                  <Card className="card">
                    <b>Other Details</b>
                    <Divider />
                    <Col>
                      <span>Created Time</span>{" "}
                      <b>
                        {data?.created_time
                          ? GetDate(data?.created_time)
                          : null}
                      </b>
                    </Col>
                    <Col>
                      <span>Updated Time</span>{" "}
                      <b>
                        {data?.updated_time
                          ? GetDate(data?.updated_time)
                          : null}
                      </b>
                    </Col>
                  </Card>
                </div>
              ) : null;
            })}
          </div>
        </Modal>
        <Modal
          title="Edit Details"
          centered
          onCancel={() => setOpenEdit(false)}
          open={openEdit}
          width={500}
          footer={false}
        >
          <Form onFinish={onFinish} layout="horizental" form={form}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  marginLeft: "160px",
                }}
              >
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Seller Name"
                    name="owner_name"
                  >
                    <Input placeholder="Enter Seller Name"
                      status={!!errorMessage?.owner_name === true ? "error" : null}
                      onChange={(e) => { onChange(e, "owner_name") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.owner_name === true ? (<p className="error">{errorMessage?.owner_name}</p>) : ("")}
                </Col>
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Mobile Number"
                    name="mobile_number"
                  >
                    <Input placeholder="Enter Mobile Number"
                      status={!!errorMessage?.mobile_number === true ? "error" : null}
                      onChange={(e) => { onChange(e, "mobile_number") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.mobile_number === true ? (<p className="error">{errorMessage?.mobile_number}</p>) : ("")}
                </Col>
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Address"
                    name="address"
                  >
                    <Input placeholder="Enter address"
                      status={!!errorMessage?.address === true ? "error" : null}
                      onChange={(e) => { onChange(e, "address") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.address === true ? (<p className="error">{errorMessage?.address}</p>) : ("")}
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  marginLeft: "60px",
                }}
              >
                <Col style={{ maxWidth: "190px", padding: "5px" }}>
                <Form.Item
                    label="Aadhaar Number"
                    name="aadhar_number"
                  >
                    <Input placeholder="Enter Aadhaar Number"
                      status={!!errorMessage?.aadhar_number === true ? "error" : null}
                      onChange={(e) => { onChange(e, "aadhar_number") }}
                    />
                  </Form.Item>
                  {!!errorMessage?.aadhar_number === true ? (<p className="error">{errorMessage?.aadhar_number}</p>) : ("")}
                  <UploadDocument name="Upload Aadhaar" imgUrl={(e) => handleImgUrl(e, "aadhar_document")} />
                </Col>
              </Row>
              <Divider />
              <Col>
                <Form.Item>
                  <div
                    style={{
                      marginLeft: "-180px",
                      marginTop: "11px",
                      position: "absolute",
                    }}
                  >
                    <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
                  </div>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <div
                    style={{
                      marginLeft: "-99px",
                      marginTop: "11px",
                      position: "absolute",
                    }}
                  >
                    <Button
                      className="custBtn1"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit <FileTextFilled />
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};
export default SellerDetails;
